import { StatsService, TCyclability, cyclabilities } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';

import Card from './card';

export const cyclabilitiesMap: {
  [key in TCyclability]: {
    bgColor: string;
    color: string;
    labelKey: string;
    textColor: string;
  };
} = {
  veryGood: {
    color: '#1C9267',
    bgColor: '#C2E4BF',
    textColor: '#1B6515',
    labelKey: 'cycling-insights.facilities.cyclability.very_cycleable',
  },
  good: {
    color: '#46CE9D',
    bgColor: '#E4F2DE',
    textColor: '#268B1D',
    labelKey: 'cycling-insights.facilities.cyclability.cycleable',
  },
  neutral: {
    color: '#aaa',
    bgColor: '#ddd',
    textColor: '#555',
    labelKey: 'cycling-insights.facilities.cyclability.neutral',
  },
  bad: {
    color: '#E76685',
    bgColor: '#FFF3F3',
    textColor: '#D34949',
    labelKey: 'cycling-insights.facilities.cyclability.not_very_cycleable',
  },
  veryBad: {
    color: '#A42C49',
    bgColor: '#FFD7D7',
    textColor: '#A32828',
    labelKey: 'cycling-insights.facilities.cyclability.difficult_to_cycle',
  },
};

const cyclabilityChartId = 'cyclability-chart';

function Cyclability(): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const {
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const cyclabilityChartRef = useRef<Chart<'doughnut'>>();
  const [roadsCountByCyclability, setRoadsCountByCyclability] = useState<number[]>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && sections) getCyclabilityDistribution();
  }, [initialized, sections]);

  useEffect(() => {
    if (roadsCountByCyclability) {
      const cyclabilityChartCtx = document.getElementById(cyclabilityChartId);
      if (cyclabilityChartCtx && cyclabilityChartCtx instanceof HTMLCanvasElement) {
        cyclabilityChartRef.current?.destroy();

        cyclabilityChartRef.current = new Chart(cyclabilityChartCtx, {
          type: 'doughnut',
          data: {
            labels: cyclabilities.map((key) => t(cyclabilitiesMap[key].labelKey)),
            datasets: [
              {
                data: roadsCountByCyclability.slice(),
                backgroundColor: cyclabilities.map((key) => cyclabilitiesMap[key].color),
              },
            ],
          },
          options: {
            cutout: '80%',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                callbacks: {
                  label: ({ parsed }) =>
                    ` ${
                      Math.round(
                        (parsed /
                          roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                          1000,
                      ) / 10
                    } %`,
                },
              },
              title: {
                display: false,
              },
            },
          },
        });
      }
    }
  }, [roadsCountByCyclability]);

  async function getCyclabilityDistribution() {
    if (!currentPartner) return;

    try {
      const { veryGood, good, neutral, bad, veryBad } =
        await StatsService.getCyclabilityDistribution(currentPartner.id);
      setRoadsCountByCyclability([veryGood, good, neutral, bad, veryBad]);
    } catch {
      enqueueSnackbar(t('commons.stats.no_data'), { variant: 'error' });
    }
  }

  const totalRoadsCount = roadsCountByCyclability?.reduce((value, res) => (res += value), 0);

  return (
    <>
      <Card
        currentData={null}
        titleKey="cycling-insights.facilities.cyclability.chart.title"
        to={`/${currentPartner?.code}/cartographic-data/cyclability`}
      >
        <Box
          alignSelf="center"
          height={200}
          maxWidth="100%"
          overflow="hidden"
          position="relative"
          sx={{ overflow: 'hidden' }}
          width={200}
        >
          {roadsCountByCyclability ? (
            <canvas id={cyclabilityChartId} />
          ) : (
            <Skeleton height={200} variant="circular" />
          )}
        </Box>
        <Box display="flex" flexWrap="wrap" gap={2} marginTop={2}>
          {cyclabilities.map((key, index) => {
            const { color, labelKey } = cyclabilitiesMap[key];

            return (
              <Box
                alignItems="center"
                display="flex"
                gap={1}
                key={key}
                minHeight={40}
                width={{ xs: '100%', xl: 'calc((100% - 16px) / 2)' }}
              >
                <Box
                  alignSelf="stretch"
                  borderLeft={`3px solid ${color}`}
                  borderRadius={3}
                  flexShrink={0}
                />
                <Box flexGrow={1}>
                  <Typography color="textSecondary" variant="caption">
                    <Trans i18nKey={labelKey} />
                  </Typography>
                </Box>
                <Box flexShrink={0}>
                  <Typography noWrap fontSize="1.125em">
                    {roadsCountByCyclability ? (
                      `${
                        totalRoadsCount
                          ? Math.round((roadsCountByCyclability[index] / totalRoadsCount) * 1000) /
                            10
                          : 0
                      } %`
                    ) : (
                      <Skeleton variant="text" width={40} />
                    )}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Card>
    </>
  );
}

export default Cyclability;
