import { GeoJsonLayer } from '@deck.gl/layers';
import {
  PartnerService,
  Report,
  ReportService,
  SuddenBrakingService,
  TReportTypeCodeWithSupport,
  TStatus,
  useCancellablePromise,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import { cellToLatLng, latLngToCell } from 'h3-js';
import JSZip from 'jszip';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import { TColorCollection } from '../../../components/color-legend';
import useH3, { TResolution, defaultResolution } from '../../../hooks/map/h3';
import useAmplitudeTracker from '../../../hooks/tracker';
import { TOutletContext } from '../../../layouts/page/container';
import AccidentTooltip from '../components/accidentology/accident-tooltip';
import BlackSpotTooltip from '../components/accidentology/black-spot-tooltip';
import H3CellTooltip from '../components/accidentology/h3-cell-tooltip';
import ReportTooltip from '../components/accidentology/report-tooltip';
import SuddenBrakingTooltip from '../components/accidentology/sudden-braking-tooltip';
import { TCartographicDataPageContext } from '../context';
import { TFubFeature, TH3CellData, TH3CellFeatureProps, tilesZoom } from '../models/accidentology';

import useDeck from './deck';
import useVectorTiles from './vector-tiles';

export const accidentologyColors: TColorCollection = [
  { value: '#a42c49' },
  { value: '#e76685' },
  { value: '#ffebee' },
];

const colors: Array<[number, number, number]> = [
  [255, 235, 238],
  [231, 102, 133],
  [164, 44, 73],
];

function useAccidentology({
  header: { setActions },
  accidentology: {
    canvasRef,
    h3Features,
    h3Map,
    currentRange,
    accidentsFeatures,
    blackSpotsFeatures,
    selectedH3Index,
    setH3Features,
    setH3Map,
    setBounds,
    setCurrentRange,
    setAccidentsFeatures,
    setAccidentsYears,
    setBlackSpotsFeatures,
    setBlackSpotsYears,
    selectH3Index,
    selectReport,
  },
  period,
  setLoading,
}: TCartographicDataPageContext & TOutletContext) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filteredBlackSpotsFeatures, filterBlackSpotsFeatures] = useState<TFubFeature[]>();
  const [filteredAccidentsFeatures, filterAccidentsFeatures] = useState<TFubFeature[]>();
  const [suddenBrakingsFeatures, setSuddenBrakingsFeatures] =
    useState<
      Array<
        GeoJSON.Feature<
          GeoJSON.Point,
          { h3Indexes: { [resolution in TResolution]?: string }; count: number }
        >
      >
    >();
  const [reportsFeatures, setReportsFeatures] = useState<
    Array<
      GeoJSON.Feature<
        GeoJSON.Point,
        {
          h3Indexes: { [resolution in TResolution]?: string };
          reportData: { id: number; typeCode: TReportTypeCodeWithSupport; status: TStatus };
        }
      >
    >
  >();
  const [frequenciesFeatures, setFrequenciesFeatures] =
    useState<Array<{ h3Indexes: { [resolution in TResolution]?: string }; frequency: number }>>();
  const [resolutionQuartiles, setResolutionQuartiles] = useState<{
    [key in TResolution]?: number[];
  }>({});
  const [data, setData] = useState<{ [h3Index: string]: TH3CellData }>();
  const [selectedReportId, selectReportId] = useState<number | null>(null);
  const [initialized, setInitialized] = useState(false);
  const tooltipElementRef = useRef<HTMLElement | null>();
  const tooltipContainerRef = useRef<Root>();
  const {
    map: {
      current: currentMap,
      accidentZonesShowed,
      incidentsShowed,
      facilitiesShowed: _facilitiesShowed,
      zoom,
    },
    partner: { current: currentPartner },
    actions: { toggleFacilities, getReportTypes },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise: cancellablePeriodPromise, cancelPromises: cancelPeriodPromises } =
    useCancellablePromise();
  const { cancellablePromise: cancellableReportPromise, cancelPromises: cancelReportPromise } =
    useCancellablePromise();
  const { getTileXY, getAccidents, getBlackSpots } = useVectorTiles();
  const {
    initialized: deckInitialized,
    flipTooltip,
    init: initDeck,
    update: updateDeck,
  } = useDeck({ canvasRef });
  useH3({
    h3Features,
    deckInitialized,
    setH3Features,
    setH3Map,
  });
  const { downloadCSV, downloadBlob } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();
  const previousZoomRef = useRef(zoom);

  useEffect(() => {
    const facilitiesShowed = _facilitiesShowed.valueOf();

    toggleFacilities(false);
    setInitialized(true);

    return () => {
      if (initialized) toggleFacilities(facilitiesShowed);
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    if (initialized) {
      tooltipElementRef.current = document.getElementById(`incident-tooltip`);
      if (!tooltipElementRef.current) return;

      tooltipContainerRef.current = createRoot(tooltipElementRef.current);

      if (initialized) getVectorTilesFeatures();
    }

    try {
      getReportTypes();
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.reports.types.server_error'), { variant: 'error' });
    }
  }, [initialized]);

  useEffect(() => {
    if (initialized && currentPartner) {
      getSuddenBrakings();
      getReports();
      getFrequencies();
    }

    return () => {
      setSuddenBrakingsFeatures(undefined);
      setReportsFeatures(undefined);
      setFrequenciesFeatures(undefined);
      cancelPeriodPromises();
    };
  }, [initialized, period.values.current]);

  useEffect(() => {
    if (currentMap) initDeck();
  }, [currentMap]);

  useEffect(() => {
    if (
      currentPartner &&
      filteredAccidentsFeatures &&
      filteredBlackSpotsFeatures &&
      suddenBrakingsFeatures &&
      reportsFeatures &&
      frequenciesFeatures
    ) {
      setLoading(false);

      const data: { [h3Index: string]: TH3CellData } = {};
      const defaultCellData: Omit<TH3CellData, 'id' | 'resolution' | 'h3Index' | 'score'> = {
        accidents: 0,
        deadlyAccidents: 0,
        hospitalizedAccidents: 0,
        injuredAccidents: 0,
        blackSpots: 0,
        suddenBrakings: 0,
        reports: 0,
        frequency: 0,
      };

      filteredAccidentsFeatures.forEach(({ properties: { h3Indexes, accidentData } }) => {
        const h3Index = h3Indexes[defaultResolution];
        if (h3Index) {
          if (!data[h3Index]) {
            data[h3Index] = {
              ...defaultCellData,
              resolution: defaultResolution,
              h3Index,
              accidents: 1,
              deadlyAccidents: accidentData?.type === 'deadly' ? 1 : 0,
              hospitalizedAccidents: accidentData?.type === 'hospitalized' ? 1 : 0,
              injuredAccidents: accidentData?.type === 'injured' ? 1 : 0,
              score:
                accidentData?.type === 'deadly'
                  ? 50
                  : accidentData?.type === 'hospitalized'
                    ? 40
                    : 30,
            };
          } else {
            ++data[h3Index].accidents;

            if (accidentData?.type === 'deadly') {
              ++data[h3Index].deadlyAccidents;
              data[h3Index].score += 50;
            } else if (accidentData?.type === 'hospitalized') {
              ++data[h3Index].hospitalizedAccidents;
              data[h3Index].score += 40;
            } else if (accidentData?.type === 'injured') {
              ++data[h3Index].injuredAccidents;
              data[h3Index].score += 30;
            }
          }
        }
      });

      filteredBlackSpotsFeatures.forEach(({ properties: { h3Indexes } }) => {
        const h3Index = h3Indexes[defaultResolution];
        if (h3Index) {
          if (!data[h3Index]) {
            data[h3Index] = {
              ...defaultCellData,
              resolution: defaultResolution,
              h3Index,
              blackSpots: 1,
              score: 1,
            };
          } else {
            ++data[h3Index].blackSpots;
            ++data[h3Index].score;
          }
        }
      });

      suddenBrakingsFeatures.forEach(({ properties: { h3Indexes, count } }) => {
        const h3Index = h3Indexes[defaultResolution];
        if (h3Index) {
          if (!data[h3Index]) {
            data[h3Index] = {
              ...defaultCellData,
              resolution: defaultResolution,
              h3Index,
              suddenBrakings: count,
              score: count,
            };
          } else {
            data[h3Index].suddenBrakings += count;
            data[h3Index].score += count;
          }
        }
      });

      reportsFeatures.forEach(({ properties: { h3Indexes } }) => {
        const h3Index = h3Indexes[defaultResolution];
        if (h3Index) {
          if (!data[h3Index]) {
            data[h3Index] = {
              ...defaultCellData,
              resolution: defaultResolution,
              h3Index,
              reports: 1,
              score: 10,
            };
          } else {
            ++data[h3Index].reports;
            data[h3Index].score += 10;
          }
        }
      });

      let maxFrequency = 0;
      frequenciesFeatures.forEach(({ h3Indexes, frequency }) => {
        const h3Index = h3Indexes[defaultResolution];
        if (h3Index) {
          if (data[h3Index]) {
            data[h3Index].frequency += frequency;
            maxFrequency = Math.max(maxFrequency, data[h3Index].frequency);
          }
        }
      });

      const scores = Object.values(data)
        .map(({ score }) => score)
        .sort((a, b) => (a && b ? a - b : 1));

      const quartiles = [
        ...[0, 1, 2]
          .slice(0, Math.min(2, scores.length - 1))
          .map((_, index) =>
            Math.round(scores[Math.round(scores.length * ((index + 1) / 3))] || 0),
          ),
        scores[scores.length - 1],
      ];

      setResolutionQuartiles({ [defaultResolution]: quartiles });

      setData(data);
      setBounds({ min: 0, max: maxFrequency });
      setCurrentRange([0, maxFrequency]);
    }

    return () => {
      setBounds(undefined);
      setCurrentRange(undefined);
      setData(undefined);
    };
  }, [
    filteredAccidentsFeatures,
    filteredBlackSpotsFeatures,
    suddenBrakingsFeatures,
    reportsFeatures,
    frequenciesFeatures,
  ]);

  useEffect(() => {
    setActions(
      <>
        <Tooltip title={t('cycling-insights.facilities.actions.download')}>
          <div>
            <IconButton
              color="primary"
              disabled={!data}
              onClick={({ currentTarget }) => setMenuAnchorEl(currentTarget)}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </div>
        </Tooltip>
        <Menu
          keepMounted
          anchorEl={menuAnchorEl}
          id="export-menu"
          MenuListProps={{ 'aria-labelledby': 'export-menu-button' }}
          onClose={() => setMenuAnchorEl(null)}
          open={Boolean(menuAnchorEl)}
        >
          <MenuItem
            dense
            key="csv"
            onClick={() => {
              handleCSVDownload();
              setMenuAnchorEl(null);
            }}
            value="csv"
          >
            CSV
          </MenuItem>
          <MenuItem
            key="geojson"
            onClick={() => {
              handleGeoJSONDownload();
              setMenuAnchorEl(null);
            }}
            value="geojson"
          >
            GeoJSON
          </MenuItem>
        </Menu>
      </>,
    );
  }, [data, menuAnchorEl]);

  useEffect(() => {
    if (
      zoom &&
      previousZoomRef.current &&
      ((previousZoomRef.current <= 13 && zoom > 13) || (previousZoomRef.current > 13 && zoom <= 13))
    )
      updateLayers();
    previousZoomRef.current = zoom;
  }, [zoom]);

  useEffect(() => {
    updateLayers();

    return () => {
      updateDeck({ layers: [] });
    };
  }, [
    accidentZonesShowed,
    h3Features,
    data,
    currentRange,
    filteredBlackSpotsFeatures,
    suddenBrakingsFeatures,
    filteredAccidentsFeatures,
    incidentsShowed,
    selectedH3Index,
  ]);

  function updateLayers() {
    if (h3Features && data && currentRange) {
      const maxScore = h3Features.reduce((res, value) => {
        if (data[value.properties.h3Index] && data[value.properties.h3Index].score > res)
          res = data[value.properties.h3Index].score;
        return res;
      }, 0);
      const quartiles = resolutionQuartiles[defaultResolution];
      if (!quartiles) return;
      updateDeck({
        layers: [
          ...(filteredBlackSpotsFeatures && incidentsShowed.blackSpots
            ? [
                new GeoJsonLayer({
                  id: 'black-spots',
                  data: {
                    type: 'FeatureCollection',
                    features: filteredBlackSpotsFeatures,
                  },
                  pickable: true,
                  filled: true,
                  stroked: false,
                  getFillColor: [255, 209, 47, 255],
                  pointRadiusMinPixels: 2,
                  pointRadiusMaxPixels: 2,
                }),
              ]
            : []),
          ...(suddenBrakingsFeatures && incidentsShowed.suddenBrakings
            ? [
                new GeoJsonLayer({
                  id: 'sudden-brakings',
                  data: {
                    type: 'FeatureCollection',
                    features: suddenBrakingsFeatures,
                  },
                  pickable: true,
                  filled: true,
                  stroked: false,
                  getFillColor: [62, 123, 223, 255],
                  pointRadiusMinPixels: 5,
                  pointRadiusMaxPixels: 5,
                }),
              ]
            : []),
          ...(filteredAccidentsFeatures && Object.values(incidentsShowed.accidents).includes(true)
            ? [
                new GeoJsonLayer({
                  id: 'accidents',
                  data: {
                    type: 'FeatureCollection',
                    features: filteredAccidentsFeatures.filter(
                      ({ properties: { accidentData } }) =>
                        accidentData && incidentsShowed.accidents[accidentData.type],
                    ),
                  },
                  pickable: true,
                  filled: true,
                  stroked: false,
                  getFillColor: [245, 107, 132, 255],
                  pointRadiusMinPixels: 12,
                  pointRadiusMaxPixels: 12,
                }),
              ]
            : []),
          ...(reportsFeatures && incidentsShowed.reports
            ? [
                new GeoJsonLayer({
                  id: 'reports',
                  data: {
                    type: 'FeatureCollection',
                    features: reportsFeatures,
                  },
                  pickable: true,
                  filled: true,
                  stroked: false,
                  getFillColor: [126, 72, 240, 255],
                  pointRadiusMinPixels: 12,
                  pointRadiusMaxPixels: 12,
                }),
              ]
            : []),
          ...(accidentZonesShowed
            ? [
                new GeoJsonLayer({
                  id: 'h3-cells',
                  data: {
                    type: 'FeatureCollection',
                    features: h3Features
                      .filter(({ properties }) => properties.h3Index !== selectedH3Index)
                      .reduce<
                        Array<
                          GeoJSON.Feature<
                            GeoJSON.MultiPolygon | GeoJSON.Polygon,
                            TH3CellFeatureProps
                          >
                        >
                      >((res, feature) => {
                        const {
                          properties: { h3Index },
                        } = feature;

                        const featureData = data[h3Index];
                        if (
                          !featureData ||
                          featureData.score === 0 ||
                          featureData.frequency < currentRange[0] ||
                          featureData.frequency > currentRange[1]
                        )
                          return res;

                        const colorIndex = Math.trunc(featureData.score / (maxScore / 3));

                        res.push({
                          ...feature,
                          properties: {
                            ...feature.properties,
                            ...featureData,
                            color: colors[colorIndex === 3 ? 2 : colorIndex],
                          },
                        });

                        return res;
                      }, []),
                  },
                  pickable: true,
                  stroked: true,
                  filled: true,
                  getLineWidth: zoom && zoom > 13 ? 0.5 : 0,
                  lineWidthMinPixels: zoom && zoom > 13 ? 1 : 0,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  getFillColor: ({ properties: { color } }: any) =>
                    [...color, 165] as [number, number, number, number],
                  getLineColor: [0, 0, 0, 255],
                }),
                new GeoJsonLayer({
                  id: 'selected-h3-cell',
                  data: {
                    type: 'FeatureCollection',
                    features: h3Features
                      .filter(({ properties }) => properties.h3Index === selectedH3Index)
                      .reduce<
                        Array<
                          GeoJSON.Feature<
                            GeoJSON.MultiPolygon | GeoJSON.Polygon,
                            TH3CellFeatureProps
                          >
                        >
                      >((res, feature) => {
                        const {
                          properties: { h3Index },
                        } = feature;

                        const featureData = data[h3Index];
                        if (!featureData || featureData.score === 0) return res;

                        const colorIndex = quartiles.findIndex(
                          (value) => featureData.score <= value,
                        );

                        res.push({
                          ...feature,
                          properties: {
                            ...feature.properties,
                            ...featureData,
                            color: colors[colorIndex],
                          },
                        });

                        return res;
                      }, []),
                  },
                  pickable: false,
                  stroked: true,
                  filled: false,
                  getLineWidth: 1,
                  lineWidthMinPixels: 1,
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  getLineColor: ({ properties: { color } }: any) =>
                    [...color, 255] as [number, number, number, number],
                }),
              ]
            : []),
        ],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onClick: ({
          object,
          layer,
        }: {
          layer: { id: string };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          object?: any;
        }) => {
          selectReportId(null);

          if (!currentPartner || !object || object.properties.h3Index === selectedH3Index) return;

          const {
            properties: { h3Index, reportData },
          } = object;

          if (layer.id === 'h3-cells') {
            const [lat, lng] = cellToLatLng(h3Index);

            currentMap?.flyTo({ center: { lat, lng }, zoom: 17, maxDuration: 500 });
            selectH3Index(h3Index);
          } else if (layer.id === 'reports' && reportData) {
            selectReportId(reportData.id);
          }
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        getTooltip: ({
          object,
          x,
          y,
          viewport,
          layer,
        }: {
          layer: { id: string };
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          object?: any;
          viewport: { height: number; width: number };
          x: number;
          y: number;
        }) => {
          if (
            !tooltipElementRef.current ||
            !tooltipContainerRef.current ||
            !object ||
            !layer ||
            (layer.id === 'h3-cells' && object.properties.h3Index === selectedH3Index)
          )
            return undefined;

          if (layer.id === 'h3-cells')
            tooltipContainerRef.current.render(<H3CellTooltip h3map={h3Map} object={object} />);
          else if (layer.id === 'black-spots')
            tooltipContainerRef.current.render(<BlackSpotTooltip />);
          else if (layer.id === 'sudden-brakings')
            tooltipContainerRef.current.render(<SuddenBrakingTooltip object={object} />);
          else if (layer.id === 'accidents')
            tooltipContainerRef.current.render(<AccidentTooltip object={object} />);
          else if (layer.id === 'reports')
            tooltipContainerRef.current.render(<ReportTooltip object={object} />);
          else return undefined;

          return {
            style: { transform: flipTooltip({ ele: tooltipElementRef.current, x, y, viewport }) },
            html: tooltipElementRef.current.innerHTML,
          };
        },
      });
    }
  }

  useEffect(() => {
    if (h3Map && blackSpotsFeatures) {
      filterBlackSpotsFeatures(
        blackSpotsFeatures.filter(
          ({ properties: { h3Indexes, date } }) =>
            Object.values(h3Indexes).find((h3Index) => h3Map[h3Index]) &&
            date &&
            date.isAfter(period.values.current.from) &&
            date.isBefore(period.values.current.to),
        ),
      );
    }

    return () => filterBlackSpotsFeatures(undefined);
  }, [period.values.current, h3Map, blackSpotsFeatures]);

  async function getVectorTilesFeatures() {
    if (!currentPartner) return;

    const { x: x1, y: y1 } = getTileXY(
      currentPartner.bounds.north,
      currentPartner.bounds.west,
      tilesZoom,
    );
    const { x: x2, y: y2 } = getTileXY(
      currentPartner.bounds.south,
      currentPartner.bounds.east,
      tilesZoom,
    );

    const tiles: Array<{ x: number; y: number }> = [];
    for (let x = x1; x <= x2; ++x) {
      for (let y = y1; y <= y2; ++y) {
        tiles.push({ x, y });
      }
    }

    if (!blackSpotsFeatures) {
      const { features, years } = await getBlackSpots(currentPartner.administrativeLevel, tiles);

      setBlackSpotsFeatures(features);
      setBlackSpotsYears(years);
    }

    if (!accidentsFeatures) {
      const { features, years } = await getAccidents(currentPartner.administrativeLevel, tiles);

      setAccidentsFeatures(features);
      setAccidentsYears(years);
    }
  }

  useEffect(() => {
    if (h3Map && accidentsFeatures) {
      filterAccidentsFeatures(
        accidentsFeatures.filter(
          ({ properties: { h3Indexes, date } }) =>
            Object.values(h3Indexes).find((h3Index) => h3Map[h3Index]) &&
            date &&
            date.isAfter(period.values.current.from) &&
            date.isBefore(period.values.current.to),
        ),
      );
    }

    return () => filterAccidentsFeatures(undefined);
  }, [period.values.current, h3Map, accidentsFeatures]);

  useEffect(() => {
    if (selectedReportId) getReport();

    return () => {
      cancelReportPromise();
      selectReport(null);
    };
  }, [selectedReportId]);

  async function getSuddenBrakings() {
    if (!currentPartner) return;

    try {
      const data = await cancellablePeriodPromise(
        SuddenBrakingService.getSuddenBrakings({
          partner: currentPartner,
          period: period.values.current.toIPeriod(),
          dayPeriod: 'all',
        }),
      );

      setSuddenBrakingsFeatures(
        (data.features || []).reduce<
          Array<
            GeoJSON.Feature<
              GeoJSON.Point,
              { h3Indexes: { [resolution in TResolution]?: string }; count: number }
            >
          >
        >((res, { geometry, properties: { cluster_level, nb_hard_braking: count } }) => {
          if (cluster_level !== 2) return res;

          res.push({
            type: 'Feature',
            geometry,
            properties: {
              h3Indexes: {
                [defaultResolution]: latLngToCell(
                  geometry.coordinates[1],
                  geometry.coordinates[0],
                  defaultResolution,
                ),
              },
              count,
            },
          });

          return res;
        }, []),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        setSuddenBrakingsFeatures([]);
        enqueueSnackbar(t('cycling-insights.usage.sudden_brakings.server_error'), {
          variant: 'error',
        });
        console.error(err);
      }
    }
  }

  async function getReports() {
    if (!currentPartner) return;

    try {
      const reports: Report[] = [];
      let hasNext = true;
      let page = 1;

      while (hasNext) {
        const { reports: _reports, next } = await cancellablePeriodPromise(
          ReportService.getReports({
            period: period.values.current.toIPeriod(),
            typeCodes: ['dangerous', 'pothole'],
            status: ['OPEN'],
            page: page++,
            rowsPerPage: 500,
            query: '{id, geo_point, status, report_type_code}',
          }),
        );

        reports.push(..._reports);
        hasNext = Boolean(next);
      }

      setReportsFeatures(
        reports.map(({ id, geoPoint: geometry, typeCode, status }) => ({
          id,
          type: 'Feature',
          geometry,
          properties: {
            h3Indexes: {
              [defaultResolution]: latLngToCell(
                geometry.coordinates[1],
                geometry.coordinates[0],
                defaultResolution,
              ),
            },
            reportData: { id, typeCode, status },
          },
        })),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        setReportsFeatures([]);
        enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.server_error'), {
          variant: 'error',
        });
        console.error(err);
      }
    }
  }

  async function getFrequencies() {
    if (!currentPartner) return;

    try {
      const frequencies = await cancellablePeriodPromise(
        PartnerService.getH3Frequencies(currentPartner, {
          period: period.values.current,
        }),
      );

      setFrequenciesFeatures(
        frequencies.map(({ h3Index, frequency }) => ({
          h3Indexes: { [10]: h3Index },
          frequency,
        })),
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        setFrequenciesFeatures([]);
        enqueueSnackbar(t('cycling-insights.usage.frequencies.server_error'), {
          variant: 'error',
        });
        console.error(err);
      }
    }
  }

  async function getReport() {
    if (!selectedReportId) return;

    try {
      const report = await cancellableReportPromise(ReportService.getReport(selectedReportId));

      selectReport(report);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  async function handleGeoJSONDownload() {
    if (
      !filteredBlackSpotsFeatures ||
      !suddenBrakingsFeatures ||
      !filteredAccidentsFeatures ||
      !reportsFeatures
    )
      return;

    const {
      values: { current: currentPeriod },
    } = period;

    trackEvent('File Downloaded', { file: 'Accidentology', format: 'GeoJSON' });

    const fileName = `stats-${t('cycling-insights.bicycle_observatory.navigation.accidentology')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/\s+/g, '_')
      .toLowerCase()}-${currentPeriod.from.format('YYYY')}`;

    const zip = new JSZip();

    zip.folder(fileName);

    if (filteredBlackSpotsFeatures.length > 0) {
      const blackSpotsFeaturesBlob = new Blob(
        [
          JSON.stringify(
            { type: 'FeatureCollection', features: filteredBlackSpotsFeatures },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      );

      zip.file(
        `${fileName}/${t('cycling-insights.usage.accidentology.black_spots.label')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '_')
          .toLowerCase()}.geojson`,
        blackSpotsFeaturesBlob,
      );
    }

    if (suddenBrakingsFeatures.length > 0) {
      const suddenBrakingsFeaturesBlob = new Blob(
        [JSON.stringify({ type: 'FeatureCollection', features: suddenBrakingsFeatures }, null, 2)],
        { type: 'application/json' },
      );

      zip.file(
        `${fileName}/${t('cycling-insights.usage.accidentology.sudden_brakings.label')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '_')
          .toLowerCase()}.geojson`,
        suddenBrakingsFeaturesBlob,
      );
    }

    if (filteredAccidentsFeatures.length > 0) {
      const filteredAccidentsFeaturesBlob = new Blob(
        [
          JSON.stringify(
            { type: 'FeatureCollection', features: filteredAccidentsFeatures },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      );

      zip.file(
        `${fileName}/${t('cycling-insights.usage.accidentology.accidents.label')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '_')
          .toLowerCase()}.geojson`,
        filteredAccidentsFeaturesBlob,
      );
    }

    if (reportsFeatures.length > 0) {
      const reportsFeaturesBlob = new Blob(
        [JSON.stringify({ type: 'FeatureCollection', features: reportsFeatures }, null, 2)],
        { type: 'application/json' },
      );

      zip.file(
        `${fileName}/${t('cycling-insights.usage.accidentology.geovelo_reports.label')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '_')
          .toLowerCase()}.geojson`,
        reportsFeaturesBlob,
      );
    }

    const allFeaturesBlob = new Blob(
      [
        JSON.stringify(
          {
            type: 'FeatureCollection',
            features: [
              ...filteredBlackSpotsFeatures.map(({ ...feature }) => ({
                ...feature,
                properties: { ...feature.properties, type: 'black-spot' },
              })),
              ...suddenBrakingsFeatures.map(({ ...feature }) => ({
                ...feature,
                properties: { ...feature.properties, type: 'sudden-braking' },
              })),
              ...filteredAccidentsFeatures.map(({ ...feature }) => ({
                ...feature,
                properties: { ...feature.properties, type: 'accident' },
              })),
              ...reportsFeatures.map(({ ...feature }) => ({
                ...feature,
                properties: { ...feature.properties, type: 'report' },
              })),
            ],
          },
          null,
          2,
        ),
      ],
      { type: 'application/json' },
    );

    zip.file(`${fileName}/${t('commons.all').toLowerCase()}.geojson`, allFeaturesBlob);

    const blob = await zip.generateAsync({ type: 'blob' });

    downloadBlob(`${fileName}.zip`, blob);
  }

  function handleCSVDownload() {
    if (!data) return;

    const {
      values: { current: currentPeriod },
    } = period;

    trackEvent('File Downloaded', { file: 'Accidentology', format: 'CSV' });

    downloadCSV(
      `stats-${t('cycling-insights.bicycle_observatory.navigation.accidentology')
        .replace(/ /g, '_')
        .toLowerCase()}-${currentPeriod.from.format('YYYY')}.csv`,
      [
        'Index',
        'Accidents Blessé léger',
        'Accidents Hospitalisé',
        'Accidents Décédé',
        'Signalements',
        'Freinages',
        'Points FUB',
        'Total pts',
      ],
      Object.keys(data).map((key) => {
        const {
          h3Index,
          hospitalizedAccidents,
          injuredAccidents,
          deadlyAccidents,
          reports,
          suddenBrakings,
          blackSpots,
          score,
        } = data[key];
        return [
          h3Index,
          injuredAccidents,
          hospitalizedAccidents,
          deadlyAccidents,
          reports,
          suddenBrakings,
          blackSpots,
          score,
        ];
      }),
    );
  }

  return { h3Map, data, filteredAccidentsFeatures };
}

export default useAccidentology;
