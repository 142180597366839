import {
  Period,
  StatsService,
  StoppingAreaService,
  TStoppingAreasFeatureCollection,
  prevMonth,
  useCancellablePromise,
  useFileSaver,
  useUnits,
} from '@geovelo-frontends/commons';
import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import { TColorCollection } from '../../../../components/color-legend';
import ColoredStats from '../../../../components/colored-stats';
import FacilitiesForm from '../../../../components/form/facilities';
import PeriodForm from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import TabIntroduction from '../../../../components/tab-introduction';
import useAverageSpeeds from '../../../../hooks/map/average-speeds';
import useStoppingAreas, { defaultColors } from '../../../../hooks/map/stopping-areas';
import usePeriod from '../../../../hooks/period';
import useQueryParams from '../../../../hooks/query-params';
import useSectionsStats from '../../../../hooks/sections-stats';
import useAmplitudeTracker from '../../../../hooks/tracker';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';
import FluidityChart from '../fluidity/chart';

import SpeedsComparisonChart from './comparison-chart';

const clusterLevel2MinZoom = 16;
export const colors: TColorCollection = [
  { value: '#F56B84', min: 0, max: 13 },
  { value: '#FFD978', min: 13, max: 15 },
  { value: '#46CE9D', min: 15, max: Infinity },
];

function FluidityForm(context: IBicycleObservatoryPageContext & TOutletContext): JSX.Element {
  const {
    header: { setPrevButtonClick, setActions },
    stoppingAreas: {
      data: stoppingAreasData,
      bounds: stoppingAreasBounds,
      currentRange: stoppingAreasCurrentRange,
      secondaryBounds: stoppingAreasSecondaryBounds,
      secondaryRange: stoppingAreasSecondaryRange,
      setData: setStoppingAreasData,
      setQuartiles: setStoppingAreasQuartiles,
      setBounds: setStoppingAreasBounds,
      setCurrentRange: setStoppingAreasCurrentRange,
      setSecondaryBounds: setStoppingAreasSecondaryBounds,
      setSecondaryRange: setStoppingAreasSecondaryRange,
    },
    averageSpeeds,
    period,
    loading: _loading,
    setLoading,
  } = context;
  const {
    data,
    bounds,
    currentRange,
    selectedFacilities,
    prevGlobalAverageSpeed,
    selectFacilities,
    setPrevTotalDistanceTraveled,
    setQuartiles,
    setTotalDistanceTraveled,
    globalAverageSpeed,
    sectionsComparedToAverage,
    totalDistanceTraveled,
    prevTotalDistanceTraveled,
  } = averageSpeeds;
  const {
    map: { current: currentMap, baseLayer, zoom, stoppingAreasShowed, averageSpeedsShowed },
    partner: { current: currentPartner, cyclabilityZones, sections },
    actions: { getPartnerCyclabilityZones },
  } = useContext(AppContext);
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useAverageSpeeds(currentMap, period);
  const {
    initialized: stoppingAreasLayersInitialized,
    init: initStoppingAreasLayers,
    update: updateStoppingAreasLayers,
    destroy: destroyStoppingAreasLayers,
  } = useStoppingAreas(currentMap);
  const [lastYearDistance, setLastYearDistance] = useState<number>();
  const { timeoutRef, stats, secondaryBounds, getStats } = useSectionsStats({
    hasChartComparison: true,
    automaticReloadDisabled: true,
    primaryCriterion: 'averageSpeed',
    initialized: layersInitialized,
    period,
    ...averageSpeeds,
    setLoading,
  });
  const stoppingAreasTimeoutRef = useRef<NodeJS.Timeout>();
  const [allData, setAllData] = useState<TStoppingAreasFeatureCollection | null>();
  const [prevAllData, setPrevAllData] = useState<TStoppingAreasFeatureCollection | null>();
  const [prevData, setPrevData] = useState<TStoppingAreasFeatureCollection | null>();
  const [prevStoppingTimeByKm, setPrevStoppingTimeByKm] = useState<number>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { getTitle: getPeriodTitle } = usePeriod();
  const navigate = useNavigate();
  const { toTime } = useUnits();
  const { downloadBlob } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();
  const lastYear = useRef<Period>(period.values.current.clone());
  const [prevZoom, setPrevZoom] = useState(zoom);
  const [alreadyLaunched, setAlreadyLaunched] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const initializedRef = useRef(false);
  const { getPeriods } = useQueryParams();

  useEffect(() => {
    setPrevButtonClick(() => () => navigate('../fluidity'));

    if (!initializedRef.current) {
      window.document.getElementById('left-panel')?.scrollTo({ top: 0, behavior: 'instant' });

      const defaultPeriods = getPeriods(
        moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
      );

      period.setValues(defaultPeriods);
    }

    initializedRef.current = true;

    return () => {
      setPrevButtonClick(undefined);
      cancelPromises();
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (stoppingAreasTimeoutRef.current) clearTimeout(stoppingAreasTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentPartner && !cyclabilityZones) getPartnerCyclabilityZones(currentPartner);
  }, [currentPartner]);

  useEffect(() => {
    lastYear.current = period.values.current.clone();
    lastYear.current.from.add(-1, 'year');
    lastYear.current.to.add(-1, 'year');
  }, [period.values.current, currentPartner]);

  useEffect(() => {
    if (prevTotalDistanceTraveled) {
      if (period.comparisonEnabled)
        setPrevStoppingTimeByKm(
          (prevData?.features.reduce((res, { properties: { nbStops, meanDuration } }) => {
            return (res += nbStops * meanDuration);
          }, 0) || 0) / prevTotalDistanceTraveled,
        );
      else if (lastYearDistance)
        setPrevStoppingTimeByKm(
          (prevData?.features.reduce((res, { properties: { nbStops, meanDuration } }) => {
            return (res += nbStops * meanDuration);
          }, 0) || 0) / lastYearDistance,
        );
    } else setPrevStoppingTimeByKm(undefined);
  }, [prevData, lastYearDistance, prevTotalDistanceTraveled]);

  useEffect(() => {
    if (currentMap) {
      initLayers();
      initStoppingAreasLayers();
    }

    return () => {
      destroyLayers();
      destroyStoppingAreasLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (
      zoom !== undefined &&
      (!prevZoom ||
        (prevZoom < clusterLevel2MinZoom && zoom >= clusterLevel2MinZoom) ||
        (prevZoom >= clusterLevel2MinZoom && zoom < clusterLevel2MinZoom))
    ) {
      update();
    }

    setPrevZoom(zoom);
  }, [zoom]);

  useEffect(() => {
    update();
  }, [allData]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized) return;

    const { comparisonEnabled } = period;

    if (averageSpeedsShowed && data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        if (!comparisonEnabled && data.features.length > 0 && globalAverageSpeed) {
          const { max } = bounds;
          setQuartiles([0, 13, 15, max]);
        }

        updateLayers(data, {
          colors,
          comparisonEnabled,
          currentRange,
          primaryBounds: bounds,
          secondaryBounds,
        });
      }, 300);
    } else {
      updateLayers(
        { type: 'FeatureCollection', features: [] },
        {
          comparisonEnabled: period.comparisonEnabled,
          currentRange: [0, 1],
          primaryBounds: { min: 0, max: 1 },
          secondaryBounds,
        },
      );
    }
  }, [baseLayer, layersInitialized, data, currentRange, averageSpeedsShowed]);

  useEffect(() => {
    if (stoppingAreasTimeoutRef.current) clearTimeout(stoppingAreasTimeoutRef.current);
    if (!stoppingAreasLayersInitialized) return;

    if (
      stoppingAreasData &&
      stoppingAreasBounds &&
      stoppingAreasSecondaryBounds &&
      stoppingAreasCurrentRange &&
      stoppingAreasSecondaryRange &&
      stoppingAreasShowed
    ) {
      stoppingAreasTimeoutRef.current = setTimeout(() => {
        const { min, max } = stoppingAreasBounds;
        const durations = stoppingAreasData.features
          .map(({ properties: { medianDuration } }) => medianDuration)
          .filter((duration) => duration >= min && duration <= max)
          .sort((a, b) => a - b);

        const _quartiles = [
          ...new Set([
            min,
            ...defaultColors
              .slice(0, Math.min(defaultColors.length - 1, durations.length - 1))
              .map((_, index) =>
                Math.round(
                  durations[Math.round(durations.length * ((index + 1) / defaultColors.length))] ||
                    0,
                ),
              ),
            max,
          ]),
        ];

        setStoppingAreasQuartiles(_quartiles);

        const colors = baseLayer === 'dark' ? defaultColors : [...defaultColors].reverse();
        updateStoppingAreasLayers(stoppingAreasData, {
          colors: colors.slice(0, _quartiles.length - 1).map(({ value }, index) => ({
            value,
            min: _quartiles?.[index],
            max: _quartiles?.[index + 1],
          })),
          currentRange: stoppingAreasCurrentRange,
          primaryBounds: stoppingAreasBounds,
          secondaryBounds: stoppingAreasSecondaryBounds,
          secondaryRange: stoppingAreasSecondaryRange,
        });
      }, 300);
    } else {
      updateStoppingAreasLayers(
        { type: 'FeatureCollection', features: [] },
        {
          primaryBounds: { min: 0, max: 1 },
          currentRange: [0, 1],
          secondaryBounds: { min: 0, max: 1 },
          secondaryRange: [0, 1],
        },
      );
    }
  }, [
    baseLayer,
    stoppingAreasLayersInitialized,
    stoppingAreasData,
    stoppingAreasCurrentRange,
    stoppingAreasSecondaryRange,
    stoppingAreasShowed,
  ]);

  useEffect(() => {
    if (sections && stats) setAlreadyLaunched(true);
  }, [sections, stats]);

  useEffect(() => {
    if (data)
      setActions(
        <>
          <Tooltip title={t('commons.actions.download')}>
            <IconButton color="primary" onClick={() => handleDownload()} size="small">
              <FileDownloadOutlined />
            </IconButton>
          </Tooltip>
        </>,
      );

    return () => {
      setActions(undefined);
    };
  }, [data]);

  async function getData() {
    cancelPromises();
    setAllData(undefined);
    setStoppingAreasData(undefined);
    setStoppingAreasQuartiles(undefined);
    setStoppingAreasBounds(undefined);
    setStoppingAreasSecondaryBounds(undefined);
    setStoppingAreasCurrentRange(undefined);
    setStoppingAreasSecondaryRange(undefined);

    if (!currentPartner) return;

    const {
      values: { current: currentPeriod, prev: prevPeriod, timePeriod, dayPeriod },
    } = period;

    if (!timePeriod || !dayPeriod) return;

    setLoadingData(true);

    try {
      const [currentDistances, prevDistances] = await Promise.all([
        StatsService.getDistancesTravelled(currentPartner.id, period.values.current.toIPeriod()),
        StatsService.getDistancesTravelled(
          currentPartner.id,
          period.comparisonEnabled ? period.values.prev.toIPeriod() : lastYear.current.toIPeriod(),
        ),
      ]);
      setTotalDistanceTraveled(currentDistances.all);
      if (period.comparisonEnabled) setPrevTotalDistanceTraveled(prevDistances.all);
      else setLastYearDistance(prevDistances.all);

      const [_data, _prevData] = await cancellablePromise(
        Promise.all([
          StoppingAreaService.getStoppingAreas({
            partnerId: currentPartner.id,
            period: currentPeriod.toIPeriod(),
            dayPeriod,
            timePeriod,
          }),
          StoppingAreaService.getStoppingAreas({
            partnerId: currentPartner.id,
            period: period.comparisonEnabled
              ? prevPeriod.toIPeriod()
              : lastYear.current.toIPeriod(),
            dayPeriod,
            timePeriod,
          }),
        ]),
      );

      if (_data.features.length === 0) throw new Error('no data');

      setPrevAllData(_prevData);
      setAllData(_data);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(
          t('commons.no_data', {
            date: getPeriodTitle(period.values.current),
          }),
          { variant: 'error' },
        );

        setStoppingAreasBounds({ min: 0, max: 1 });
        setStoppingAreasSecondaryBounds(undefined);
        setStoppingAreasQuartiles([0, 1]);
        setAllData(null);
        setStoppingAreasData(null);
      }
    }

    setLoadingData(false);
  }

  function update() {
    if (zoom !== undefined && allData) {
      let max = 0;
      let secondaryMax = 0;

      const filteredData: TStoppingAreasFeatureCollection = {
        ...allData,
        features: allData.features.filter(
          ({ properties: { clusterLevel } }) =>
            clusterLevel === (zoom >= clusterLevel2MinZoom ? 2 : 1),
        ),
      };

      if (prevAllData) {
        const filteredPrevData: TStoppingAreasFeatureCollection = {
          ...prevAllData,
          features:
            prevAllData?.features.filter(
              ({ properties: { clusterLevel } }) =>
                clusterLevel === (zoom >= clusterLevel2MinZoom ? 2 : 1),
            ) || [],
        };
        setPrevData(filteredPrevData);
      } else setPrevData(prevAllData);

      filteredData.features.forEach(({ properties }) => {
        max = Math.max(max, properties.totalNbStops);
        secondaryMax = Math.max(secondaryMax, properties.medianDuration);
      });

      max = Math.max(Math.ceil(max), 5);
      secondaryMax = Math.ceil(secondaryMax);

      setStoppingAreasBounds({ min: 0, max });
      setStoppingAreasCurrentRange([0, max]);
      setStoppingAreasSecondaryBounds({ min: 0, max: secondaryMax });
      setStoppingAreasSecondaryRange([0, secondaryMax]);
      setStoppingAreasData(filteredData);
    } else setStoppingAreasData(allData);
    setPrevZoom(zoom);
  }

  function handleDownload() {
    if (!data || !currentRange) return;

    trackEvent('File Downloaded', { file: 'Roads Use' });

    const {
      values: { current: currentPeriod, prev: prevPeriod },
      comparisonEnabled,
    } = period;
    const [min, max] = currentRange;

    downloadBlob(
      `stats-${t('cycling-insights.usage.navigation.road_use').replace(/ /g, '_').toLowerCase()}-${
        comparisonEnabled
          ? `${prevPeriod.from.format('YYYY-MM-DD')}_${prevPeriod.to.format('YYYY-MM-DD')}-`
          : ''
      }${currentPeriod.from.format('YYYY-MM-DD')}_${currentPeriod.to.format('YYYY-MM-DD')}.geojson`,
      new Blob(
        [
          JSON.stringify(
            {
              ...data,
              features: data.features.filter(
                ({ properties: { averageSpeed } }) =>
                  averageSpeed && averageSpeed >= min && averageSpeed <= max,
              ),
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      ),
    );
  }

  const loading = _loading || loadingData;
  const stoppingTimeByKm =
    stoppingAreasData && totalDistanceTraveled !== undefined
      ? totalDistanceTraveled
        ? (stoppingAreasData.features.reduce((res, { properties: { nbStops, meanDuration } }) => {
            return (res += nbStops * meanDuration);
          }, 0) || 0) / totalDistanceTraveled
        : 0
      : undefined;
  const sectionsCount = sectionsComparedToAverage?.reduce((res, count) => res + count, 0);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
        <Box display="flex" flexDirection="column" flexGrow={1} gap={3}>
          <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.fluidity" />
          <Paper
            header={
              <Box display="flex" flexDirection="column" gap={3}>
                <PeriodForm
                  disablePadding
                  enableCustomComparison
                  enableDayPeriods
                  enableTimePeriods
                  {...period}
                />
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography fontWeight={600} variant="subtitle2">
                    {t('commons.facilities_types')}
                  </Typography>
                  <FacilitiesForm
                    selectedFacilities={selectedFacilities}
                    selectFacilities={selectFacilities}
                  />
                </Box>
              </Box>
            }
          >
            {alreadyLaunched && (
              <>
                <Box display="flex" gap={3}>
                  <Box
                    border="1px solid #E3E7EE"
                    borderRadius={4}
                    padding={2}
                    width="calc((100% - 24px) / 2)"
                  >
                    <Typography color="#5D687E" variant="body2">
                      {t('commons.stats.average_speed_label')}
                    </Typography>
                    {globalAverageSpeed ? (
                      <Typography fontSize="1.5rem" fontWeight={600}>
                        {Math.round(globalAverageSpeed * 10) / 10} km/h
                      </Typography>
                    ) : (
                      <Skeleton variant="text" width={120} />
                    )}
                    {!!globalAverageSpeed && !!prevGlobalAverageSpeed && (
                      <Typography color="#5D687E" variant="body2">
                        <span
                          style={{
                            color:
                              globalAverageSpeed < prevGlobalAverageSpeed ? '#A42C49' : '#038B63',
                          }}
                        >
                          {globalAverageSpeed < prevGlobalAverageSpeed ? '-' : '+'}{' '}
                          {Math.abs(
                            Math.round((globalAverageSpeed - prevGlobalAverageSpeed) * 10) / 10,
                          )}{' '}
                          km/h{' '}
                        </span>
                        vs{' '}
                        {(period.comparisonEnabled
                          ? period.values.prev
                          : lastYear.current
                        ).from.format('MMMM YYYY')}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    border="1px solid #E3E7EE"
                    borderRadius={4}
                    padding={2}
                    width="calc((100% - 24px) / 2)"
                  >
                    <Typography color="#5D687E" variant="body2">
                      {t('commons.stats.mean_stop_time_per_km')}
                    </Typography>
                    {stoppingTimeByKm !== undefined ? (
                      <Typography fontSize="1.5rem" fontWeight={600}>
                        {toTime(Math.round(stoppingTimeByKm * 10000) / 10)}
                      </Typography>
                    ) : (
                      <Skeleton variant="text" width={120} />
                    )}
                    {stoppingTimeByKm !== undefined && !!prevStoppingTimeByKm && (
                      <Typography color="#5D687E" variant="body2">
                        <span
                          style={{
                            color: stoppingTimeByKm < prevStoppingTimeByKm ? '#038B63' : '#A42C49',
                          }}
                        >
                          {stoppingTimeByKm < prevStoppingTimeByKm ? '-' : '+'}{' '}
                          {toTime(
                            Math.abs(
                              Math.round((stoppingTimeByKm - prevStoppingTimeByKm) * 10000) / 10,
                            ),
                          )}{' '}
                        </span>
                        vs{' '}
                        {(period.comparisonEnabled
                          ? period.values.prev
                          : lastYear.current
                        ).from.format('MMMM YYYY')}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {period.comparisonEnabled ? (
                  <SpeedsComparisonChart {...context} />
                ) : (
                  <>
                    <FluidityChart {...context} />
                    <Box display="flex" gap={2}>
                      <ColoredStats
                        color="#FFEBEE"
                        content={
                          <Typography fontSize="0.75rem" fontWeight={400}>
                            {t('cycling-insights.usage.fluidity.slower_roads')}
                          </Typography>
                        }
                        header={
                          sectionsComparedToAverage && sectionsCount !== undefined ? (
                            <Typography fontSize="1.125rem" fontWeight={600}>
                              {sectionsCount
                                ? ((sectionsComparedToAverage[0] / sectionsCount) * 100).toFixed(1)
                                : 0}{' '}
                              %
                            </Typography>
                          ) : (
                            <Skeleton variant="text" width={80} />
                          )
                        }
                      />
                      <ColoredStats
                        color="rgba(255, 217, 120, 0.2)"
                        content={
                          <Typography fontSize="0.75rem" fontWeight={400}>
                            {t('cycling-insights.usage.fluidity.same_speed_roads')}
                          </Typography>
                        }
                        header={
                          sectionsComparedToAverage && sectionsCount !== undefined ? (
                            <Typography fontSize="1.125rem" fontWeight={600}>
                              {sectionsCount
                                ? ((sectionsComparedToAverage[1] / sectionsCount) * 100).toFixed(1)
                                : 0}{' '}
                              %
                            </Typography>
                          ) : (
                            <Skeleton variant="text" width={80} />
                          )
                        }
                      />
                      <ColoredStats
                        color="#E3F5ED"
                        content={
                          <Typography fontSize="0.75rem" fontWeight={400}>
                            {t('cycling-insights.usage.fluidity.faster_roads')}
                          </Typography>
                        }
                        header={
                          sectionsComparedToAverage && sectionsCount !== undefined ? (
                            <Typography fontSize="1.125rem" fontWeight={600}>
                              {sectionsCount
                                ? ((sectionsComparedToAverage[2] / sectionsCount) * 100).toFixed(1)
                                : 0}{' '}
                              %
                            </Typography>
                          ) : (
                            <Skeleton variant="text" width={80} />
                          )
                        }
                      />
                    </Box>
                  </>
                )}
              </>
            )}
          </Paper>
        </Box>
        {!alreadyLaunched && (
          <Box
            bgcolor="#fff"
            borderTop="1px solid rgba(0, 0, 0, 0.12)"
            bottom={0}
            display="flex"
            gap={2}
            justifyContent="flex-end"
            left={0}
            marginBottom={-3}
            marginX={-3}
            paddingX={3}
            paddingY={2}
            position="sticky"
            right={0}
            zIndex={1000}
          >
            <Tooltip title={t('cycling-insights.bicycle_observatory.long_analysis')}>
              <Button
                disableElevation
                color="primary"
                disabled={loading}
                onClick={() => {
                  getStats();
                  getData();
                }}
                size="large"
                startIcon={loading && <CircularProgress color="inherit" size={16} thickness={4} />}
                variant="contained"
              >
                {alreadyLaunched
                  ? t('commons.actions.restart_analyze')
                  : t('commons.actions.analyze')}
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
    </>
  );
}

export default FluidityForm;
