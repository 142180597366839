import {
  Button,
  Period,
  SectionStats,
  StatsService,
  roadTypesLabels,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Add } from '@mui/icons-material';
import { Box, Chip, Skeleton, Typography } from '@mui/material';
import center from '@turf/center';
import { CategoryScale, Chart } from 'chart.js';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../app/context';
import usePeriod from '../hooks/period';
import RightPanelLayout from '../layouts/right-panel';
import { IBicycleObservatoryPageContext } from '../pages/bicycle-observatory/context';
import { getProgression } from '../utils/stats';

function SectionDetails({
  period,
  roadsUse: { selectedSection, selectSection },
}: IBicycleObservatoryPageContext): JSX.Element {
  const [expanded, expand] = useState(true);
  const [progression, setProgression] = useState<number>();
  const [yearProgression, setYearProgression] = useState<number>();
  const [sectionStats, setSectionStats] = useState<SectionStats[]>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { getTitle } = usePeriod();
  const {
    partner: { current: currentPartner, sections },
  } = useContext(AppContext);
  const lastYear = useRef<Period>(period.values.current.clone());
  const chartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (selectedSection?.id) {
      if (currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated') {
        setProgression(
          getProgression(
            selectedSection.extrapolation || 0,
            selectedSection.prevExtrapolation || 0,
          ),
        );
      } else {
        lastYear.current = period.values.current.clone();
        lastYear.current.from.add(-1, 'year');
        lastYear.current.to.add(-1, 'year');
        getSectionStats();
      }
    }
  }, [selectedSection]);

  useEffect(() => {
    Chart.register(CategoryScale);
    let _chart: Chart | undefined;

    if (chartRef.current) {
      _chart = new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels: Array.from({ length: period.values.current.nbDays }, (_, i) => i + 1),
          datasets: [
            {
              label: t('commons.bike_stations.bikes') || '',
              data: period.values.current.times.map((time) => {
                const stats = sectionStats?.find(({ date }) => date?.valueOf() === time);
                return stats ? (stats.frequencyBackward || 0) + (stats.frequencyOnward || 0) : 0;
              }),
              backgroundColor: '#3E7BDF',
              borderRadius: { topLeft: 5, topRight: 5 },
              barThickness: 5,
            },
          ],
        },
        options: {
          plugins: {
            legend: { display: false },
            tooltip: {
              callbacks: {
                title: ([{ label }]) => label + ' ' + period.values.current.from.format('MMMM'),
                label: ({ parsed: { y } }) =>
                  t('commons.stats.passages', {
                    count: y as number,
                  }) || '',
              },
            },
          },
          responsive: true,
          maintainAspectRatio: true,
          scales: {
            x: {
              title: {
                align: 'end',
                display: true,
                text: period.values.current.from.format('MMMM'),
              },
              grid: { display: false },
            },
            y: {
              beginAtZero: true,
              ticks: {
                maxTicksLimit: 5,
              },
              title: {
                align: 'end',
                display: true,
                text: t('commons.stats.passages') || '',
              },
              border: { dash: [8, 8] },
            },
          },
        },
      });
    }
    _chart?.update();

    return () => {
      if (_chart) _chart.destroy();
    };
  }, [sectionStats]);

  async function getSectionStats() {
    cancelPromises();

    if (!currentPartner || !selectedSection) return;

    try {
      const [currentStats, prevStats, lastYearStats] = await cancellablePromise(
        Promise.all([
          StatsService.getSectionStats(
            currentPartner.id,
            selectedSection.id,
            period.values.current.toIPeriod(),
          ),
          StatsService.getSectionStats(
            currentPartner.id,
            selectedSection.id,
            period.values.prev.toIPeriod(),
          ),
          StatsService.getSectionStats(
            currentPartner.id,
            selectedSection.id,
            lastYear.current.toIPeriod(),
          ),
        ]),
      );
      setProgression(
        getProgression(
          selectedSection.frequency || 0,
          prevStats.reduce<number>((res, { frequencyBackward, frequencyOnward }) => {
            return (res += (frequencyBackward || 0) + (frequencyOnward || 0));
          }, 0),
        ),
      );
      setYearProgression(
        getProgression(
          selectedSection.frequency || 0,
          lastYearStats.reduce<number>((res, { frequencyBackward, frequencyOnward }) => {
            return (res += (frequencyBackward || 0) + (frequencyOnward || 0));
          }, 0),
        ),
      );
      setSectionStats(currentStats);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(
          t('commons.no_data', {
            date: getTitle(period.values.current),
          }),
          { variant: 'error' },
        );

        selectSection?.();
      }
    }
  }

  if (!selectedSection) return <></>;

  const geometry = sections?.features.find(({ id }) => id === selectedSection.id)?.geometry;

  return (
    <RightPanelLayout
      disableCollapse
      actions={
        <Button
          disableElevation
          color="primary"
          component={Link}
          startIcon={<Add />}
          state={{
            disableBaseLayerUpdate: true,
            location: geometry ? center(geometry).geometry : undefined,
          }}
          sx={{ paddingX: '24px', height: '40px', borderRadius: '8px !important' }}
          to="../counter-form"
          variant="contained"
        >
          <Trans i18nKey="cycling-insights.usage.roads_use.add_counter" />
        </Button>
      }
      content={
        <Box display="flex" flexDirection="column" paddingTop={3}>
          <Typography color="#283859" fontWeight={600} variant="body1">
            {selectedSection ? (
              <Trans
                count={
                  currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated'
                    ? selectedSection.extrapolation
                    : selectedSection.frequency
                }
                i18nKey={'commons.stats.passages'}
                values={{
                  count:
                    currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated'
                      ? Math.round(selectedSection.extrapolation || 0)
                      : selectedSection.frequency,
                }}
              />
            ) : (
              <Skeleton variant="text" width={100} />
            )}
          </Typography>
          <Box display="flex" gap={3} marginBottom={3} marginTop="12px">
            {progression !== undefined || yearProgression !== undefined ? (
              <>
                {progression !== undefined && (
                  <Box alignItems="center" display="flex" gap="6px">
                    <StyledChip
                      label={`${
                        progression >= 0 ? '+ ' + progression : '- ' + Math.abs(progression)
                      }% `}
                      negative={progression < 0 ? 'true' : 'false'}
                    />
                    <Typography variant="body2">
                      vs {t('commons.periods.prev_month').toLocaleLowerCase()}
                    </Typography>
                  </Box>
                )}
                {yearProgression !== undefined && (
                  <Box alignItems="center" display="flex" gap="6px">
                    <StyledChip
                      label={`${
                        yearProgression >= 0
                          ? '+ ' + yearProgression
                          : '- ' + Math.abs(yearProgression)
                      }% `}
                      negative={yearProgression < 0 ? 'true' : 'false'}
                    />
                    <Typography variant="body2">
                      vs {t('commons.periods.last_year').toLocaleLowerCase()}
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Skeleton variant="text" width={50} />
                <Skeleton variant="text" width={50} />
              </>
            )}
          </Box>
          {currentPartner?.dashboardTabsPermissions.usageRoadsUse !== 'extrapolated' && (
            <Box border="1px solid #CFCFCF" borderRadius={2} padding={2}>
              <Box height={200}>
                <canvas
                  id="section-stats-chart"
                  ref={chartRef}
                  style={{ height: '100%', width: '100%' }}
                />
              </Box>
            </Box>
          )}
        </Box>
      }
      expand={expand}
      expanded={expanded}
      header={
        <Typography fontSize="1.125rem" fontWeight={700} variant="h6">
          {selectedSection.wayName
            ? selectedSection.wayName
            : `${t(roadTypesLabels[selectedSection.roadType || 'unclassified'])} - ${
                selectedSection.id
              }`}
        </Typography>
      }
      onClose={() => selectSection?.()}
    />
  );
}

const StyledChip = styled(Chip)<{ negative: string }>`
  && {
    height: 26px;
    background-color: ${({ negative }) => (negative === 'true' ? '#FFEBEE' : '#EEF8F4')};
    color: ${({ negative }) => (negative === 'true' ? '#A42C49' : '#038B63')};
    > * {
      padding: 8px;
      font-size: 0.75rem;
    }
  }
`;

export default SectionDetails;
