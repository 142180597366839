import { useFileSaver } from '@geovelo-frontends/commons';
import { Close } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useAmplitudeTracker from '../../../../hooks/tracker';
import { TOutletContext } from '../../../../layouts/page/container';
import { TCartographicDataPageContext } from '../../context';
import MostImportantDiscontinuities from '../discontinuity/most-important';

function Result({
  onClose,
  ...context
}: TCartographicDataPageContext & TOutletContext & { onClose: () => void }): JSX.Element {
  const {
    header: { setActions, setPrevButtonClick, setTitle },
    discontinuity: { currentRange, data },
    loading,
  } = context;
  const { t } = useTranslation();
  const { trackEvent: _trackEvent } = useAmplitudeTracker();
  const { downloadBlob } = useFileSaver();

  useEffect(() => {
    setPrevButtonClick(undefined);
    setTitle(t('commons.result'));
    setActions(
      <Tooltip title={t('commons.actions.go_back')}>
        <IconButton color="primary" onClick={onClose} size="small">
          <Close />
        </IconButton>
      </Tooltip>,
    );
  }, []);

  function handleDownload() {
    if (!data || !currentRange) return;

    _trackEvent('File Downloaded', { file: 'Discontinuity analysis' });

    const [min, max] = currentRange;

    downloadBlob(
      `stats-${t('cycling-insights.bicycle_observatory.navigation.discontinuity')
        .replace(/ /g, '_')
        .toLowerCase()}.geojson`,
      new Blob(
        [
          JSON.stringify(
            {
              ...data,
              features: data.features.filter(
                ({ properties: { discontinuityIndex } }) =>
                  discontinuityIndex !== undefined &&
                  discontinuityIndex >= min &&
                  discontinuityIndex <= max,
              ),
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      ),
    );
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3}>
        <MostImportantDiscontinuities clickDisabled {...context} />
      </Box>
      <Box display="flex" gap={2} justifyContent="flex-end">
        <StyledButton
          disableElevation
          color="primary"
          disabled={loading}
          onClick={() => handleDownload()}
          variant="outlined"
        >
          {t('commons.actions.download')}
        </StyledButton>
        {/* <StyledButton
          disableElevation
          color="primary"
          disabled={loading}
          onClick={() => {
            setting(true);
          }}
          variant="contained"
        >
          {t('commons.actions.restart_analyze')}
        </StyledButton>*/}
      </Box>
    </>
  );
}

const StyledButton = styled(Button)`
  border-radius: 8px;
  height: 54px;
  && {
    padding: 0 24px;
    font-weight: 700;
  }
`;

export default Result;
