import { useFileSaver, useUnits } from '@geovelo-frontends/commons';
import { Box, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import moment from 'moment';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import DualStats, { TStats } from '../../../../components/dual-stats';
import DownloadIcon from '../../../../components/icons/download';
import useAmplitudeTracker from '../../../../hooks/tracker';
import { getPeriodsDiff } from '../../../../utils/period';
import { IBicycleObservatoryPageContext } from '../../context';

const keys = ['distance', 'nbRoutes', 'nbComputedRoutes'] as const;

export type TStatKey = (typeof keys)[number];

const keysMap: { [key in TStatKey]: { i18nKey: string } } = {
  distance: { i18nKey: 'commons.stats.distance' },
  nbRoutes: { i18nKey: 'commons.stats.journeys' },
  nbComputedRoutes: { i18nKey: 'commons.stats.computed_routes' },
};

function BicycleTravelAnalysisData({
  period: {
    values: { prev: prevPeriod, current: currentPeriod },
  },
  activityStats: { data },
}: IBicycleObservatoryPageContext): JSX.Element {
  const [stats, setStats] = useState<{ [key in TStatKey]: TStats }>();
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { formatNumber, toDistance } = useUnits();
  const { downloadCSV } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    if (data) {
      const { type: diffType, value: diff } = getPeriodsDiff(currentPeriod, prevPeriod);

      setStats(
        keys.reduce<{ [key in TStatKey]: TStats }>(
          (res, key) => {
            const count = data?.current[key] || 0;
            const prevCount = data?.prev[key] || 0;

            res[key] = {
              current: {
                count,
                values:
                  data?.current.data.map(({ date, ...values }) => ({ date, value: values[key] })) ||
                  [],
              },
              prev: {
                count: prevCount,
                values:
                  data?.prev.data.map(({ date: initialDate, ...values }) => ({
                    initialDate,
                    date: moment(initialDate).add(diff, diffType),
                    value: values[key],
                  })) || [],
              },
              progression: data.progressions[key],
            };

            return res;
          },
          {
            distance: undefined,
            nbRoutes: undefined,
            nbComputedRoutes: undefined,
          },
        ),
      );
    } else {
      setStats(undefined);
    }
  }, [data]);

  function handleDownload() {
    if (!data) return;

    trackEvent('File Downloaded', { file: 'Geovelo Activity' });

    downloadCSV(
      `geovelo-activity-${prevPeriod.from.format('YYYY-MM-DD')}_${currentPeriod.to.format(
        'YYYY-MM-DD',
      )}.csv`,
      [
        t('commons.stats.date_label'),
        `${t(keysMap.distance.i18nKey, { context: 'label' })} (m)`,
        `${t(keysMap.nbRoutes.i18nKey, { context: 'label' })}`,
        `${t(keysMap.nbComputedRoutes.i18nKey, { context: 'label' })}`,
      ],
      [...data.prev.data, ...data.current.data].map(({ date, ...stats }) => [
        date.format('YYYY-MM-DD'),
        stats.distance,
        stats.nbRoutes,
        stats.nbComputedRoutes,
      ]),
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={2}
      padding={2}
      sx={{ backgroundColor: 'whitesmoke', overflowY: 'auto' }}
    >
      <Box display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          disabled={!data}
          onClick={handleDownload}
          size="small"
          startIcon={<DownloadIcon />}
          variant="outlined"
        >
          <Trans i18nKey="commons.actions.download" />
        </Button>
      </Box>
      <Grid container spacing={2}>
        {keys.map((key) => {
          if (key === 'nbComputedRoutes' && !currentPartner?.area) return <Fragment key={key} />;

          const i18nKey = keysMap[key].i18nKey;
          const count = data?.current[key] || 0;
          const prevCount = data?.prev[key] || 0;
          const selectedStats = stats?.[key];

          return (
            <Grid item key={key} xl={6} xs={12}>
              <DualStats
                chartId={`${key}-chart`}
                currentPeriod={currentPeriod}
                currentStatLabel={
                  <Trans
                    count={count}
                    i18nKey={i18nKey}
                    values={
                      key === 'distance'
                        ? { count, distance: toDistance(count) }
                        : { count, countFormatted: formatNumber(count) }
                    }
                  />
                }
                labelFormat={key === 'distance' ? toDistance : formatNumber}
                prevPeriod={prevPeriod}
                prevStatLabel={
                  <Trans
                    count={prevCount}
                    i18nKey={i18nKey}
                    values={
                      key === 'distance'
                        ? { count: prevCount, distance: toDistance(prevCount) }
                        : { count: prevCount, countFormatted: formatNumber(prevCount) }
                    }
                  />
                }
                stats={selectedStats}
                title={
                  <Box alignItems="center" display="flex" gap={2}>
                    <Trans i18nKey={i18nKey} values={{ context: 'label' }} />
                    <Tooltip title={<Trans i18nKey="commons.stats.progression" />}>
                      <Typography
                        color={
                          selectedStats
                            ? selectedStats.progression > 0
                              ? green[500]
                              : red[500]
                            : 'inherit'
                        }
                        component="span"
                      >
                        {selectedStats ? (
                          selectedStats.progression >= 0 ? (
                            `(+${selectedStats.progression}%)`
                          ) : (
                            `(${selectedStats.progression}%)`
                          )
                        ) : (
                          <Skeleton component="span" variant="text" width={50} />
                        )}
                      </Typography>
                    </Tooltip>
                  </Box>
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

export default BicycleTravelAnalysisData;
