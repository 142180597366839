import { DashboardPages, File as DownloadFile, FileService } from '@geovelo-frontends/commons';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import useAmplitudeTracker from '../../hooks/tracker';

import DownloadsTable from './components/table';

function DownloadsPage(): JSX.Element {
  const [files, setFiles] = useState<DownloadFile[]>();
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
    actions: { setCurrentPage, setCurrentTab },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    init();

    setCurrentPage(DashboardPages.Downloads);
    setCurrentTab(undefined);
  }, []);

  useEffect(() => {
    if (currentUser && currentPartner) {
      trackEvent('Page Visited', { pathname: window.location.pathname });
    }
  }, [currentUser, currentPartner]);

  async function init() {
    try {
      const files = await FileService.getFiles();

      setFiles(files);
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.downloads.server_error'), { variant: 'error' });
    }
  }

  function handleDownloaded(id: number) {
    if (!files) return;

    const index = files.findIndex((file) => file.id === id);
    if (index >= -1) {
      files[index].incrementDownloadsCount();
      setFiles([...files]);
    }
  }

  return <DownloadsTable files={files} onDowloaded={handleDownloaded} />;
}

export default DownloadsPage;
