import {
  CyclabilityZone,
  CyclabilityZoneStats,
  Facilities,
  Period,
  ReportService,
  TPeriod,
  prevMonth,
  useCancellablePromise,
  useCyclabilityZones,
  useUnits,
} from '@geovelo-frontends/commons';
import { Add, FileDownloadOutlined, KeyboardArrowRight, Place } from '@mui/icons-material';
import { Box, IconButton, Paper as MuiPaper, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import PeriodForm, { IPeriodFormValues } from '../../../../components/form/period';
import ContributionsIcon from '../../../../components/icons/contributions';
import LinkCard from '../../../../components/link-card';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useAmplitudeTracker from '../../../../hooks/tracker';
import { TCartographicDataPageContext } from '../../context';
import useFacilities from '../../hooks/facilities';

import FacilitiesChart from './chart';

import { LngLatBounds } from '!maplibre-gl';

const minPeriod = new Period(
  'month',
  moment('2021-10').startOf('month'),
  moment('2021-10').endOf('month'),
);

const maxPeriod = moment().get('date') <= 7 ? prevMonth : undefined;

function FacilitiesForm(
  context: TCartographicDataPageContext & {
    openDownloadDialog: (value: boolean) => void;
    openSendFileDialog: (value: boolean) => void;
  },
): JSX.Element {
  const {
    openDownloadDialog,
    openSendFileDialog,
    defaultPeriods,
    period,
    facilities: {
      cyclabilityZone,
      prevCyclabilityZone,
      cyclabilityZones,
      prevCyclabilityZones,
      zonesMap,
      setPrevStats,
      setStats,
      selectZone,
      selectedZone,
      stats,
    },
  } = context;
  const [currentYearReportsCount, setCurrentYearReportsCount] = useState<number>();
  const [progressions, setProgressions] =
    useState<{ id: number; title: string; subtitle: string; value: number }[]>();
  const [initialized, setInitialized] = useState(false);
  const [customPeriodTypes] = useState<{
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  }>({ defaultPeriods, enabledTypes: ['month'] });
  const {
    map: { current: currentMap, baseLayer },
    partner: { current: currentPartner, currentArea },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { toDistance } = useUnits();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  useFacilities({ context });
  const {
    init: initLayers,
    initialized: layersInitialized,
    update: updateLayers,
    displayCurrentArea,
    destroy: destroyLayers,
  } = useCyclabilityZones(
    currentMap,
    zonesMap || {},
    {
      onClick: selectZone,
      getTooltipContent: (properties) => {
        return `<h3>${properties?.name}</h3><span>${toDistance(
          (properties?.distance || 0) * 1000,
        )}</span>`;
      },
    },
    { darkTheme: baseLayer === 'dark' },
  );
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    setInitialized(true);

    return () => {
      cancelPromises();
      setStats();
      setPrevStats();
    };
  }, []);

  useEffect(() => {
    if (initialized) getReports();
  }, [initialized]);

  useEffect(() => {
    if (currentMap) {
      initLayers();
    }

    return () => {
      destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (
      currentPartner &&
      currentArea &&
      ['region', 'department'].includes(currentPartner.administrativeLevel)
    )
      displayCurrentArea(currentArea);
  }, [currentMap, currentArea]);

  useEffect(() => {
    if (!currentMap) return;

    let bounds;
    if (selectedZone) bounds = selectedZone.bounds;
    else if (currentPartner) bounds = currentPartner.bounds;

    if (bounds) {
      const { north, east, south, west } = bounds;
      currentMap.fitBounds(new LngLatBounds({ lat: south, lng: west }, { lat: north, lng: east }));
    }
  }, [currentMap, selectedZone]);

  useEffect(() => {
    if (layersInitialized && cyclabilityZones) {
      updateLayers(
        cyclabilityZones.length === 1 ? (selectedZone ? selectedZone : cyclabilityZones[0]) : null,
        cyclabilityZones.length > 1 ? cyclabilityZones : null,
        period.values.current.to.format('YYYY-MM'),
      );
    }
  }, [layersInitialized, cyclabilityZones, selectedZone, period]);

  useEffect(() => {
    if (!currentPartner || !cyclabilityZones || !prevCyclabilityZones) return;

    if (!selectedZone) {
      if (cyclabilityZone) {
        setStats(getStats([cyclabilityZone]));
        setPrevStats(
          prevCyclabilityZone
            ? {
                [prevCyclabilityZone.id]: prevCyclabilityZone.stats[0],
                global: getStats([prevCyclabilityZone]),
              }
            : undefined,
        );
      } else {
        setStats(getStats(cyclabilityZones));
        setPrevStats(
          prevCyclabilityZones.reduce<{
            [key: number]: CyclabilityZoneStats;
            global: CyclabilityZoneStats;
          }>(
            (res, { id, stats }) => {
              res[id] = stats[0];

              return res;
            },
            { global: getStats(prevCyclabilityZones) },
          ),
        );
      }
    } else {
      const prevSelectedZone = prevCyclabilityZones?.find((zone) => zone.id === selectedZone.id);
      setStats(getStats(cyclabilityZones.filter((zone) => zone.id === selectedZone.id)));
      setPrevStats(
        prevSelectedZone
          ? {
              [prevSelectedZone.id]: getStats([prevSelectedZone]),
              global: getStats([prevSelectedZone]),
            }
          : undefined,
      );
    }

    setProgressions(
      prevCyclabilityZones
        ?.map((zone) => {
          const distance =
            cyclabilityZones?.find(({ id }) => zone.id === id)?.stats[0]?.distances.all || 0;

          return {
            zone,
            distance,
            diff: distance - (zone.stats[0]?.distances.all || 0),
          };
        })
        .filter(({ diff }) => diff > 0)
        .sort((a, b) => b.diff - a.diff)
        .slice(0, 3)
        .map(({ zone, distance, diff }) => ({
          id: zone.id,
          title: zone.name,
          subtitle: toDistance(distance * 1000),
          value: diff,
        })),
    );

    return () => {
      setStats();
      setPrevStats();
      setProgressions(undefined);
    };
  }, [cyclabilityZones, prevCyclabilityZones, selectedZone]);

  async function getReports() {
    setCurrentYearReportsCount(undefined);

    try {
      const { count } = await cancellablePromise(
        ReportService.getReports({
          period: {
            period: 'custom',
            from: moment().startOf('year'),
            to: moment(),
            unit: 'day',
          },
          typeCodes: ['bikeFacility', 'cartographicIssue'],
          status: ['CLOSED', 'ARCHIVED', 'CLOSED_BY_OSM'],
          page: 1,
          rowsPerPage: 1,
          query: '{id}',
        }),
      );

      setCurrentYearReportsCount(count);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.reports.cartographic_reports.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  function getStats(cyclabilityZones: CyclabilityZone[]): CyclabilityZoneStats {
    const distances: { [key in Facilities]: number } = {
      [Facilities.Cycleways]: 0,
      [Facilities.Greenways]: 0,
      [Facilities.Lanes]: 0,
      [Facilities.Opposites]: 0,
      [Facilities.SharedBusways]: 0,
      [Facilities.MixedFacilities]: 0,
    };

    let cmp = 0;

    cyclabilityZones.forEach(({ stats }) => {
      if (stats[0]) {
        ++cmp;
        Object.values(Facilities).forEach((facilityKey) => {
          distances[facilityKey] += stats[0].distances[facilityKey] || 0;
        });
      }
    });

    if (cmp === 0) {
      return new CyclabilityZoneStats(period.values.current.from, {
        [Facilities.Cycleways]: 0,
        [Facilities.Greenways]: 0,
        [Facilities.Lanes]: 0,
        [Facilities.Opposites]: 0,
        [Facilities.SharedBusways]: 0,
        [Facilities.MixedFacilities]: 0,
        all: Object.values(distances).reduce((res, distance) => res + distance, 0),
      });
    }

    return new CyclabilityZoneStats(period.values.current.from, {
      ...distances,
      all: Object.values(distances).reduce((res, distance) => res + distance, 0),
    });
  }

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      {!selectedZone && (
        <>
          <TabIntroduction title="cycling-insights.cartographic_data.introduction.facilities" />
          <Wrapper elevation={0}>
            <Box alignItems="center" display="flex" gap={2} marginBottom={3}>
              <IconButton
                disableRipple
                color="primary"
                onClick={() => {
                  trackEvent('Button Clicked', { cta: 'Facilities File Upload Button' });
                  openSendFileDialog(true);
                }}
              >
                <Add />
              </IconButton>
              <Typography variant="body2">
                <Trans
                  components={[
                    <StyledLink
                      className="active"
                      key={0}
                      onClick={() => {
                        trackEvent('Button Clicked', { cta: 'Facilities File Download Button' });
                        openSendFileDialog(true);
                      }}
                    />,
                    <br key={1} />,
                  ]}
                  i18nKey="cycling-insights.facilities.actions.gis_files"
                  values={{ context: 'detailed' }}
                />
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" gap={2}>
              <IconButton
                disableRipple
                color="primary"
                disabled={!stats}
                onClick={() => openDownloadDialog(true)}
              >
                <FileDownloadOutlined />
              </IconButton>
              <Typography variant="body2">
                <Trans
                  components={[
                    <StyledLink
                      className={!stats ? 'disabled' : 'active'}
                      key={0}
                      onClick={() => openDownloadDialog(true)}
                    />,
                    <br key={1} />,
                  ]}
                  i18nKey="cycling-insights.facilities.actions.download"
                  values={{ context: 'detailed' }}
                />
              </Typography>
            </Box>
          </Wrapper>
        </>
      )}
      <Paper
        header={
          <PeriodForm
            disablePadding
            disablePeriodTypeChange
            customPeriodTypes={customPeriodTypes}
            maxPeriod={maxPeriod}
            minPeriod={minPeriod}
            {...period}
          />
        }
      >
        <FacilitiesChart {...context} />
      </Paper>
      {cyclabilityZones && cyclabilityZones.length > 1 && (
        <>
          <Ranking
            enableSubtitles
            action={
              <Button
                component={Link}
                endIcon={<KeyboardArrowRight />}
                to="../facilities-stats"
                variant="text"
              >
                <Trans i18nKey="cycling-insights.facilities.progress.all_zones" />
              </Button>
            }
            data={progressions}
            formatProgress={(value) => `${value > 0 ? '+' : ''}${Math.round(value * 10) / 10} km`}
            onClick={(id) => selectZone(zonesMap[id])}
            startIcon={<Place sx={{ color: '#E76685' }} />}
            subtitle={
              !period.comparisonEnabled ? (
                <Typography marginBottom={2} marginTop={1} variant="body2">
                  <Trans i18nKey="cycling-insights.facilities.progress.subtitle" />
                </Typography>
              ) : undefined
            }
            title="cycling-insights.facilities.progress.title"
          />
          <LinkCard
            description={
              <Trans
                i18nKey="cycling-insights.facilities.contributions.description"
                values={{ year: moment().year() }}
              />
            }
            Icon={ContributionsIcon}
            title={
              currentYearReportsCount !== undefined ? (
                <Trans
                  count={currentYearReportsCount}
                  i18nKey="commons.stats.contributions"
                  values={{ count: currentYearReportsCount }}
                />
              ) : (
                <Skeleton variant="text" width={200} />
              )
            }
            to="../facilities-reports"
          />
        </>
      )}
    </Box>
  );
}

const Wrapper = styled(MuiPaper)`
  padding: 24px;
  && {
    border-radius: 16px;
  }
`;

const StyledLink = styled.span`
  text-decoration: underline;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};

  &.disabled {
    color: rgba(0, 0, 0, 0.26);
  }

  &.active {
    color: #326ac2;

    &:hover {
      cursor: pointer;
    }
  }
`;

export default FacilitiesForm;
