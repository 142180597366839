import {
  DashboardPages,
  Loading,
  RefRouteService,
  Ride,
  RideService,
  RideTrace,
  RideTraceService,
  Route,
  RouteService,
  TBackendPublicationStatus,
} from '@geovelo-frontends/commons';
import { ArrowBack, BugReport, Delete, ExpandMore } from '@mui/icons-material';
import { IconButton, Skeleton, Tab, Tabs, Toolbar, Tooltip, Typography } from '@mui/material';
import { red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link, LinkProps, Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { AppContext } from '../../app/context';
import Button from '../../components/button';
import ConfirmDialog from '../../components/confirm-dialog';
import TabPanel from '../../components/tab-panel';
import useAmplitudeTracker from '../../hooks/tracker';
import { ridesTab } from '../dashboard-routes';

import AddToTestsMenu from './add-to-tests-menu';
import DataTab from './components/data';
import RouteTab from './components/route';
import StepsTab from './components/steps';
import PublicationStatus from './publication-status';
import PublicationStatusDialog, { TPublicationDialogStatus } from './publication-status-dialog';
import { TTab, tabs as _tabs } from './tab';

const tabs: Array<{
  key: TTab;
  labelKey: string;
}> = [
  { key: 'data', labelKey: 'cycling-insights.ride.navigation.data' },
  { key: 'route', labelKey: 'cycling-insights.ride.navigation.route' },
  { key: 'steps', labelKey: 'cycling-insights.ride.navigation.steps' },
];

function RideDetail(): JSX.Element {
  const [ride, setRide] = useState<Ride | null>();
  const [route, setRoute] = useState<Route | null>();
  const [returnRoute, setReturnRoute] = useState<Route | null>();
  const [traces, setTraces] = useState<RideTrace[]>();
  const [publicationDialogStatus, setPublicationDialogStatus] =
    useState<TPublicationDialogStatus>(null);
  const [addToTestsMenuAnchorEl, setAddToTestsMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );
  const [publicationDialogOpen, openPublicationDialog] = useState(false);
  const [removeDialogOpen, openRemoveDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addingToTests, setAddingToTests] = useState(false);
  const {
    user: { current: currentUser },
    partner: { current: currentPartner },
    ride: { themes, traces: condensedTraces },
    actions: { setCurrentPage, setCurrentTab, setRideThemes, setRideTraces: setCondensedTraces },
  } = useContext(AppContext);
  const { state } = useLocation();
  const params = useParams<{ id: string; tab: string }>();
  const { transitions } = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedTab, selectTab] = useState<TTab>();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    init();

    setCurrentPage(DashboardPages.Promotion);
    setCurrentTab(ridesTab);
  }, []);

  useEffect(() => {
    if (currentUser && currentPartner) {
      let pathname = window.location.pathname;
      const index = window.location.pathname.indexOf(currentPartner.code);
      if (index > -1) {
        pathname = pathname.substring(index + currentPartner.code.length);
      }

      trackEvent('Page Visited', { pathname });
    }
  }, [currentUser, currentPartner]);

  useEffect(() => {
    if (params.tab && _tabs.indexOf(params.tab as TTab) > -1) selectTab(params.tab as TTab);
    else selectTab(undefined);
  }, [params.tab]);

  useEffect(() => {
    if (selectedTab === 'steps') {
      enqueueSnackbar(t('cycling-insights.ride.steps.add_info'), {
        action: (key) => (
          <>
            <Button color="inherit" onClick={() => closeSnackbar(key)} size="small">
              <Trans i18nKey="commons.actions.close" />
            </Button>
          </>
        ),
      });
    }
  }, [selectedTab]);

  async function init() {
    const { id: _id } = params;
    const id = parseInt(_id || '');

    if (!themes) {
      try {
        const rideThemes = await RideService.getRideThemes();

        setRideThemes(rideThemes);
      } catch {
        enqueueSnackbar(t('cycling-insights.tourism.ride_themes.server_error'));
      }
    }

    if (!condensedTraces) initCondensedTraces();

    try {
      if (isNaN(id)) throw new Error('');

      const _ride = await RideService.getRide(id);

      setRide(_ride);
      initRoute(_ride);
      initTraces(_ride);
    } catch {
      setRide(null);
      setRoute(null);
      setReturnRoute(null);
      enqueueSnackbar(t('cycling-insights.ride.server_error'), { variant: 'error' });
    }
  }

  async function initCondensedTraces() {
    try {
      const _condensedTraces = await RideTraceService.getRideTraces();

      setCondensedTraces(_condensedTraces);
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.ride_traces.server_error'));
    }
  }

  async function initRoute({ routeId, returnRouteId }: Ride) {
    try {
      if (returnRouteId) {
        const _route = await RouteService.getRoute(returnRouteId);
        setReturnRoute(_route);
      } else {
        setReturnRoute(null);
      }
      if (routeId) {
        const _route = await RouteService.getRoute(routeId);
        setRoute(_route);
      } else {
        setRoute(null);
      }
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.route.server_error'));
    }
  }

  async function initTraces({ traceIds }: Ride) {
    try {
      const _traces = await Promise.all(traceIds.map((id) => RideTraceService.getRideTrace(id)));

      setTraces(
        _traces.reduce<RideTrace[]>((res, trace) => {
          if (trace) res.push(trace);

          return res;
        }, []),
      );
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.ride_traces.server_error'));
    }
  }

  function handlePublicationDialogOpen(status: TPublicationDialogStatus) {
    setPublicationDialogStatus(status);
    openPublicationDialog(true);
  }

  function handlePublicationDialogClose() {
    openPublicationDialog(false);

    setTimeout(() => {
      setPublicationDialogStatus(null);
    }, transitions.duration.leavingScreen);
  }

  async function handlePublicationStatusUpdate() {
    if (!ride || !ride.id || !publicationDialogStatus) return;

    setLoading(true);

    let newStatus: TBackendPublicationStatus;
    if (publicationDialogStatus === 'publish') newStatus = 'PUBLISHED';
    else if (publicationDialogStatus === 'requestPublication') newStatus = 'WAITING_FOR_APPROVAL';
    else newStatus = 'UNPUBLISHED';

    try {
      const updatedRide = await RideService.updateRideV1(ride.id, {
        publication_status: newStatus,
      });

      enqueueSnackbar(t('cycling-insights.ride.updated'), { variant: 'success' });
      setRide(updatedRide);
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_updated'), { variant: 'error' });
    }

    handlePublicationDialogClose();
    setLoading(false);
  }

  async function handleRemove() {
    if (!ride || !ride.id) return;

    setLoading(true);

    try {
      await RideService.removeRide(ride.id);

      enqueueSnackbar(t('cycling-insights.ride.remove_dialog.success'), { variant: 'success' });
      setRide(null);
      setRoute(null);
      setReturnRoute(null);
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.remove_dialog.server_error'), { variant: 'error' });
    }

    openRemoveDialog(false);
    setLoading(false);
  }

  async function handleAddingToTests(isReturnRoute: boolean) {
    if (!ride || !route) return;

    setAddingToTests(true);

    try {
      if (isReturnRoute && returnRoute) {
        await RefRouteService.create({
          route: returnRoute,
          description: ride.title + ' (return)' || '',
        });
        enqueueSnackbar(t('cycling-insights.ride.add_to_tests.added_to_tests_return'), {
          variant: 'success',
        });
      } else {
        await RefRouteService.create({ route, description: ride.title || '' });
        enqueueSnackbar(t('cycling-insights.ride.add_to_tests.added_to_tests_main'), {
          variant: 'success',
        });
      }
    } catch {
      enqueueSnackbar(t('cycling-insights.ride.not_added_to_tests'), { variant: 'error' });
    }

    setAddingToTests(false);
  }

  if (!selectedTab) return <Navigate to="data" />;

  if (ride === null) return <Navigate to={`/${currentPartner?.code}/promotion`} />;

  const fromBikeRouteId = (state as { fromBikeRoute?: number } | undefined)?.fromBikeRoute;

  return (
    <>
      <Helmet>
        {ride ? (
          <title>
            {t('cycling-insights.app_title')}&nbsp;|&nbsp;{t('commons.navigation.tourism')}
            &nbsp;|&nbsp;
            {t('cycling-insights.tourism.navigation.rides')}&nbsp;|&nbsp;{ride.title}
          </title>
        ) : (
          <title>
            {t('cycling-insights.app_title')}&nbsp;|&nbsp;{t('commons.navigation.tourism')}
            &nbsp;|&nbsp;
            {t('cycling-insights.tourism.navigation.rides')}
          </title>
        )}
        {ride ? (
          <meta content={ride.description || ''} name="description" />
        ) : (
          <meta content={t('commons.navigation.tourism_description') || ''} name="description" />
        )}
      </Helmet>
      <Wrapper>
        <StyledToolbar>
          <Tooltip
            title={
              <Trans
                i18nKey={
                  fromBikeRouteId
                    ? 'cycling-insights.ride.actions.back_to_route'
                    : 'cycling-insights.ride.actions.back_to_list'
                }
              />
            }
          >
            <IconButton
              component={StyledBackButton}
              size="medium"
              to={
                fromBikeRouteId
                  ? `/${currentPartner?.code}/promotion/bike-routes/${fromBikeRouteId}`
                  : `/${currentPartner?.code}/promotion/rides`
              }
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
          {ride ? (
            <>
              <Typography lineHeight={1} variant="h6">
                {ride.title || (
                  <Trans i18nKey="cycling-insights.ride.title" values={{ id: ride.id }} />
                )}
              </Typography>
              {ride.creator?.username && (
                <Typography
                  alignItems="flex-end"
                  color="textSecondary"
                  display="flex"
                  height={18}
                  lineHeight={1}
                  variant="caption"
                >
                  <Trans
                    i18nKey="cycling-insights.ride.created_by"
                    values={{ creator: ride.creator.username }}
                  />
                </Typography>
              )}
            </>
          ) : (
            <Typography component="div" lineHeight={1} variant="h6">
              <Skeleton variant="text" width={200} />
            </Typography>
          )}
          <StyledSpacer />
          {currentPartner?.dashboardTabsPermissions.qaRefRoutes === 'write' && (
            <Button
              disabled={addingToTests || !route}
              endIcon={<ExpandMore />}
              onClick={({ currentTarget }) => setAddToTestsMenuAnchorEl(currentTarget)}
              startIcon={<BugReport />}
              variant="outlined"
            >
              <Trans i18nKey="commons.actions.add_to_tests" />
            </Button>
          )}
          {ride && (
            <>
              <Button
                onClick={() => openRemoveDialog(true)}
                startIcon={<Delete />}
                style={{ borderColor: red[500], color: red[500] }}
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.remove" />
              </Button>
              {ride.publicationStatus && (
                <PublicationStatus
                  onChange={handlePublicationDialogOpen}
                  status={ride.publicationStatus}
                />
              )}
            </>
          )}
        </StyledToolbar>
        <StyledContent>
          {ride ? (
            <>
              <StyledTabs indicatorColor="secondary" value={selectedTab}>
                {tabs.map(({ key, labelKey }) => (
                  <StyledTab
                    aria-controls={`tabpanel-${key}`}
                    component={Link}
                    id={`tab-${key}`}
                    key={key}
                    label={<Trans i18nKey={labelKey} />}
                    style={{ textTransform: 'initial' }}
                    to={key}
                    value={key}
                  />
                ))}
              </StyledTabs>
              {selectedTab === 'data' && (
                <TabPanel value="data">
                  <DataTab canWrite={true} onChange={setRide} onTabChange={selectTab} ride={ride} />
                </TabPanel>
              )}
              {selectedTab === 'route' && (
                <TabPanel value="route">
                  <RouteTab
                    canWrite={true}
                    onChange={setRide}
                    onReturnRouteChange={setReturnRoute}
                    onRouteChange={setRoute}
                    onTabChange={selectTab}
                    onTracesChange={setTraces}
                    returnRoute={returnRoute}
                    ride={ride}
                    route={route}
                    traces={traces}
                  />
                </TabPanel>
              )}
              {selectedTab === 'steps' && (
                <StepsTab
                  canWrite={true}
                  onChange={setRide}
                  onTabChange={selectTab}
                  returnRoute={returnRoute}
                  ride={ride}
                  route={route}
                />
              )}
              <Outlet />
            </>
          ) : (
            <Loading text={<Trans i18nKey="cycling-insights.ride.loading" />} />
          )}
        </StyledContent>
      </Wrapper>
      {ride && (
        <>
          <PublicationStatusDialog
            loading={loading}
            onCancel={handlePublicationDialogClose}
            onConfirm={handlePublicationStatusUpdate}
            open={publicationDialogOpen}
            status={publicationDialogStatus}
          />
          <ConfirmDialog
            dialogTitle="ride-remove-dialog"
            loading={loading}
            onCancel={() => openRemoveDialog(false)}
            onConfirm={handleRemove}
            open={removeDialogOpen}
            title={<Trans i18nKey="cycling-insights.ride.remove_dialog.title" />}
          />
          <AddToTestsMenu
            anchorEl={addToTestsMenuAnchorEl}
            onAddingToTests={handleAddingToTests}
            onClose={() => setAddToTestsMenuAnchorEl(null)}
            ride={ride}
          />
        </>
      )}
    </>
  );
}

const Wrapper = styled.div`
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  position: relative;
`;

const StyledToolbar = styled(Toolbar)`
  h6 {
    margin-right: 8px;
  }

  button {
    margin-left: 8px;
  }
`;

const StyledBackButton = styled(Link)`
  && {
    margin-right: 16px;
  }
`;

const StyledSpacer = styled.div`
  flex-grow: 1;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
`;

const StyledTabs = styled(Tabs)`
  && {
    flex-shrink: 0;
    margin: 0 16px;
    min-height: 36px;

    .MuiTabs-indicator {
      height: 0;
    }
  }
`;

const StyledTab = styled(Tab)<{ component: typeof Link } & LinkProps>`
  && {
    border-radius: 100px;
    min-height: 36px;

    &.Mui-selected {
      background-color: ${({ theme }) => theme.palette.secondary.main};
      color: ${({ theme }) => theme.palette.secondary.contrastText};
    }
  }
`;

export default RideDetail;
