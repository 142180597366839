import { roadTypesLabels, useUnits } from '@geovelo-frontends/commons';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { TColorCollection, TThicknessCollection } from '../../components/color-legend';
import { getFacilityLabelKey } from '../../components/form/facilities';
import { IPeriodFormProps } from '../../components/form/period';
import { ISliderBounds, TSliderRange } from '../../components/form/slider';
import { TSectionFeatureCollection, TSectionProperties } from '../../models/sections';
import usePeriod from '../period';

import useHeatmap from './heatmap';

import { Map } from '!maplibre-gl';

export const colors: TColorCollection = [
  { value: '#50315e' },
  { value: '#643d68' },
  { value: '#956485' },
  { value: '#c1939d' },
  { value: '#edd1ca' },
];

export const colorsComparison: TColorCollection = [
  { value: '#a50026' },
  { value: '#d73027' },
  { value: '#f46d43' },
  { value: '#fdae61' },
  { value: '#fee08b' },
  { value: '#ffffbf' },
  { value: '#d9ef8b' },
  { value: '#a6d96a' },
  { value: '#66bd63' },
  { value: '#1a9850' },
  { value: '#006837' },
];

export const colorsComparisonAllNegatives: TColorCollection = [
  { value: '#a50026' },
  { value: '#d73027' },
  { value: '#f46d43' },
  { value: '#fdae61' },
  { value: '#fee08b' },
  { value: '#ffffbf' },
];

export const colorsComparisonAllPositives: TColorCollection = [
  { value: '#ffffbf' },
  { value: '#d9ef8b' },
  { value: '#a6d96a' },
  { value: '#66bd63' },
  { value: '#1a9850' },
  { value: '#006837' },
];

const widths: TThicknessCollection = [
  { value: 2 },
  { value: 4 },
  { value: 6 },
  { value: 8 },
  { value: 10 },
];

function useRoadsUses(
  map: Map | null | undefined,
  period: IPeriodFormProps,
  onClick?: (section?: TSectionProperties) => void,
): {
  initialized: boolean;
  init: () => void;
  update: (
    { features }: TSectionFeatureCollection,
    props: {
      colors?: TColorCollection;
      comparisonEnabled?: boolean;
      currentRange?: TSliderRange;
      primaryBounds: ISliderBounds;
      secondaryBounds?: ISliderBounds;
    },
  ) => void;
  destroy: () => void;
} {
  const periodRef = useRef(period);
  const { t } = useTranslation();
  const { toSpeed } = useUnits();
  const { getTitle: getPeriod } = usePeriod();

  useEffect(() => {
    periodRef.current = period;
  }, [period]);

  return useHeatmap(
    map,
    {
      sourceId: 'roads-uses',
      layerId: 'roads-uses',
      primaryCriterion: 'frequency',
      secondaryCriterion: 'frequency',
      colors: period.comparisonEnabled ? colorsComparison : colors,
      widths,
    },
    function getTooltipContent({
      averageSpeed,
      frequency,
      currentFrequency,
      prevFrequency,
      lastYearFrequency,
      facility,
      wayName,
      roadType,
    }) {
      if ((!averageSpeed && averageSpeed !== 0) || (!frequency && frequency !== 0)) return '';
      const {
        values: { current, prev },
        comparisonEnabled,
      } = periodRef.current;
      const rounding = 10;
      const roundedSpeed = Math.round(averageSpeed * rounding) / rounding;

      if (comparisonEnabled) {
        if (currentFrequency === undefined || prevFrequency === undefined) return '';

        return (
          `<div style="padding: 8px"><h3>${
            wayName ? wayName : t(roadTypesLabels[roadType || 'unclassified'])
          }</h3>` +
          (facility && facility !== 'none'
            ? `<div style="background-color: #EEF8F4; color: #038B63 ">${t(
                getFacilityLabelKey(facility),
              )}</div>`
            : '') +
          `<div>${t('commons.stats.passages', {
            count: frequency,
          })}</div>` +
          (frequency !== 0 && prevFrequency !== 0 && currentFrequency !== 0
            ? `<div>${
                frequency < 0
                  ? Math.round(((currentFrequency - prevFrequency) / prevFrequency) * 100) + '%'
                  : '+' +
                    Math.round(((currentFrequency - prevFrequency) / prevFrequency) * 100) +
                    '%'
              }</div>`
            : '') +
          `<div>${getPeriod(prev)}: ${t('commons.stats.passages', {
            count: prevFrequency,
          })}<div/>` +
          `<div>${getPeriod(current)}: ${t('commons.stats.passages', {
            count: currentFrequency,
          })}<div/>` +
          `<div style="font-size: 0.75rem;">${t('commons.stats.average_speed', {
            speed: roundedSpeed >= 0 ? '+' + toSpeed(roundedSpeed) : toSpeed(roundedSpeed),
          })}</div></div>`
        );
      } else {
        return (
          `<div style="padding: 8px"><h3>${
            wayName ? wayName : t(roadTypesLabels[roadType || 'unclassified'])
          }</h3>` +
          (facility && facility !== 'none'
            ? `<div style="background-color: #EEF8F4; color: #038B63; padding: 0 6px; border-radius: 8px; width: fit-content;">${t(
                getFacilityLabelKey(facility),
              )}</div>`
            : '') +
          `<div>${t('commons.stats.passages', {
            count: frequency,
          })}</div>` +
          (lastYearFrequency !== undefined
            ? `<div><span style="background-color: ${
                frequency < lastYearFrequency ? '#FFEBEE' : '#EEF8F4'
              }; color: ${
                frequency < lastYearFrequency ? '#A42C49' : '#038B63'
              }; padding: 0 6px; border-radius: 8px; width: fit-content;">${
                frequency < lastYearFrequency
                  ? '- ' + Math.abs(frequency - lastYearFrequency)
                  : '+ ' + Math.abs(frequency - lastYearFrequency)
              }</span> vs ${t('commons.periods.last_year').toLocaleLowerCase()}</div>`
            : '') +
          `<div>${t('commons.stats.average_speed', {
            speed: toSpeed(roundedSpeed),
          })}</div></div>`
        );
      }
    },
    onClick,
  );
}

export default useRoadsUses;
