import {
  CyclabilityZone,
  ExportData,
  Period,
  ShapefileService,
  TFileFormat,
  formats,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { DialogProps, FormControl, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import Dialog from '../../../../components/dialog';
import useAmplitudeTracker from '../../../../hooks/tracker';
import { parkingsTypes } from '../../../../models/parkings-types';

interface IValues {
  exportDataKey: 'csv' | number;
  zoneId: number | 'all';
  format: TFileFormat;
}

type TProps = Omit<DialogProps, 'onClose'> & {
  cyclabilityZones?: CyclabilityZone[];
  onClose: () => void;
  period: Period;
  selectedZone: CyclabilityZone | null;
};

function DownloadDialog({
  period,
  cyclabilityZones,
  selectedZone,
  onClose,
  ...props
}: TProps): JSX.Element {
  const [exportData, setExportData] = useState<ExportData[]>();
  const [downloading, setDownloading] = useState(false);
  const {
    partner: { current: currentPartner, exportData: _exportData },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { transitions } = useTheme();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { values, setFieldValue, setValues, handleChange, handleSubmit } = useFormik<IValues>({
    initialValues: {
      exportDataKey: 0,
      zoneId: 'all',
      format: 'GEOJSON',
    },
    onSubmit,
    enableReinitialize: true,
  });
  const { trackEvent } = useAmplitudeTracker();
  const { downloadCSV } = useFileSaver();

  useEffect(() => {
    setExportData(
      _exportData?.filter(({ type: { code } }) =>
        ['BICYCLE_PARKINGS', 'STATS_BICYCLE_PARKINGS'].includes(code),
      ),
    );
  }, [_exportData]);

  useEffect(() => {
    if (props.open) {
      setValues({
        exportDataKey: exportData?.[0].id || 0,
        zoneId: 'all',
        format: 'GEOJSON',
      });
    } else {
      setTimeout(() => {
        setDownloading(false);
      }, transitions.duration.leavingScreen);
    }
  }, [props.open]);

  useEffect(() => {
    if (
      !exportData?.find(({ id }) => id === values.exportDataKey)?.tablerExport &&
      values.format === 'CSV'
    )
      setFieldValue('format', formats[0].code);
  }, [values.exportDataKey]);

  async function onSubmit({ exportDataKey, zoneId, format }: IValues) {
    setDownloading(true);

    trackEvent('File Downloaded', {
      file: 'Parkings',
      exported_data: exportData?.find(({ id }) => id === values.exportDataKey)?.title,
    });

    if (exportDataKey === 'csv') {
      downloadCSV(
        `stats-${t('commons.navigation.parkings').toLowerCase()}-${period.from.format(
          'YYYY-MM-DD',
        )}.csv`,
        [
          t('cycling-insights.cartographic_data.parkings.download_dialog.zone'),
          ...parkingsTypes.map(({ titleKey }) => `${t(titleKey)} (km)`),
          `${t('commons.stats.total')} (km)`,
        ],
        cyclabilityZones
          ?.filter(({ id }) => zoneId === 'all' || id === zoneId)
          .map(({ name, statsBNSC }) => {
            return [
              name,
              ...parkingsTypes.map(({ key }) => statsBNSC[0].slots[key] || 0),
              statsBNSC[0].slots.all || 0,
            ];
          }) || [],
      );
    } else {
      const data = exportData?.find(({ id }) => id === exportDataKey);
      if (!data) return;

      try {
        const params: { partnerCode?: string; cyclabilityZoneId?: number } = {};

        if (zoneId === 'all') {
          if (selectedZone) {
            params.cyclabilityZoneId = selectedZone.id;
          } else {
            if (!currentPartner) throw new Error('no current partner');
            params.partnerCode = currentPartner.code;
          }
        } else {
          params.cyclabilityZoneId = zoneId;
        }

        await ShapefileService.requestShapefile({
          ...params,
          model: data.model.code,
          type: data.type.code,
          format,
        });

        enqueueSnackbar(
          t('cycling-insights.cartographic_data.parkings.download_dialog.request_sent'),
          {
            action: (
              <Button
                color="inherit"
                onClick={() => navigate(`/${currentPartner?.code}/downloads`)}
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.go_to_downloads" />
              </Button>
            ),
            variant: 'success',
          },
        );
      } catch {
        enqueueSnackbar(
          t('cycling-insights.cartographic_data.parkings.download_dialog.request_error'),
          {
            variant: 'error',
          },
        );
      }
    }

    setDownloading(false);
    onClose();
  }

  return (
    <Dialog
      isForm
      confirmTitle={<Trans i18nKey="commons.actions.download" />}
      dialogTitle="facilities-download-dialog"
      loading={downloading}
      maxWidth="xs"
      onCancel={onClose}
      onConfirm={handleSubmit}
      title={<Trans i18nKey="cycling-insights.cartographic_data.parkings.download_dialog.title" />}
      {...props}
    >
      <FormControl margin="normal">
        <FormLabel component="legend" id="export-data-label">
          <Trans i18nKey="cycling-insights.facilities.cyclability_zones.download_dialog.type" />
        </FormLabel>
        <Select
          labelId="export-data-label"
          name="exportDataKey"
          onChange={handleChange}
          size="small"
          value={values.exportDataKey}
        >
          {exportData?.map(({ id, title }) => (
            <MenuItem key={id} value={id}>
              {title}
            </MenuItem>
          ))}
        </Select>
        {exportData && (
          <Typography variant="caption">
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a href={decoratedHref} key={key} target="blank">
                  {decoratedText}
                </a>
              )}
            >
              {exportData.find(({ id }) => id === values.exportDataKey)?.description}
            </Linkify>
          </Typography>
        )}
      </FormControl>
      <FormControl margin="normal">
        <FormLabel component="legend" id="facilities-download-zone-label">
          <Trans i18nKey="cycling-insights.cartographic_data.parkings.download_dialog.zone" />
        </FormLabel>
        <Select
          labelId="facilities-download-zone-label"
          name="zoneId"
          onChange={handleChange}
          size="small"
          value={values.zoneId}
        >
          <MenuItem value="all">
            <Trans i18nKey="cycling-insights.cartographic_data.parkings.download_dialog.all_zones" />
          </MenuItem>
          {cyclabilityZones
            ?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
            .map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl margin="normal">
        <FormLabel component="legend" id="format-label">
          <Trans i18nKey="cycling-insights.cartographic_data.parkings.download_dialog.format" />
        </FormLabel>
        <Select
          labelId="format-label"
          name="format"
          onChange={handleChange}
          size="small"
          value={values.format}
        >
          {formats
            ?.filter(
              ({ code }) =>
                code !== 'CSV' ||
                exportData?.find(({ id }) => id === values.exportDataKey)?.tablerExport,
            )
            ?.map(({ code, titleKey }) => (
              <MenuItem key={code} value={code}>
                <Trans i18nKey={titleKey} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Dialog>
  );
}

export default DownloadDialog;
