import {
  CyclabilityZoneService,
  Period,
  backendAdministrativeLevels,
  childAdministrativeLevels,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import { TCartographicDataPageContext } from '../context';

function useFacilities({
  context: {
    period,
    facilities: {
      prevCyclabilityZones,
      parents,
      selectedZone,
      zonesMap,
      setCyclabilityZone,
      setPrevCyclabilityZone,
      setCyclabilityZones,
      setPrevCyclabilityZones,
      setParents,
      setZonesMap,
    },
  },
}: {
  context: TCartographicDataPageContext;
}) {
  const [initialized, setInitialized] = useState(false);
  const lastYear = useRef<Period>(period.values.current.clone());
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    lastYear.current = period.values.current.clone();
    lastYear.current.from.add(-1, 'year');
    lastYear.current.to.add(-1, 'year');

    setInitialized(true);

    return () => {
      cancelPromises();
      setPrevCyclabilityZones();
      setZonesMap({});
    };
  }, []);

  useEffect(() => {
    if (!initialized) return;

    setCyclabilityZones();
    setPrevCyclabilityZones();

    if (currentPartner) {
      lastYear.current = period.values.current.clone();
      lastYear.current.from.add(-1, 'year');
      lastYear.current.to.add(-1, 'year');

      if (!selectedZone) getParentZones();
      else getChildrenZones(true);
    }

    return () => {
      cancelPromises();
    };
  }, [initialized, selectedZone, period.values, period.comparisonEnabled]);

  async function getParentZones() {
    if (!currentPartner || currentPartner.administrativeLevel === 'world') return;

    const childAdministrativeLevel = childAdministrativeLevels[currentPartner.administrativeLevel];
    const administrativeLevel = childAdministrativeLevel
      ? childAdministrativeLevel === 'world'
        ? undefined
        : childAdministrativeLevel
      : currentPartner.administrativeLevel;
    if (!administrativeLevel) return;

    try {
      if (!administrativeLevel) return;

      const results = await cancellablePromise(
        Promise.all([
          currentPartner.cyclabilityZoneId
            ? CyclabilityZoneService.getZone(currentPartner.cyclabilityZoneId, {
                period: period.values.current,
              })
            : null,
          currentPartner.cyclabilityZoneId
            ? CyclabilityZoneService.getZone(currentPartner.cyclabilityZoneId, {
                period: period.comparisonEnabled ? period.values.prev : lastYear.current,
              })
            : null,
          CyclabilityZoneService.getZones({
            administrativeLevel: backendAdministrativeLevels[administrativeLevel],
            partnerCode: currentPartner.code,
            considerLivingStreets: true,
            period: period.values.current,
            rowsPerPage: 100,
            query:
              '{ id, code, name, country_code, administrative_level, reference, geo_polygon_simplified, stats }',
          }),
          CyclabilityZoneService.getZones({
            administrativeLevel: backendAdministrativeLevels[administrativeLevel],
            partnerCode: currentPartner.code,
            considerLivingStreets: true,
            period: period.comparisonEnabled ? period.values.prev : lastYear.current,
            rowsPerPage: 100,
            query: '{ id, code, name, administrative_level, stats }',
          }),
        ]),
      );

      setCyclabilityZone(results[0]);
      setPrevCyclabilityZone(results[1]);

      results[2].zones.forEach((zone) => {
        zonesMap[zone.id] = zone;
      });

      setCyclabilityZones(results[2].zones);
      setPrevCyclabilityZones(results[3].zones);
      setZonesMap({ ...zonesMap });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.facilities.cyclability_zones.form.server_error'));
      }
    }
  }

  async function getChildrenZones(forceReload = false) {
    if (!selectedZone) return;

    if (selectedZone.administrativeLevel === 'city') {
      if (forceReload) {
        try {
          const [zone, prevZone] = await cancellablePromise(
            Promise.all([
              CyclabilityZoneService.getZone(selectedZone.id, {
                period: period.values.current,
                query: '{ id, code, name, administrative_level, stats }',
              }),
              CyclabilityZoneService.getZone(selectedZone.id, {
                period: period.comparisonEnabled ? period.values.prev : lastYear.current,
                query: '{ id, code, name, administrative_level, stats }',
              }),
            ]),
          );

          setPrevCyclabilityZones([prevZone]);

          setCyclabilityZones([zone]);
          setZonesMap({ [zone.id]: zone });
        } catch {
          enqueueSnackbar(t('cycling-insights.facilities.cyclability_zones.form.server_error'));
        }
      } else {
        setCyclabilityZones([selectedZone]);
        setZonesMap({ [selectedZone.id]: selectedZone });

        setPrevCyclabilityZones(
          prevCyclabilityZones?.filter(({ id }) => id === selectedZone.id) || [],
        );
      }

      return;
    }

    const childAdministrativeLevel = childAdministrativeLevels[selectedZone.administrativeLevel];
    if (!childAdministrativeLevel || childAdministrativeLevel === 'world') return;

    try {
      const [zones, prevZones] = await cancellablePromise(
        Promise.all([
          CyclabilityZoneService.getChildrenZones(selectedZone.id, {
            administrativeLevel: backendAdministrativeLevels[childAdministrativeLevel],
            period: period.values.current,
            query: '{ id, code, name, administrative_level, geo_polygon_simplified, stats }',
          }),
          CyclabilityZoneService.getChildrenZones(selectedZone.id, {
            administrativeLevel: backendAdministrativeLevels[childAdministrativeLevel],
            period: period.comparisonEnabled ? period.values.prev : lastYear.current,
            query: '{ id, code, name, administrative_level, stats }',
          }),
        ]),
      );

      setPrevCyclabilityZones(prevZones);

      zones.forEach((zone) => {
        if (zonesMap) zonesMap[zone.id] = zone;
      });

      setCyclabilityZones(zones);
      setZonesMap({ ...zonesMap });
      setParents(
        zones.reduce(
          (res, { id }) => {
            res[id] = [...(parents[selectedZone.id] || []), selectedZone];

            return res;
          },
          { ...parents },
        ),
      );
    } catch {
      enqueueSnackbar(t('cycling-insights.facilities.cyclability_zones.form.server_error'));
    }
  }
}

export default useFacilities;
