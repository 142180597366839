import {
  Area,
  Challenge,
  CondensedRideTrace,
  CyclabilityZone,
  DashboardPages,
  Event,
  ExportData,
  ExportDataProjection,
  Geogroup,
  ParkingTypes,
  Partner,
  PartnerContract,
  PartnerContractTemplate,
  PoiCategory,
  ReportType,
  RideTheme,
  TBaseLayer,
  TPoiCategoryCode,
  TSectionFeatureCollection,
  User,
} from '@geovelo-frontends/commons';
import { MutableRefObject, createContext, createRef } from 'react';

import { TAccidentType } from '../pages/cartographic-data/models/accidentology';
import { IDashboardTab } from '../pages/dashboard-page';

import { Map } from '!maplibre-gl';

export interface _IAppContext {
  initializing: MutableRefObject<boolean | null>;
  highlightedEvent?: Event | null;
  highlightedEventChallenge?: Challenge | null;
}

export interface INavigationContext {
  currentPage?: DashboardPages;
  currentTab?: IDashboardTab;
}

export interface IUserContext {
  current?: User | null;
}

export interface IPartnerContext {
  all?: Partner[];
  activeContracts?: PartnerContract[];
  contractTemplates?: PartnerContractTemplate[];
  contracts?: PartnerContract[];
  createDialogOpen: boolean;
  current?: Partner | null;
  currentArea?: Area | null;
  currentGeogroup?: Geogroup | null;
  cyclabilityZones?: CyclabilityZone[] | null;
  editDialogOpen: boolean;
  exportData?: ExportData[];
  exportDataProjections?: ExportDataProjection[];
  lastVisited?: Partner | null;
  list?: Partner[];
  partnerToEdit: Partner | null;
  sections?: TSectionFeatureCollection;
}

export interface IReportContext {
  types?: ReportType[];
}

export interface ICommunityContext {
  geogroups?: Geogroup[];
}

export interface IMapContext {
  accidentZonesShowed: boolean;
  incidentsShowed: {
    accidents: { [key in TAccidentType]: boolean };
    blackSpots: boolean;
    reports: boolean;
    suddenBrakings: boolean;
  };
  averageSpeedsShowed: boolean;
  baseLayer: TBaseLayer;
  countersShowed: boolean;
  current?: Map | null;
  facilitiesShowed: boolean;
  originDestinationExternalFlowsShowed: boolean;
  originDestinationFlowsShowed: boolean;
  originDestinationZonesShowed: boolean;
  parkingRequestsShowed: boolean;
  parkingsToggleEnabled: boolean;
  parkingsShowed: { [type in ParkingTypes]: boolean };
  potholesShowed: boolean;
  stoppingAreasShowed: boolean;
  zoom?: number;
}

export interface IReportContext {
  types?: ReportType[];
}

export interface IRideContext {
  themes?: RideTheme[];
  traces?: CondensedRideTrace[];
}

export interface IPoiContext {
  categories?: PoiCategory[];
  selectedCategories: { [key in TPoiCategoryCode]?: boolean };
}

interface IAppActions {
  enableParkingsToggle: (enabled: boolean) => void;
  getGeogroups: () => void;
  getPartnerCyclabilityZones: (partner: Partner) => void;
  getPartnerSections: (partner?: Partner) => void;
  getReportTypes: () => void;
  openCreatePartnerDialog: (open: boolean) => void;
  openEditPartnerDialog: (open: boolean) => void;
  selectPoiCategories: (categories: { [key in TPoiCategoryCode]?: boolean }) => void;
  setBaseLayer: (layer: TBaseLayer) => void;
  setCurrentMap: (map?: Map | null) => void;
  setCurrentPage: (page?: DashboardPages) => void;
  setCurrentPartner: (partner?: Partner) => void;
  setCurrentPartnerActiveContracts: (contracts?: PartnerContract[]) => void;
  setCurrentPartnerContracts: (contracts?: PartnerContract[]) => void;
  setCurrentTab: (tab?: IDashboardTab) => void;
  setCurrentUser: (user?: User | null) => void;
  setHighlightedEvent: (event: Event) => void;
  setHighlightedEventChallenge: (challenge?: Challenge | null) => void;
  setLastVisitedPartner: (partner?: Partner) => void;
  setPartnerContractTemplates: (templates?: PartnerContractTemplate[]) => void;
  setPartnerToEdit: (partner: Partner | null) => void;
  setPoiCategories: (categories?: PoiCategory[]) => void;
  setRideThemes: (themes?: RideTheme[]) => void;
  setRideTraces: (traces?: CondensedRideTrace[]) => void;
  setUserPartners: (partners?: Partner[]) => void;
  toggleAccidentZones: (enabled: boolean) => void;
  toggleAverageSpeeds: (enabled: boolean) => void;
  toggleCounters: (showed: boolean) => void;
  toggleFacilities: (enabled: boolean) => void;
  toggleIncidents: (enabled: {
    accidents: { [key in TAccidentType]: boolean };
    blackSpots: boolean;
    reports: boolean;
    suddenBrakings: boolean;
  }) => void;
  toggleOriginDestinationFlows: (showed: boolean) => void;
  toggleOriginDestinationExternalFlows: (showed: boolean) => void;
  toggleOriginDestinationZones: (showed: boolean) => void;
  toggleParkingRequests: (showed: boolean) => void;
  toggleParkings: (showed: { [key in ParkingTypes]: boolean }) => void;
  togglePotholes: (showed: boolean) => void;
  toggleStoppingAreas: (showed: boolean) => void;
}

interface IAppContext {
  app: _IAppContext;
  navigation: INavigationContext;
  user: IUserContext;
  partner: IPartnerContext;
  poi: IPoiContext;
  report: IReportContext;
  community: ICommunityContext;
  map: IMapContext;
  ride: IRideContext;
  actions: IAppActions;
}

export const AppContext = createContext<IAppContext>({
  app: {
    initializing: createRef(),
  },
  navigation: {},
  user: {},
  partner: { createDialogOpen: false, editDialogOpen: false, partnerToEdit: null },
  poi: { selectedCategories: {} },
  report: {},
  community: {},
  map: {
    baseLayer: 'geovelo',
    facilitiesShowed: false,
    countersShowed: false,
    parkingsToggleEnabled: false,
    parkingsShowed: {
      arch: true,
      free: true,
      private: true,
      rack: true,
      secure: true,
      sheltered: true,
      locked: true,
    },
    parkingRequestsShowed: false,
    potholesShowed: false,
    stoppingAreasShowed: true,
    averageSpeedsShowed: true,
    accidentZonesShowed: true,
    incidentsShowed: {
      accidents: { deadly: true, hospitalized: true, injured: true },
      blackSpots: true,
      reports: true,
      suddenBrakings: true,
    },
    originDestinationZonesShowed: true,
    originDestinationFlowsShowed: true,
    originDestinationExternalFlowsShowed: true,
  },
  ride: {},
  actions: {
    setHighlightedEvent: () => undefined,
    setHighlightedEventChallenge: () => undefined,
    setCurrentPage: () => undefined,
    setCurrentTab: () => undefined,
    setCurrentUser: () => undefined,
    setPartnerContractTemplates: () => undefined,
    setUserPartners: () => undefined,
    setCurrentPartner: () => undefined,
    setLastVisitedPartner: () => undefined,
    setCurrentPartnerContracts: () => undefined,
    setCurrentPartnerActiveContracts: () => undefined,
    setRideThemes: () => undefined,
    setRideTraces: () => undefined,
    openCreatePartnerDialog: () => undefined,
    openEditPartnerDialog: () => undefined,
    setPartnerToEdit: () => undefined,
    setCurrentMap: () => undefined,
    setBaseLayer: () => undefined,
    toggleFacilities: () => undefined,
    toggleCounters: () => undefined,
    enableParkingsToggle: () => undefined,
    toggleParkings: () => undefined,
    toggleParkingRequests: () => undefined,
    togglePotholes: () => undefined,
    toggleStoppingAreas: () => undefined,
    toggleAverageSpeeds: () => undefined,
    toggleAccidentZones: () => undefined,
    toggleIncidents: () => undefined,
    toggleOriginDestinationZones: () => undefined,
    toggleOriginDestinationFlows: () => undefined,
    toggleOriginDestinationExternalFlows: () => undefined,
    getReportTypes: () => undefined,
    getGeogroups: () => undefined,
    getPartnerSections: () => undefined,
    getPartnerCyclabilityZones: () => undefined,
    selectPoiCategories: () => undefined,
    setPoiCategories: () => undefined,
  },
});
