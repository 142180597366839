import { useFileSaver } from '@geovelo-frontends/commons';
import { FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import useAmplitudeTracker from '../../../../hooks/tracker';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { TCartographicDataPageContext } from '../../context';

import CyclabilityForm from './form';

function CyclabilityTab(): JSX.Element {
  const context = useOutletContext<TCartographicDataPageContext & TOutletContext>();
  const {
    header: { setActions },
    cyclability: { data, selectedCyclabilities },
  } = context;
  const { t } = useTranslation();
  const { downloadBlob } = useFileSaver();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    setActions(
      <Tooltip title={<Trans i18nKey="cycling-insights.facilities.actions.download" />}>
        <span>
          <IconButton
            color="primary"
            disabled={!data}
            onClick={() => {
              handleDownload();
            }}
            size="small"
          >
            <FileDownloadOutlined />
          </IconButton>
        </span>
      </Tooltip>,
    );
  }, [data]);

  function handleDownload() {
    if (!data) return;

    trackEvent('File Downloaded', { file: 'Cyclability' });

    downloadBlob(
      `stats-${t('cycling-insights.bicycle_observatory.navigation.cyclability')
        .replace(/ /g, '_')
        .toLowerCase()}-${moment().format('YYYY-MM-DD')}.geojson`,
      new Blob(
        [
          JSON.stringify(
            {
              ...data,
              features: data.features.filter(
                ({ properties: { cyclability } }) =>
                  !selectedCyclabilities ||
                  (cyclability && selectedCyclabilities.includes(cyclability)),
              ),
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      ),
    );
  }

  return <PageContentLayout leftPanel={<CyclabilityForm {...context} />} />;
}

export default CyclabilityTab;
