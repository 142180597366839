import {
  Button,
  Period,
  TDayPeriod,
  TPeriod,
  TTimePeriod,
  currentMonth,
  currentWeek,
  currentYear,
} from '@geovelo-frontends/commons';
import { ArrowDropDown, ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Button as MuiButton,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import usePeriod from '../../hooks/period';
import useQueryParams from '../../hooks/query-params';
import useAmplitudeTracker from '../../hooks/tracker';

import DatePickerDialog from './date-picker-dialog';

export interface IPeriodFormValues {
  current: Period;
  prev: Period;
  timePeriod?: TTimePeriod;
  dayPeriod?: TDayPeriod;
}

export interface IPeriodFormProps {
  comparisonEnabled?: boolean;
  customPeriodTypes?: {
    defaultPeriods: IPeriodFormValues;
    enabledTypes: TPeriod[];
  };
  disableComparison?: boolean;
  disablePadding?: boolean;
  disablePeriodTypeChange?: boolean;
  enableCustomComparison?: boolean;
  enableComparison?: (enabled: boolean) => void;
  enableDayPeriods?: boolean;
  enableTimePeriods?: boolean;
  forceComparison?: boolean;
  maxDate?: Date;
  maxPeriod?: Period;
  minPeriod?: Period;
  setValues: (values: IPeriodFormValues) => void;
  values: IPeriodFormValues;
}

const periodTypes: Array<{ key: TPeriod; titleKey: string }> = [
  {
    key: 'week',
    titleKey: 'commons.periods.week',
  },
  {
    key: 'month',
    titleKey: 'commons.periods.month',
  },
  {
    key: 'year',
    titleKey: 'commons.periods.year',
  },
  { key: 'custom', titleKey: 'commons.periods.custom' },
];

export const timePeriods: Array<{
  key: TTimePeriod;
  titleKey: string;
  hourMin?: number;
  hourMax?: number;
}> = [
  { key: 'am_peak', titleKey: 'commons.periods.am_peak', hourMin: 7, hourMax: 10 },
  { key: 'pm_peak', titleKey: 'commons.periods.pm_peak', hourMin: 17, hourMax: 20 },
  { key: 'off_peak', titleKey: 'commons.periods.off_peak' },
  { key: 'all_day', titleKey: 'commons.all' },
];

export const dayPeriods: Array<{ key: TDayPeriod; titleKey: string }> = [
  { key: 'week', titleKey: 'commons.periods.week' },
  { key: 'weekend', titleKey: 'commons.periods.weekend' },
  { key: 'all', titleKey: 'commons.all' },
];

const customComparisonPeriods: {
  [key in Extract<TPeriod, 'week' | 'month' | 'year' | 'custom'>]: {
    key: string;
    titleKey: string;
  }[];
} = {
  week: [
    { key: 'last_week', titleKey: 'commons.periods.last_week' },
    { key: 'last_month', titleKey: 'commons.periods.last_month' },
    { key: 'last_year', titleKey: 'commons.periods.last_year' },
  ],
  month: [
    { key: 'last_month', titleKey: 'commons.periods.last_month' },
    { key: 'last_year', titleKey: 'commons.periods.last_year' },
  ],
  year: [{ key: 'last_year', titleKey: 'commons.periods.last_year' }],
  custom: [{ key: 'last_year', titleKey: 'commons.periods.last_year' }],
};

function PeriodForm({
  customPeriodTypes,
  disableComparison,
  disablePadding,
  disablePeriodTypeChange,
  enableCustomComparison,
  forceComparison,
  minPeriod,
  maxPeriod,
  maxDate,
  values,
  comparisonEnabled,
  enableTimePeriods,
  enableDayPeriods,
  setValues,
  enableComparison,
}: IPeriodFormProps): JSX.Element {
  const { current: currentPeriod, prev: prevPeriod, timePeriod, dayPeriod } = values;
  const [initialized, setInitialized] = useState(false);
  const [customPeriodDatePickerOpen, openCustomPeriodDatePicker] = useState(false);
  const [comparisonCheckDisabled, disableComparisonCheck] = useState<boolean>(false);
  const [customComparisonPeriod, setCustomComparisonPeriod] = useState<string>(
    customComparisonPeriods[
      currentPeriod.type as Extract<TPeriod, 'week' | 'month' | 'year' | 'custom'>
    ][0].key,
  );
  const { t } = useTranslation();
  const { update } = useQueryParams();
  const { trackEvent: _trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    setCustomComparisonPeriod(
      customComparisonPeriods[
        currentPeriod.type as Extract<TPeriod, 'week' | 'month' | 'year' | 'custom'>
      ][0].key,
    );
  }, [currentPeriod.type]);

  useEffect(() => {
    if (customPeriodTypes && !customPeriodTypes.enabledTypes.includes(currentPeriod.type)) {
      setValues({
        ...values,
        current: customPeriodTypes.defaultPeriods.current.clone(),
        prev: customPeriodTypes.defaultPeriods.prev.clone(),
      });
    } else if (minPeriod && currentPeriod.from.isBefore(minPeriod.from)) {
      setValues({
        ...values,
        current: minPeriod.clone(),
        prev: minPeriod.getPrevPeriod(),
      });
    } else if (maxPeriod && currentPeriod.from.isAfter(maxPeriod.from)) {
      setValues({
        ...values,
        current: maxPeriod.clone(),
        prev: maxPeriod.getPrevPeriod(),
      });
    }
  }, [minPeriod, maxPeriod, customPeriodTypes]);

  useEffect(() => {
    if (initialized) {
      const params = { currentPeriod, timePeriod, dayPeriod };

      if (forceComparison || comparisonEnabled)
        update({ ...params, prevPeriod, periodsComparisonEnabled: true });
      else update({ ...params, prevPeriod: null, periodsComparisonEnabled: false });
    }
  }, [initialized, currentPeriod, prevPeriod, comparisonEnabled, timePeriod, dayPeriod]);

  useEffect(() => {
    if (minPeriod?.from && currentPeriod.getPrevPeriod().from.isBefore(minPeriod.from)) {
      disableComparisonCheck(true);
      enableComparison?.(false);
    } else disableComparisonCheck(false);
  }, [currentPeriod]);

  function trackEvent() {
    _trackEvent('Filter Selected', { filter: 'Period' });
  }

  function handleTypeChange({ target: { value } }: SelectChangeEvent<TPeriod>) {
    switch (value) {
      case 'week':
        setValues({ ...values, current: currentWeek, prev: currentWeek.getPrevPeriod() });
        break;
      case 'month':
        setValues({ ...values, current: currentMonth, prev: currentMonth.getPrevPeriod() });
        break;
      case 'year':
        setValues({ ...values, current: currentYear, prev: currentYear.getPrevPeriod() });
        break;
      case 'custom':
        openCustomPeriodDatePicker(true);
        break;
      default:
        break;
    }

    trackEvent();
  }

  function handleCustomComparisonPeriodChange({ target: { value } }: SelectChangeEvent<string>) {
    setCustomComparisonPeriod(value);
    switch (currentPeriod.type) {
      case 'week':
        if (value === 'last_week') setValues({ ...values, prev: currentPeriod.getPrevPeriod() });
        else if (value === 'last_month')
          setValues({
            ...values,
            prev: new Period(
              'week',
              currentPeriod.clone().from.add(-4, 'week'),
              currentPeriod.clone().to.add(-4, 'week'),
            ),
          });
        else if (value === 'last_year')
          setValues({
            ...values,
            prev: new Period(
              'week',
              moment().week(currentPeriod.from.week()).startOf('isoWeek'),
              moment().week(currentPeriod.from.week()).endOf('isoWeek'),
            ),
          });
        else setValues({ ...values, prev: currentPeriod.getPrevPeriod() });
        break;
      case 'month':
        if (value === 'last_month') setValues({ ...values, prev: currentPeriod.getPrevPeriod() });
        else if (value === 'last_year')
          setValues({
            ...values,
            prev: new Period(
              'month',
              currentPeriod.clone().from.add(-1, 'year'),
              currentPeriod.clone().to.add(-1, 'year').endOf('month'),
            ),
          });
        else setValues({ ...values, prev: currentPeriod.getPrevPeriod() });
        break;
      case 'year':
        if (value === 'last_year') setValues({ ...values, prev: currentPeriod.getPrevPeriod() });
        else setValues({ ...values, prev: currentPeriod.getPrevPeriod() });
        break;
      case 'custom':
        if (value === 'last_year')
          setValues({
            ...values,
            prev: new Period(
              'custom',
              currentPeriod.clone().from.add(-1, 'year'),
              currentPeriod.clone().to.add(-1, 'year'),
            ),
          });
        else setValues({ ...values, prev: currentPeriod.getPrevPeriod() });
        break;
      default:
        break;
    }

    trackEvent();
  }

  function handleCustomPeriodClose(period?: Period) {
    if (period) {
      setValues({ ...values, current: period, prev: period.getPrevPeriod() });
      trackEvent();
    }

    openCustomPeriodDatePicker(false);
  }

  function handleCurrentPeriodPrev() {
    const newPeriod = currentPeriod.getPrevPeriod();

    setValues({
      ...values,
      current: newPeriod,
      prev: newPeriod.from.isSameOrAfter(prevPeriod.from) ? newPeriod.getPrevPeriod() : prevPeriod,
    });
    trackEvent();
  }

  function handleCurrentPeriodNext() {
    const newPeriod = currentPeriod.getNextPeriod();

    if (minPeriod?.from && currentPeriod.getPrevPeriod().from.isBefore(minPeriod.from))
      setValues({ ...values, current: newPeriod, prev: newPeriod.getPrevPeriod() });
    else if (disableComparison || !comparisonEnabled) {
      setValues({ ...values, current: newPeriod, prev: newPeriod.getPrevPeriod() });
    } else setValues({ ...values, current: newPeriod });
    trackEvent();
  }

  function handlePrevPeriodPrev() {
    const newPeriod = prevPeriod.getPrevPeriod();

    setValues({ ...values, prev: newPeriod });
    trackEvent();
  }

  function handlePrevPeriodNext() {
    const newPeriod = prevPeriod.getNextPeriod();

    setValues({ ...values, prev: newPeriod });
    trackEvent();
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        flexShrink={0}
        gap={3}
        padding={disablePadding ? 0 : 2}
        width="100%"
      >
        {!disablePadding && (
          <Typography color="textSecondary" variant="caption">
            {t('commons.form.filtering_by_period')}
          </Typography>
        )}
        <Box display="flex" flexDirection="column" gap={1}>
          <Box alignItems="center" display="flex" gap={2} justifyContent="space-between">
            {!disablePeriodTypeChange ? (
              <FormControl size="small">
                <Select onChange={handleTypeChange} value={currentPeriod.type}>
                  {periodTypes.map(
                    ({ key, titleKey }) =>
                      (!customPeriodTypes || customPeriodTypes.enabledTypes.includes(key)) && (
                        <MenuItem key={key} value={key}>
                          {t(titleKey)}
                        </MenuItem>
                      ),
                  )}
                </Select>
              </FormControl>
            ) : (
              <Box />
            )}
            <PeriodSelector
              handleCustomPeriodUpdate={() => openCustomPeriodDatePicker(true)}
              handleNext={handleCurrentPeriodNext}
              handlePrev={handleCurrentPeriodPrev}
              max={maxPeriod?.to}
              min={minPeriod?.from}
              period={currentPeriod}
            />
          </Box>
          {!disableComparison && (
            <Box alignItems="start" display="flex" gap={2} justifyContent="space-between">
              <FormControlLabel
                disableTypography
                control={
                  <Checkbox
                    checked={comparisonEnabled}
                    onChange={(_, enabled) => {
                      enableComparison?.(enabled);
                      trackEvent();
                    }}
                    size="small"
                  />
                }
                disabled={forceComparison || comparisonCheckDisabled}
                label={<Typography variant="body2">{t('commons.form.compare_to')}</Typography>}
              />
              {enableCustomComparison ? (
                <Box alignItems="end" display="flex" flexDirection="column" gap={1}>
                  <FormControl size="small">
                    <Select
                      disabled={!comparisonEnabled}
                      onChange={handleCustomComparisonPeriodChange}
                      style={{ width: '176px' }}
                      value={customComparisonPeriod}
                    >
                      {customComparisonPeriods[
                        currentPeriod.type as Extract<TPeriod, 'week' | 'month' | 'year' | 'custom'>
                      ].map(({ key, titleKey }) => (
                        <MenuItem key={key} value={key}>
                          {t(titleKey)}
                        </MenuItem>
                      ))}
                      <MenuItem value="custom">{t('commons.periods.custom')}</MenuItem>
                    </Select>
                  </FormControl>
                  {customComparisonPeriod === 'custom' && (
                    <PeriodSelector
                      disabled={!comparisonEnabled}
                      handleNext={handlePrevPeriodNext}
                      handlePrev={handlePrevPeriodPrev}
                      max={currentPeriod.from}
                      period={prevPeriod}
                    />
                  )}
                </Box>
              ) : (
                <PeriodSelector
                  disabled={!comparisonEnabled}
                  handleNext={handlePrevPeriodNext}
                  handlePrev={handlePrevPeriodPrev}
                  max={currentPeriod.from}
                  period={prevPeriod}
                />
              )}
            </Box>
          )}
        </Box>
        {enableDayPeriods && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={600} variant="subtitle2">
              {t('commons.periods.days_other')}
            </Typography>
            <Box display="flex" gap={1}>
              {dayPeriods.map(({ key, titleKey }) => {
                const active = key === dayPeriod;

                return (
                  <MuiButton
                    disableElevation
                    disabled={active}
                    key={key}
                    onClick={() => {
                      if (!active) {
                        setValues({ ...values, dayPeriod: key });
                        trackEvent();
                      }
                    }}
                    sx={{
                      backgroundColor: active ? '#E5F0FF' : 'white',
                      border: `1px solid ${active ? '#3E7BDF' : '#C7CEDC'} !important`,
                      borderRadius: '8px',
                      color: active ? '#3E7BDF !important' : 'inherit',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingX: 3,
                      height: '32px',
                      textTransform: 'none',
                    }}
                    variant="outlined"
                  >
                    {t(titleKey)}
                  </MuiButton>
                );
              })}
            </Box>
          </Box>
        )}
        {enableTimePeriods && (
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography fontWeight={600} variant="subtitle2">
              {t('commons.periods.slots')}
            </Typography>
            <Box display="flex" gap={1}>
              {timePeriods.map(({ key, titleKey, hourMin, hourMax }) => {
                const active = key === timePeriod;

                return (
                  <MuiButton
                    disableElevation
                    disabled={active}
                    key={key}
                    onClick={() => {
                      if (!active) {
                        setValues({ ...values, timePeriod: key });
                        trackEvent();
                      }
                    }}
                    sx={{
                      backgroundColor: active ? '#E5F0FF' : 'white',
                      border: `1px solid ${active ? '#3E7BDF' : '#C7CEDC'} !important`,
                      borderRadius: '8px',
                      color: active ? '#3E7BDF !important' : 'inherit',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingX: 3,
                      height: '48px',
                      textTransform: 'none',
                    }}
                    variant="outlined"
                  >
                    <Typography noWrap align="center" variant="caption">
                      {t(titleKey)}
                    </Typography>
                    {hourMin !== undefined && hourMax !== undefined && (
                      <Typography
                        noWrap
                        align="center"
                        color={active ? '#3E7BDF' : 'textSecondary'}
                        variant="caption"
                      >
                        {hourMin}h - {hourMax}h
                      </Typography>
                    )}
                  </MuiButton>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
      <DatePickerDialog
        maxDate={maxDate || moment().endOf('year').toDate()}
        minDate={new Date(2018, 0)}
        onClose={handleCustomPeriodClose}
        open={customPeriodDatePickerOpen}
        period={currentPeriod}
      />
    </>
  );
}

function PeriodSelector({
  disabled,
  period,
  min,
  max,
  handlePrev,
  handleNext,
  handleCustomPeriodUpdate,
}: {
  disabled?: boolean;
  handleCustomPeriodUpdate?: () => void;
  handleNext: () => void;
  handlePrev: () => void;
  min?: Moment;
  max?: Moment;
  period: Period;
}): JSX.Element {
  const { getTitle } = usePeriod();
  const { t } = useTranslation();

  return (
    <Box alignItems="center" display="flex" flexShrink={0} gap={1}>
      {period.type !== 'custom' || !handleCustomPeriodUpdate ? (
        <>
          <Tooltip
            placement="bottom"
            title={t('commons.periods.actions.prev', { context: period.type })}
          >
            <span>
              <IconButton
                className="prev"
                disabled={disabled || (min && period.getPrevPeriod().from.isBefore(min))}
                onClick={handlePrev}
                size="small"
              >
                <ChevronLeft />
              </IconButton>
            </span>
          </Tooltip>
          <Box
            width={
              period.type === 'custom' || period.type === 'week'
                ? 140
                : period.type === 'month'
                  ? 120
                  : 40
            }
          >
            <Typography
              align="center"
              color={disabled ? 'rgba(0, 0, 0, 0.26)' : 'inherit'}
              variant="body2"
            >
              {getTitle(period)}
            </Typography>
          </Box>
          <Tooltip
            placement="bottom"
            title={t('commons.periods.actions.next', { context: period.type })}
          >
            <span>
              <IconButton
                className="next"
                disabled={
                  disabled ||
                  !period.hasNext ||
                  (max && period.to.isSameOrAfter(max)) ||
                  (max && period.getNextPeriod().from.isSameOrAfter(max))
                }
                onClick={handleNext}
                size="small"
              >
                <ChevronRight />
              </IconButton>
            </span>
          </Tooltip>
        </>
      ) : (
        <Tooltip placement="bottom" title={t('commons.periods.actions.select')}>
          <span>
            <Button
              disabled={disabled}
              endIcon={<ArrowDropDown />}
              onClick={handleCustomPeriodUpdate}
              size="small"
              variant="outlined"
            >
              <Typography noWrap variant="caption">
                {getTitle(period)}
              </Typography>
            </Button>
          </span>
        </Tooltip>
      )}
    </Box>
  );
}

export default PeriodForm;
