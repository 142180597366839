import { useUnits } from '@geovelo-frontends/commons';
import { Box, DialogProps, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import Dialog from '../../../../components/dialog';
import Ranking from '../../../../components/ranking';
import { TCompany } from '../../hooks/home-work-journeys';

export const sizeRanges: Array<{ labelKey: string; min?: number; max?: number }> = [
  { labelKey: 'commons.communities.form.size_ranges.less_than_company', max: 50 },
  { labelKey: 'commons.communities.form.size_ranges.between_company', min: 51, max: 100 },
  { labelKey: 'commons.communities.form.size_ranges.between_company', min: 101, max: 500 },
  { labelKey: 'commons.communities.form.size_ranges.more_than_company', min: 501 },
];

function CompaniesDialog({
  companiesInArea,
  open,
  onClose,
  ...props
}: Omit<DialogProps, 'onClose' | 'title'> & {
  companiesInArea: TCompany[] | undefined;
  dialogTitle: string;
  onClose: () => void;
}): JSX.Element {
  const [sizeRangeIndex, setSizeRangeIndex] = useState(-1);
  const [items, setItems] = useState<TCompany[]>();
  const { t } = useTranslation();
  const { formatNumber } = useUnits();
  const {
    map: { current: currentMap },
  } = useContext(AppContext);

  useEffect(() => {
    const { min, max } =
      sizeRangeIndex > -1 ? sizeRanges[sizeRangeIndex] : { min: undefined, max: undefined };
    setItems(
      companiesInArea
        ?.filter(
          ({ membersCount }) =>
            (min === undefined || min <= membersCount) &&
            (max === undefined || max >= membersCount),
        )
        .sort((a, b) => b.homeWorkTracesCount - a.homeWorkTracesCount),
    );
  }, [companiesInArea, sizeRangeIndex]);

  return (
    <Dialog
      confirmHidden
      fullHeight
      cancelTitle={t('commons.actions.close')}
      maxWidth="sm"
      onCancel={onClose}
      open={open}
      scroll="paper"
      title={
        <Box
          alignItems="center"
          component="span"
          display="flex"
          gap={2}
          justifyContent="space-between"
        >
          <span>{t('cycling-insights.community.home_work_journeys.companies_list.title')}</span>
          <FormControl margin="none" size="small">
            <Select
              onChange={({ target: { value } }) => {
                setSizeRangeIndex(typeof value === 'string' ? parseInt(value) : value);
              }}
              renderValue={(selected) => {
                if (selected === -1)
                  return (
                    <Typography color="#b6b6b6" component="span" fontSize="inherit">
                      {t('cycling-insights.community.home_work_journeys.companies_list.all_sizes')}
                    </Typography>
                  );

                const { labelKey, min, max } = sizeRanges[selected];
                return t(labelKey, { min, max });
              }}
              style={{ width: 300 }}
              value={sizeRangeIndex}
            >
              <MenuItem value={-1}>
                {t('cycling-insights.community.home_work_journeys.companies_list.all_sizes')}
              </MenuItem>
              {sizeRanges.map(({ labelKey, min, max }, index) => (
                <MenuItem key={index} value={index}>
                  {t(labelKey, { min, max })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      }
      {...props}
    >
      <Box>
        <Ranking
          disableProgression
          enableSubtitles
          data={items?.map(({ homeWorkTracesCount: value, ...otherProps }) => ({
            ...otherProps,
            value,
          }))}
          formatData={({ value }) => (
            <Typography minWidth={80} textAlign="end" variant="body2">
              {t(
                'cycling-insights.community.home_work_journeys.companies_list.home_work_journeys',
                {
                  count: value,
                  value: formatNumber(value ?? 0),
                },
              )}
            </Typography>
          )}
          onClick={(id) => {
            const coordinates = items?.find(({ id: companyId }) => companyId === id)?.location
              .coordinates;
            if (coordinates) {
              const [lng, lat] = coordinates;
              currentMap?.flyTo({
                center: { lat, lng },
                zoom: 16,
              });
              onClose();
            }
          }}
        />
      </Box>
    </Dialog>
  );
}

export default CompaniesDialog;
