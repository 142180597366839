import { GeogroupMember, GeogroupService, useUnits } from '@geovelo-frontends/commons';
import {
  ArrowBackIosNew,
  DeleteOutline,
  EditOutlined,
  FiberNew,
  LockOutlined,
  ShareOutlined,
} from '@mui/icons-material';
import { Box, ButtonBase, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import InvitationLinkDialog from '../../../../components/invitation-link-dialog';
import Table, { TRow } from '../../../../components/table';
import usePaginatedTable from '../../../../hooks/table/paginated';
import useSelectableTable from '../../../../hooks/table/selectable';
import useSortableTable from '../../../../hooks/table/sortable';
import useAmplitudeTracker from '../../../../hooks/tracker';

import RemoveDialog from './remove-dialog';

type TKey = 'username' | 'joinDate' | 'distance';

const keys: TKey[] = ['username', 'joinDate', 'distance'];

function MembersData(): JSX.Element {
  const [invitationLinkDialogOpen, openInvitationLinkDialog] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { trackEvent } = useAmplitudeTracker();
  const [editable, setEditable] = useState(false);
  const [members, setMembers] = useState<GeogroupMember[]>();
  const [membersCount, setMembersCount] = useState<number>();
  const [rows, setRows] = useState<TRow<number, TKey>[] | undefined>();
  const [removeDialogOpen, openRemoveDialog] = useState(false);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { toDistance } = useUnits();
  const { page, rowsPerPage, setPage, onPageChange, onRowsPerPageChange } = usePaginatedTable<
    number,
    TKey
  >(25);
  const { orderBy, order, onSortRequest } = useSortableTable<number, TKey>('joinDate', 'desc', {
    setPage,
  });
  const { selectedKeys, onToggleKey, onSelectAllKeys, onUnselectAllKeys } = useSelectableTable();

  useEffect(() => {
    update();
  }, [orderBy, order, page, rowsPerPage]);

  function handleEdit() {
    onUnselectAllKeys();
    setEditable(!editable);
  }

  function handleRemoveDialogClose(reload?: boolean) {
    openRemoveDialog(false);
    if (reload) {
      onUnselectAllKeys();

      if (page === 0) update();
      else setPage(0);
    }
  }

  async function update() {
    setMembers(undefined);
    setMembersCount(undefined);
    setRows(undefined);
    onUnselectAllKeys();

    if (!currentPartner?.geoGroupId) return;

    try {
      const { count, users } = await GeogroupService.getMembers(currentPartner.geoGroupId, {
        partner: currentPartner,
        page: page + 1,
        pageSize: rowsPerPage,
        orderBy,
        order,
        onlyNew: false,
      });

      const now = moment();
      const _rows = [...users].map<TRow<number, TKey>>(({ id, username, joinDate, distance }) => ({
        key: id,
        cells: {
          username: {
            value: username || '',
            format: (username) => (
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <span>{username}</span>
                {joinDate.diff(now, 'days') >= -30 && (
                  <FiberNew color="secondary" fontSize="small" style={{ marginLeft: 4 }} />
                )}
              </div>
            ),
          },
          joinDate: { value: joinDate, format: (date: Moment) => date.format('L') },
          distance: { value: distance, format: toDistance },
        },
      }));

      setMembers(users);
      setMembersCount(count);
      setRows(_rows);
    } catch {
      enqueueSnackbar(t('cycling-insights.community.members.server_error'), { variant: 'error' });
    }
  }

  return (
    <>
      <Box
        bgcolor="#F6F7FB"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={5}
        paddingX={14}
        paddingY={8}
        sx={{ overflowY: 'auto' }}
      >
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Box alignItems="center" display="flex" gap={2}>
            <ButtonBase component={Link} to="../animation">
              <ArrowBackIosNew color="primary" fontSize="large" />
            </ButtonBase>
            <Typography
              color="primary"
              component="h2"
              flexDirection="column"
              fontSize="2rem"
              fontWeight="700"
            >
              <Trans i18nKey="cycling-insights.community.members.table.title" />
            </Typography>
          </Box>
          <Box display="flex" gap={3}>
            <Button
              color="primary"
              onClick={() => {
                openInvitationLinkDialog(true);
                trackEvent('Button Clicked', { cta: 'Community Invitation Button' });
              }}
              size="large"
              startIcon={<ShareOutlined />}
              variant="outlined"
            >
              <Trans i18nKey="cycling-insights.community.members.actions.get_invitation_link" />
            </Button>
            <Button
              disableElevation
              disabled={!members}
              onClick={handleEdit}
              size="large"
              startIcon={editable ? <LockOutlined /> : <EditOutlined />}
              variant="contained"
            >
              {editable ? (
                <Trans i18nKey="commons.actions.cancel" />
              ) : (
                <Trans i18nKey="commons.actions.edit" />
              )}
            </Button>
          </Box>
        </Box>
        {editable && (
          <Box
            alignItems="center"
            bgcolor="#9CBDF433"
            borderRadius={4}
            display="flex"
            justifyContent="space-between"
            paddingX={3}
            paddingY={2}
          >
            <Typography color="#326AC2" fontSize="1rem" fontWeight={600}>
              <Trans
                count={selectedKeys.length}
                i18nKey="cycling-insights.community.members.table.selected_members"
                values={{ count: selectedKeys.length }}
              />
            </Typography>
            <Button
              disableElevation
              disabled={!editable || selectedKeys.length === 0}
              onClick={() => openRemoveDialog(true)}
              size="medium"
              startIcon={<DeleteOutline />}
              variant="contained"
            >
              <Trans i18nKey="commons.actions.remove" />
            </Button>
          </Box>
        )}
        <Table
          hasBorder
          whiteBackgroundColor
          count={membersCount}
          headers={{
            username: {
              label: <Trans i18nKey="commons.user.username" />,
              sortable: true,
            },
            joinDate: {
              label: <Trans i18nKey="cycling-insights.community.members.table.join_date" />,
              sortable: true,
            },
            distance: {
              label: <Trans i18nKey="commons.stats.distance_label" />,
              sortable: true,
            },
          }}
          keys={keys}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          onSelectAllKeys={() => onSelectAllKeys(rows || [])}
          onSortRequest={onSortRequest}
          onToggleKey={onToggleKey}
          onUnselectAllKeys={onUnselectAllKeys}
          order={order}
          orderBy={orderBy}
          page={page}
          rows={rows}
          rowsPerPage={rowsPerPage}
          selectable={editable}
          selectedKeys={selectedKeys}
          size="medium"
          title="Community members table"
        />
      </Box>
      <InvitationLinkDialog
        onClose={() => openInvitationLinkDialog(false)}
        open={invitationLinkDialogOpen}
      />
      <RemoveDialog
        members={(members || []).filter(({ id }) => selectedKeys.indexOf(id) > -1)}
        onClose={handleRemoveDialogClose}
        open={removeDialogOpen}
      />
    </>
  );
}

export default MembersData;
