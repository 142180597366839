import {
  TCyclability,
  cyclabilities,
  facilitiesLabels,
  roadTypesLabels,
} from '@geovelo-frontends/commons';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';
import type { TThicknessCollection } from '../../components/color-legend';
import { getFacilityLabelKey } from '../../components/form/facilities';
import { ISliderBounds } from '../../components/form/slider';
import { TSectionFeatureCollection, TSectionProperties } from '../../models/sections';
import { cyclabilitiesMap } from '../../pages/home/components/cyclability';

import useHeatmap from './heatmap';

import { Map } from '!maplibre-gl';

const widths: TThicknessCollection = [
  { value: 2 },
  { value: 4 },
  { value: 6 },
  { value: 8 },
  { value: 10 },
];

function useCyclability(
  map: Map | null | undefined,
  onClick?: (section?: TSectionProperties) => void,
): {
  initialized: boolean;
  init: () => void;
  update: (
    { features }: TSectionFeatureCollection,
    props: {
      secondaryBounds?: ISliderBounds;
      selectedCyclabilities: TCyclability[];
    },
  ) => void;
  destroy: () => void;
} {
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();

  return useHeatmap(
    map,
    {
      sourceId: 'cyclability',
      layerId: 'cyclability',
      primaryCriterion: 'cyclability',
      secondaryCriterion: 'frequency',
      colors: cyclabilities.map((key) => ({ value: cyclabilitiesMap[key].color })),
      widths,
      interpolateWidths: true,
    },
    function getTooltipContent({ cyclability, frequency, facility, wayName, roadType }) {
      if (!cyclability) return '';

      const { bgColor, textColor, labelKey } = cyclabilitiesMap[cyclability];

      return (
        `<div style="padding: 8px"><h3>${
          wayName ? wayName : t(roadTypesLabels[roadType || 'unclassified'])
        }</h3>` +
        `<div style="background-color: ${bgColor}; color: ${textColor}; border: 1px solid ${textColor}; border-radius: 4px; width: fit-content; padding: 2px 6px; margin: 6px 0;">
          ${t(labelKey)}</div>` +
        (currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'none'
          ? ''
          : `<div style="color: #283859">${t('commons.stats.passages', {
              count: frequency,
            })}</div>`) +
        `<div>${
          t('cycling-insights.facilities.cyclability.road_type') +
          t(roadTypesLabels[roadType || 'unclassified'])
        }</div>` +
        `<div>${
          t('cycling-insights.facilities.cyclability.facility_type') +
          t(facility ? getFacilityLabelKey(facility) : facilitiesLabels['unknown'])
        }</div>`
      );
    },
    onClick,
  );
}

export default useCyclability;
