import { Box, Slider as MuiSlider, Skeleton, SliderProps, Typography } from '@mui/material';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import useAmplitudeTracker from '../../hooks/tracker';

export type TSliderRange = [number, number];

export interface ISliderBounds {
  max: number;
  min: number;
}

export interface ISliderProps {
  disableRange?: boolean;
  currentRange?: TSliderRange;
  customLabels?: string[];
  bounds?: ISliderBounds;
  eventValue: string;
  label: ReactNode;
  setCurrentRange: (range?: TSliderRange) => void;
  step: number | null;
}

export type TSliderProps = Pick<ISliderProps, 'bounds' | 'currentRange' | 'setCurrentRange'> & {
  setBounds: (bounds?: ISliderBounds) => void;
};

function Slider({
  disableRange,
  label,
  step,
  bounds,
  currentRange,
  customLabels,
  eventValue,
  setCurrentRange,
  ...props
}: ISliderProps & SliderProps): JSX.Element {
  const [range, setRange] = useState(currentRange);
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => setRange(currentRange), [bounds, currentRange]);

  function handleChange(_: Event, value: number | number[]) {
    if (Array.isArray(value)) setRange([value[0], value[1]]);
    else setRange([currentRange?.[0] || 0, value]);
  }

  function handleChangeCommitted(_: Event | SyntheticEvent, value: number | number[]) {
    if (Array.isArray(value)) setCurrentRange([value[0], value[1]]);
    else setCurrentRange([currentRange?.[0] || 0, value]);

    trackEvent('Filter Selected', { filter: eventValue });
  }

  return (
    <Box flexShrink={0} height={74} minWidth={270}>
      <Typography component="p" fontSize="0.875rem" fontWeight={600}>
        {label}
      </Typography>
      <Box display="flex" flexDirection="column">
        <StyledSlider
          disabled={!currentRange}
          marks={[
            { value: bounds?.min || 0, label: '' },
            {
              value: bounds?.max ? Math.max(bounds.max, step || 1) : 1,
              label: '',
            },
          ]}
          max={bounds?.max ? Math.max(bounds.max, step || 1) : 1}
          min={bounds?.min || 0}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          size="small"
          step={step}
          value={disableRange ? range?.[1] || 1 : range || [0, 1]}
          valueLabelDisplay={customLabels ? 'off' : 'auto'}
          {...props}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {customLabels ? (
            <>
              {customLabels.map((label, index) => (
                <Box key={index} width={`calc(100% / ${customLabels.length})`}>
                  <Typography
                    component="p"
                    textAlign={
                      index === 0 ? 'left' : index === customLabels.length - 1 ? 'right' : 'center'
                    }
                    variant="caption"
                  >
                    {label}
                  </Typography>
                </Box>
              ))}
            </>
          ) : (
            <>
              <Typography variant="caption">
                {bounds ? bounds.min : <Skeleton variant="text" width={50} />}
              </Typography>
              <Typography variant="caption">
                {bounds ? bounds.max : <Skeleton variant="text" width={50} />}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Slider;

const StyledSlider = styled(MuiSlider)`
  margin-top: 8px;

  && > .MuiSlider-markLabel {
    font-size: 0.75rem;
    top: 26px;
  }

  && > .MuiSlider-thumb {
    width: 14px;
    height: 14px;
  }
`;
