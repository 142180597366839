import {
  Challenge,
  ChallengeCard,
  ChallengeEmptyCard,
  GeogroupService,
  HighlightedEventCard,
  News,
  useCancellablePromise,
  useUnits,
} from '@geovelo-frontends/commons';
import { Add, KeyboardArrowRight, ShareOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import CreateContentDialog from '../../../../components/create-content-dialog';
import DataCard from '../../../../components/data-card';
import MembershipsIcon from '../../../../components/icons/memberships';
import PlottyBikeIcon from '../../../../components/icons/plotty-bike';
import PlottyUsersIcon from '../../../../components/icons/plotty-users';
import InvitationLinkDialog from '../../../../components/invitation-link-dialog';

function AnimationContent(): JSX.Element {
  const [membersCount, setMembersCount] = useState<number>();
  const [newMembersCount, setNewMembersCount] = useState<number>();
  const [currentChallenge, setCurrentChallenge] = useState<Challenge | null>();
  const [invitationLinkDialogOpen, openInvitationLinkDialog] = useState(false);
  const [createContentDialogOpen, openCreateContentDialog] = useState(false);
  const {
    app: { highlightedEvent, highlightedEventChallenge },
    partner: { current: currentPartner, currentGeogroup },
    user: { current: currentUser },
    actions: { setHighlightedEventChallenge },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { toDistance } = useUnits();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    update();
  }, [currentPartner]);

  useEffect(() => {
    getCurrentChallenge();

    return () => {
      cancelPromises();
    };
  }, [currentGeogroup]);

  async function getCurrentChallenge() {
    if (!currentGeogroup) return;

    try {
      const {
        challenges: [currentChallenge],
      } = await cancellablePromise(
        GeogroupService.getChallenges(currentGeogroup.id, {
          page: 1,
          pageSize: 1,
          state: ['running'],
        }),
      );

      setCurrentChallenge(currentChallenge || null);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('companies.pages.animation.ongoing_challenges.server_error'), {
          variant: 'error',
        });
        setCurrentChallenge(null);
      }
    }
  }

  async function update() {
    setMembersCount(undefined);
    setNewMembersCount(undefined);

    if (!currentPartner?.geoGroupId) return;

    try {
      const [{ count }, { count: newCount }] = await Promise.all([
        GeogroupService.getMembersCount(currentPartner.geoGroupId, {
          partner: currentPartner,
        }),
        GeogroupService.getMembersCount(currentPartner.geoGroupId, {
          partner: currentPartner,
          registrationStartDate: moment().add(-30, 'days').startOf('day'),
          registrationEndDate: moment(),
        }),
      ]);

      setMembersCount(count);
      setNewMembersCount(newCount);
    } catch {
      enqueueSnackbar(t('cycling-insights.community.members.server_error'), { variant: 'error' });
    }
  }

  return (
    <Box
      bgcolor="#F6F7FB"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      gap={5}
      paddingX={14}
      paddingY={8}
      sx={{ overflowY: 'auto' }}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography
          color="primary"
          component="h2"
          flexDirection="column"
          fontSize="2rem"
          fontWeight="700"
        >
          <Trans i18nKey="commons.navigation.animation" />
        </Typography>
        <Box display="flex" gap={3}>
          {!currentGeogroup?.isPremium && (
            <Button
              onClick={() => openInvitationLinkDialog(true)}
              size="large"
              startIcon={<ShareOutlined />}
              variant="outlined"
            >
              <Trans i18nKey="cycling-insights.community.members.actions.invite" />
            </Button>
          )}
          <Button
            disableElevation
            onClick={() => openCreateContentDialog(true)}
            size="large"
            startIcon={<Add />}
            variant="contained"
          >
            <Trans i18nKey="commons.actions.create" />
          </Button>
        </Box>
      </Box>
      <HighlightedEventCard
        Button={Button}
        displayButtonProps={
          highlightedEventChallenge
            ? {
                component: Link,
                to: `/${currentPartner?.code}/promotion/challenges/${highlightedEventChallenge.id}`,
              }
            : {}
        }
        geogroup={currentGeogroup}
        highlightedEvent={highlightedEvent}
        highlightedEventChallenge={highlightedEventChallenge}
        onJoined={(challenge) => {
          setHighlightedEventChallenge(challenge);
          navigate(`/${currentPartner?.code}/promotion/challenges/${challenge.id}`);
        }}
      />
      <Box display="flex" gap={3}>
        <DataCard
          backgroundColor="#2AC68226"
          caption={
            <Typography sx={{ textTransform: 'lowercase' }}>
              <Trans i18nKey="commons.stats.distance_label" />
            </Typography>
          }
          data={toDistance(currentGeogroup?.totalDistance || 0)}
          dataColor="#03825C"
          icon={<PlottyBikeIcon sx={{ height: '52px', width: '57px' }} />}
        />
        <DataCard
          backgroundColor="#D9E7FFCC"
          caption={
            <Typography component={Link} to="../members">
              <Trans i18nKey="commons.actions.manage" />
            </Typography>
          }
          data={
            membersCount !== undefined
              ? t('cycling-insights.community.members.count', { count: membersCount })
              : undefined
          }
          dataColor="#326AC2"
          icon={<PlottyUsersIcon sx={{ height: '72px', width: '80px' }} />}
        />
        {newMembersCount !== 0 && (
          <DataCard
            backgroundColor="#C9E9F8BF"
            caption={
              <Typography sx={{ textTransform: 'lowercase' }}>
                <Trans i18nKey="cycling-insights.community.members.last_30_days" />
              </Typography>
            }
            data={
              newMembersCount !== undefined
                ? t('cycling-insights.community.members.memberships', { count: newMembersCount })
                : undefined
            }
            dataColor="#1589BB"
            icon={<MembershipsIcon sx={{ height: '52px', width: '57px' }} />}
          />
        )}
      </Box>
      <Box display="flex" gap={8}>
        <Box display="flex" flexDirection="column" gap={2} minWidth={380} width="35%">
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography color="#283859" fontSize="1.5rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.community.challenges.title" />
            </Typography>
            <Button
              color="primary"
              component={Link}
              endIcon={<KeyboardArrowRight />}
              to="../challenges"
            >
              <Trans i18nKey="commons.all" />
            </Button>
          </Box>
          {currentChallenge === null ? (
            <ChallengeEmptyCard backgroundColor="#FFF" userType="user" />
          ) : currentChallenge ? (
            <ChallengeCard
              challenge={currentChallenge}
              containerProps={{ flexGrow: 0 }}
              redirectLink={
                currentPartner
                  ? `/${currentPartner.code}/promotion/challenges/${currentChallenge.id}`
                  : '/'
              }
            />
          ) : (
            <ChallengeCard containerProps={{ flexGrow: 0 }} />
          )}
        </Box>
        <Box display="flex" flexDirection="column" gap={2} width="65%">
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <Typography color="#283859" fontSize="1.5rem" fontWeight={700}>
              <Trans i18nKey="cycling-insights.community.news.title" />
            </Typography>
            <Button
              color="primary"
              component={Link}
              endIcon={<KeyboardArrowRight />}
              to="../news-feed"
            >
              <Trans i18nKey="cycling-insights.community.news.all" />
            </Button>
          </Box>
          <News
            small
            currentPartner={currentPartner}
            currentUser={currentUser}
            geogroup={currentGeogroup}
            newPostRedirection="../new-post"
            userType={'admin'}
          />
        </Box>
      </Box>
      <InvitationLinkDialog
        onClose={() => openInvitationLinkDialog(false)}
        open={invitationLinkDialogOpen}
      />
      <CreateContentDialog
        onClose={() => openCreateContentDialog(false)}
        open={createContentDialogOpen}
      />
    </Box>
  );
}

export default AnimationContent;
