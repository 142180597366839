import {
  GeogroupAvatar,
  PeopleIcon,
  SavedCO2Icon,
  useSource,
  useUnits,
} from '@geovelo-frontends/commons';
import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Root, createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../../app/context';
import { TThicknessCollection, findIndexInIntervals } from '../../../components/color-legend';
import JourneyIcon from '../../../components/icons/journey';
import { environment } from '../../../environment';
import { TStats } from '../components/home-work-journeys/content';

import { Map, MapGeoJSONFeature, Popup } from '!maplibre-gl';

export type TCompany = {
  homeWorkDistance: number;
  homeWorkTracesCount: number;
  id: string;
  location: GeoJSON.Point;
  membersCount: number;
  photo: string | null;
  startIcon: ReactNode;
  subtitle: string | null;
  title: string;
};

const sourceId = 'companies';
const layerId = 'companies';

const radiuses: TThicknessCollection = [
  { value: 4 },
  { value: 6 },
  { value: 8 },
  { value: 10 },
  { value: 12 },
  { value: 14 },
  { value: 16 },
  { value: 18 },
  { value: 20 },
  { value: 22 },
  { value: 24 },
];

function useHomeWorkJourneys({
  companies,
  stats,
}: {
  companies: TCompany[] | undefined;
  stats: TStats | undefined;
}) {
  const [initialized, setInitialized] = useState(false);
  const [mapInitialized, setMapInitialized] = useState(false);
  const {
    map: { current: map },
  } = useContext(AppContext);
  const { addGeoJSONSource, updateGeoJSONSource, getGeoJSONSource, clearGeoJSONSource } = useSource<
    GeoJSON.Point,
    TCompany & { radius: number }
  >(map, sourceId);
  const hoveredCompanyTooltip = useRef<Popup>();

  useEffect(() => {
    if (map) setInitialized(true);
  }, [map]);

  useEffect(() => {
    let root: Root | undefined;

    function handleMoveOnCompany({ features }: { features?: MapGeoJSONFeature[] }) {
      const feature = features?.[0];
      if (map && feature && feature.geometry.type === 'Point') {
        const [lng, lat] = feature.geometry.coordinates;

        map.getCanvas().style.cursor = 'pointer';

        hoveredCompanyTooltip.current?.setLngLat({ lat, lng }).addTo(map);

        if (!root) {
          const ele = document.getElementById('company-popup');
          if (!ele) return;

          root = createRoot(ele);
        }

        root.render(<CompanyTooltip company={feature.properties as unknown as TCompany} />);
      }
    }

    function handleLeaveCompany() {
      if (map) map.getCanvas().style.cursor = '';
      hoveredCompanyTooltip.current?.remove();
      root?.unmount();
      root = undefined;
    }

    function initMap(map: Map) {
      if (!getGeoJSONSource()) {
        addGeoJSONSource();

        map.addLayer({
          id: layerId,
          type: 'circle',
          source: sourceId,
          paint: {
            'circle-radius': ['get', 'radius'],
            'circle-color': '#326ac2',
            'circle-opacity': 0.5,
            'circle-stroke-width': 2,
            'circle-stroke-opacity': 1,
            'circle-stroke-color': '#326ac2',
          },
        });

        map.on('mousemove', layerId, handleMoveOnCompany);
        map.on('mouseleave', layerId, handleLeaveCompany);

        hoveredCompanyTooltip.current = new Popup({
          offset: [0, 0],
          closeButton: false,
          closeOnClick: false,
          maxWidth: '300px',
          className: 'map-custom-popup',
        }).setHTML('<div id="company-popup"></div>');
      }

      setMapInitialized(true);
    }

    if (initialized && map) initMap(map);

    return () => {
      map?.off('mousemove', layerId, handleMoveOnCompany);
      map?.off('mouseleave', layerId, handleLeaveCompany);
    };
  }, [initialized]);

  useEffect(() => {
    function updateMap() {
      updateGeoJSONSource({
        type: 'FeatureCollection',
        features: (companies || []).map(({ membersCount, ...otherProps }) => ({
          type: 'Feature',
          geometry: otherProps.location,
          properties: {
            radius:
              radiuses[
                findIndexInIntervals<number>(
                  otherProps.homeWorkTracesCount,
                  { min: 0, max: stats?.tracesMax !== undefined ? stats?.tracesMax : 1 },
                  radiuses,
                ) ?? 0
              ].value,
            membersCount,
            ...otherProps,
          },
        })),
      });
    }

    if (mapInitialized) updateMap();

    return () => clearGeoJSONSource();
  }, [mapInitialized, companies]);
}

function CompanyTooltip({
  company: { title, subtitle, photo, membersCount, homeWorkTracesCount, homeWorkDistance },
}: {
  company: TCompany;
}): JSX.Element {
  const { t } = useTranslation();
  const { formatNumber } = useUnits();

  const savedKgOfCO2 = (homeWorkDistance / 1000) * environment.savedKgOfCO2PerKilometer;

  return (
    <Card elevation={0} sx={{ width: 300 }}>
      <CardHeader
        avatar={<GeogroupAvatar geogroup={{ title, photo }} />}
        subheader={subtitle}
        subheaderTypographyProps={{ color: 'textSecondary', variant: 'body2' }}
        title={title}
        titleTypographyProps={{ variant: 'subtitle1' }}
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: 1, paddingTop: 0 }}>
        <Box alignItems="center" display="flex" gap={1}>
          <PeopleIcon color="action" fontSize="small" />
          <Typography variant="body2">
            {t('cycling-insights.community.home_work_journeys.companies_list.employees', {
              count: membersCount,
            })}
          </Typography>
        </Box>
        <Box alignItems="center" display="flex" gap={1}>
          <JourneyIcon color="action" fontSize="small" />
          <Typography variant="body2">
            {t('cycling-insights.community.home_work_journeys.companies_list.home_work_journeys', {
              count: homeWorkTracesCount,
              value: formatNumber(homeWorkTracesCount),
            })}
          </Typography>
        </Box>
        <Box alignItems="center" display="flex" gap={1}>
          <SavedCO2Icon color="action" fontSize="small" />
          <Typography variant="body2">
            {t('cycling-insights.community.home_work_journeys.companies_list.saved_CO2', {
              count: Math.floor(savedKgOfCO2),
              value: formatNumber(Math.round(savedKgOfCO2 * 10) / 10),
            })}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default useHomeWorkJourneys;
