import { AuthService, TLanguage, TPartner } from '@geovelo-frontends/commons';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import { AppContext } from '../../app/context';
import Button from '../../components/button';
import { environment } from '../../environment';
import useAmplitudeTracker from '../../hooks/tracker';
import LoginLayout from '../../layouts/login';

interface IValues {
  email: string;
  password: string;
  rememberMe: boolean;
}

function Login(): JSX.Element {
  const _backIsReady = false;
  const [passwordShowed, showPassword] = useState(false);
  const [partner, setPartner] = useState<TPartner>();
  const {
    actions: { setCurrentUser },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const {
    isSubmitting,
    isValid,
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = useFormik<IValues>({
    initialValues: { email: '', password: '', rememberMe: false },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(t('commons.sign_in_form.email_invalid') || '')
        .required(t('commons.sign_in_form.email_required') || ''),
      password: Yup.string().required(),
    }),
    enableReinitialize: true,
    onSubmit,
  });
  const { trackEvent } = useAmplitudeTracker();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    trackEvent('Page Visited', { pathname: window.location.pathname });

    try {
      const token = searchParams.get('data');
      if (!token) {
        navigate('/login');
        return;
      }

      const {
        email,
        partner_code: partnerCode,
        partner_title: partnerTitle,
        partner_icon: partnerIcon,
      } = JSON.parse(atob(token || ''));
      const partner: TPartner = {
        code: partnerCode,
        title: partnerTitle || null,
        icon: partnerIcon ? `${environment.backendUrl}${partnerIcon}` : null,
      };

      if (!partnerCode) navigate('/login');

      setPartner(partner);
      setFieldValue('email', email);
    } catch (err) {
      console.error(err);
      navigate('/login');
    }
  }, []);

  async function onSubmit(
    { email, password, rememberMe }: IValues,
    { setSubmitting, setErrors }: FormikHelpers<IValues>,
  ) {
    if (!email || !password) return;

    setSubmitting(true);

    try {
      const user = await AuthService.signInWithEmail(
        email,
        password,
        rememberMe,
        language as TLanguage,
      );

      setCurrentUser(user);
    } catch (err) {
      setErrors({
        email:
          t(
            typeof err === 'object' && err && 'code' in err && err.code === 429
              ? 'commons.sign_in_form.too_many_attempts'
              : 'commons.sign_in_form.invalid_credentials',
          ) || '',
      });
      setFieldValue('password', '', false);
      setSubmitting(false);
    }
  }

  return (
    <LoginLayout>
      <StyledForm onSubmit={handleSubmit}>
        {partner && (
          <Box alignItems="center" display="flex" gap={2}>
            {partner.icon ? (
              <StyledAvatar src={partner.icon} />
            ) : (
              <>{partner.code && <StyledAvatar>{partner.code.toUpperCase()}</StyledAvatar>}</>
            )}
            <Typography fontWeight={700} lineHeight={1.8}>
              <Trans
                i18nKey="cycling-insights.sign_up.partner_invite"
                values={{ partner: partner.title }}
              />
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap={2}>
          {errors.email && touched.email && <Typography color="error">{errors.email}</Typography>}
          <TextField
            disabled={isSubmitting || Boolean(partner)}
            error={Boolean(errors.email && touched.email)}
            id="email"
            label={<Trans i18nKey="commons.sign_in_form.email" />}
            name="email"
            onChange={handleChange}
            size="small"
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            disabled={isSubmitting}
            id="password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => showPassword(!passwordShowed)}
                    size="medium"
                  >
                    {passwordShowed ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={<Trans i18nKey="commons.sign_in_form.password" />}
            name="password"
            onChange={handleChange}
            size="small"
            type={passwordShowed ? 'text' : 'password'}
            value={values.password}
            variant="outlined"
          />
          <Typography variant="caption">
            <Trans
              components={[
                <Typography
                  color="primary"
                  component={Link}
                  key={0}
                  to="/forgot-password"
                  variant="caption"
                />,
              ]}
              i18nKey="commons.sign_in_form.forgot_password"
            />
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                name="rememberMe"
                onChange={handleChange}
                size="small"
                value={values.rememberMe}
              />
            }
            disabled={isSubmitting}
            label={<Trans i18nKey="commons.sign_in_form.remember_me" />}
          />
        </Box>
        <Box alignSelf="flex-start">
          <Button
            color="primary"
            disabled={isSubmitting || !isValid}
            size="large"
            type="submit"
            variant="contained"
          >
            <Trans i18nKey="commons.actions.sign_in" />
          </Button>
        </Box>
        {_backIsReady && (
          <StyledTypography align="center">
            <Trans
              components={[
                <Typography
                  color="primary"
                  component="a"
                  href={`${process.env.PUBLIC_URL}/register/api`}
                  key={1}
                />,
              ]}
              i18nKey="cycling-insights.login.want_sign_up"
            />
          </StyledTypography>
        )}
      </StyledForm>
    </LoginLayout>
  );
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 40px auto;
  max-width: 100%;
  width: 400px;
`;

const StyledAvatar = styled(Avatar)`
  && {
    background-color: ${({ theme }) => theme.palette.secondary.light};
    border: 1px solid whitesmoke;
    color: ${({ theme }) => theme.palette.primary.dark};
    font-size: 2em;
    font-weight: 700;
    height: 64px;
    width: 64px;
  }
`;

const StyledTypography = styled(Typography)`
  && {
    margin-top: 24px;
  }
`;

export default Login;
