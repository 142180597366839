import { useAmplitudeTracker as _useAmplitudeTracker } from '@geovelo-frontends/commons';
import { useContext } from 'react';

import { AppContext } from '../app/context';

function useAmplitudeTracker() {
  const {
    partner: { current: currentPartner, activeContracts, contractTemplates },
  } = useContext(AppContext);
  const { trackEvent: _trackEvent } = _useAmplitudeTracker();

  function trackEvent(
    name: 'Page Visited' | string,
    properties?: Record<string, string | boolean | undefined>,
  ) {
    const activeContractsTemplateIds = activeContracts?.map(
      ({ contractTemplateId }) => contractTemplateId,
    );

    _trackEvent(name, {
      pathname: `${window.location.host}${window.location.pathname}`,
      partner_id: currentPartner?.id,
      partner_code: currentPartner?.code,
      partner_contracts:
        activeContractsTemplateIds &&
        contractTemplates
          ?.filter(({ id, code }) => code && activeContractsTemplateIds.includes(id))
          .map(({ code }) => code || ''),
      ...properties,
    });
  }

  return { trackEvent };
}

export default useAmplitudeTracker;
