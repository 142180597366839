import {
  Ride,
  RideService,
  TBackendPublicationStatus,
  TPublicationStatus,
  useCancellablePromise,
  useRides,
} from '@geovelo-frontends/commons';
import { Close, FilterList, Search } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import TabIntroduction from '../../../../components/tab-introduction';
import useAmplitudeTracker from '../../../../hooks/tracker';
import { publicationStatuses } from '../../../../models/publication-status';
import { IPromotionPageContext } from '../../context';

import FilterDialog from './filter-dialog';
import RideFormDialog from './form-dialog';
import RidesTable, { IRidesTableRef } from './table';

function RidesForm(context: IPromotionPageContext): JSX.Element {
  const {
    rides: { list, setList },
  } = context;
  const [rides, setRides] = useState<Ride[]>();
  const [count, setCount] = useState<number>();
  const [search, setSearch] = useState('');
  const [selectedPublicationStatuses, selectPublicationStatuses] = useState<TPublicationStatus[]>([
    'published',
    'unpublished',
    'waitingForApproval',
  ]);
  const [initialized, setInitialized] = useState(false);
  const [filterDialogOpen, openFilterDialog] = useState(false);
  const [formDialogOpen, openFormDialog] = useState(false);
  const {
    map: { current: map },
    partner: { current: currentPartner },
    ride: { themes },
    actions: { setRideThemes },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise: cancellableThemesPromise, cancelPromises: cancelThemesPromises } =
    useCancellablePromise();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { trackEvent } = useAmplitudeTracker();
  const {
    initialized: ridesInitializedOnMap,
    init: initRidesOnMap,
    update: updateRidesMarkers,
    updateClusters: updateRideClusters,
    clear: clearRidesMarkers,
  } = useRides(map, theme, list, undefined, true, {
    onClick: (ride) =>
      ride && currentPartner && navigate(`/${currentPartner.code}/promotion/rides/${ride.id}`),
  });
  const tableRef = useRef<IRidesTableRef>(null);
  const searchTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    getThemes();
    setInitialized(true);

    return () => {
      cancelThemesPromises();
      cancelSearch();
    };
  }, []);

  useEffect(() => {
    if (map) {
      initRidesOnMap();
      map?.on('zoomend', () => updateRideClusters());
    }

    return () => clearRidesMarkers();
  }, [map]);

  useEffect(() => {
    if (ridesInitializedOnMap) updateRidesMarkers(list || []);
  }, [ridesInitializedOnMap, list]);

  useEffect(() => {
    if (!initialized) return;

    setList(undefined);
    setRides(undefined);
    setCount(undefined);

    searchTimeout.current = setTimeout(() => {
      tableRef.current?.getRides();
      getRides();
      trackEvent('Filter Selected', { filter: 'Search' });
    }, 300);

    return () => {
      if (searchTimeout.current) clearTimeout(searchTimeout.current);
    };
  }, [search]);

  useEffect(() => {
    if (initialized) getRides();

    return () => cancelSearch();
  }, [initialized, themes, selectedPublicationStatuses]);

  function cancelSearch() {
    if (searchTimeout.current) clearTimeout(searchTimeout.current);
  }

  async function getThemes() {
    if (!themes) {
      try {
        const rideThemes = await cancellableThemesPromise(RideService.getRideThemes());

        setRideThemes(rideThemes);
      } catch (err) {
        if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
          enqueueSnackbar(t('cycling-insights.tourism.ride_themes.server_error'), {
            variant: 'error',
          });
        }
      }
    }
  }

  async function getRides() {
    setList(undefined);
    cancelPromises();

    if (!themes) return;

    try {
      const publicationStatusMap: { [key in TPublicationStatus]: TBackendPublicationStatus } = {
        published: 'PUBLISHED',
        unpublished: 'UNPUBLISHED',
        waitingForApproval: 'WAITING_FOR_APPROVAL',
      };

      const rides: Ride[] = [];
      let hasMore = true;
      let page = 1;
      while (hasMore) {
        const { rides: _rides, next } = await cancellablePromise(
          RideService.getRides({
            page,
            pageSize: 100,
            search,
            publicationStatuses: publicationStatuses
              .filter((key) => selectedPublicationStatuses.includes(key))
              .map((key) => publicationStatusMap[key]),
            query: '{id, title, icon, geo_point_center, geometry_condensed }',
          }),
        );
        hasMore = Boolean(next);
        ++page;
        rides.push(..._rides);
      }

      setList([...rides]);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.tourism.rides.list.server_error'), {
          variant: 'error',
        });
      }
    }
  }

  function handleSearchChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setSearch(value);
  }

  return (
    <>
      <Box display="flex" flexDirection="column">
        <TabIntroduction title="cycling-insights.tourism.introduction.rides" />
        <Box display="flex" flexDirection="column" flexShrink={0} gap={2} padding={2}>
          <Box alignItems="center" display="flex" gap={1}>
            <TextField
              fullWidth
              disabled={!initialized}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearch('')} size="small">
                      <Close />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={handleSearchChange}
              placeholder={t('commons.actions.search') || ''}
              size="small"
              value={search}
              variant="outlined"
            />
            <Box flexShrink={0}>
              <Button
                onClick={() => {
                  openFilterDialog(true);
                  trackEvent('Button Clicked', { cta: 'Rides Filter Button' });
                }}
                size="small"
                startIcon={<FilterList />}
                variant="outlined"
              >
                <Trans i18nKey="commons.actions.filter" />
              </Button>
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1} paddingX={1} sx={{ overflowY: 'auto' }}>
          <RidesTable
            {...context}
            cancelSearch={cancelSearch}
            count={count}
            onAdd={() => {
              openFormDialog(true);
              trackEvent('Button Clicked', { cta: 'Add Ride Button' });
            }}
            ref={tableRef}
            rides={rides}
            search={search}
            selectedPublicationStatuses={selectedPublicationStatuses}
            setCount={setCount}
            setRides={setRides}
          />
        </Box>
      </Box>
      <FilterDialog
        dialogTitle="ride-filters-dialog"
        onCancel={() => openFilterDialog(false)}
        onConfirm={(params) => {
          if (params) {
            const { selectedPublicationStatuses } = params;

            selectPublicationStatuses(selectedPublicationStatuses);
            trackEvent('Filter Selected', { filter: 'Publication Statuses' });
          }
          openFilterDialog(false);
        }}
        open={filterDialogOpen}
        selectedPublicationStatuses={selectedPublicationStatuses}
      />
      <RideFormDialog onClose={() => openFormDialog(false)} open={formDialogOpen} />
    </>
  );
}

export default RidesForm;
