import {
  Period,
  StatsService,
  prevMonth,
  roadTypesLabels,
  useCancellablePromise,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { FileDownloadOutlined, Place } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';
import FacilitiesForm from '../../../../components/form/facilities';
import PeriodForm from '../../../../components/form/period';
import Paper from '../../../../components/paper';
import Ranking from '../../../../components/ranking';
import TabIntroduction from '../../../../components/tab-introduction';
import useExtrapolation from '../../../../hooks/map/extrapolation';
import useRoadsUses, {
  colors as _colors,
  colorsComparison,
  colorsComparisonAllPositives,
} from '../../../../hooks/map/roads-uses';
import useQueryParams from '../../../../hooks/query-params';
import useSectionsStats from '../../../../hooks/sections-stats';
import useAmplitudeTracker from '../../../../hooks/tracker';
import { TOutletContext } from '../../../../layouts/page/container';
import { IBicycleObservatoryPageContext } from '../../context';
import FrequenciesChart from '../roads-use-new/chart';

const minPeriod = new Period(
  'month',
  moment('2021-10').startOf('month'),
  moment('2021-10').endOf('month'),
);

const maxPeriod = moment().get('date') <= 7 ? prevMonth : undefined;

function RoadsUseAnalysisForm(
  context: IBicycleObservatoryPageContext & TOutletContext,
): JSX.Element {
  const {
    header: { setPrevButtonClick, setActions },
    period,
    roadsUse,
    loading: _loading,
    setLoading,
  } = context;
  const {
    data,
    distancesByFacilities,
    bounds,
    currentRange,
    selectedFacilities,
    selectFacilities,
    setDistancesByFacilities,
    setPrevDistancesByFacilities,
    setQuartiles,
  } = roadsUse;
  const [lastMonthDistance, setLastMonthDistance] = useState<{
    normal: number;
    extrapolated: number;
  }>();
  const [lastYearDistance, setLastYearDistance] = useState<{
    normal: number;
    extrapolated: number;
  }>();
  const [loadingDistances, setLoadingDistances] = useState(false);
  const [alreadyLaunched, setAlreadyLaunched] = useState(false);
  const lastYear = useRef<Period>(period.values.current.clone());
  const lastMonth = useRef<Period>(period.values.current.clone());
  const {
    map: { current: currentMap, baseLayer },
    partner: { current: currentPartner, cyclabilityZones, sections },
    actions: { getPartnerCyclabilityZones },
  } = useContext(AppContext);
  const {
    initialized: layersInitialized,
    init: initLayers,
    update: updateLayers,
    destroy: destroyLayers,
  } = useRoadsUses(currentMap, period);
  const {
    initialized: extrapolationInitialized,
    init: initExtrapolation,
    update: updateExtrapolation,
    destroy: destroyExtrapolation,
  } = useExtrapolation(currentMap, period);
  const { timeoutRef, stats, getStats } = useSectionsStats({
    isExtrapolated: currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated',
    hasChartComparison: true,
    automaticReloadDisabled: !alreadyLaunched,
    primaryCriterion:
      currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated'
        ? 'extrapolation'
        : 'frequency',
    initialized:
      currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated'
        ? extrapolationInitialized
        : layersInitialized,
    period,
    ...roadsUse,
    setLoading,
  });
  const { downloadBlob } = useFileSaver();
  const { trackEvent: _trackEvent } = useAmplitudeTracker();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const initializedRef = useRef(false);
  const { getPeriods } = useQueryParams();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    setPrevButtonClick(() => () => navigate('../roads-use'));

    if (!initializedRef.current) {
      window.document.getElementById('left-panel')?.scrollTo({ top: 0, behavior: 'instant' });

      const defaultPeriods = getPeriods(
        moment().get('date') <= 7 ? prevMonth.getPrevPeriod() : prevMonth,
      );

      period.setValues(defaultPeriods);
    }

    initializedRef.current = true;

    return () => {
      cancelPromises();
      setPrevButtonClick(undefined);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (currentPartner && !cyclabilityZones) getPartnerCyclabilityZones(currentPartner);
  }, [currentPartner]);

  useEffect(() => {
    lastYear.current = period.values.current.clone();
    lastYear.current.from.add(-1, 'year');
    lastYear.current.to.add(-1, 'year');
    lastMonth.current = period.values.current.clone();
    lastMonth.current.from.add(-1, 'month');
    lastMonth.current.to.add(-1, 'month').endOf('month');
    if (alreadyLaunched) getDistancesTravelled();
  }, [period.values.current, currentPartner]);

  useEffect(() => {
    if (currentMap) {
      if (currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated')
        initExtrapolation();
      else initLayers();
    }

    return () => {
      if (currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated')
        destroyExtrapolation();
      else destroyLayers();
    };
  }, [currentMap]);

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (!layersInitialized && !extrapolationInitialized) return;

    const { comparisonEnabled } = period;

    if (data && bounds && currentRange) {
      timeoutRef.current = setTimeout(() => {
        const { min, max } = bounds;
        let _quartiles: number[] | undefined;

        if (!comparisonEnabled && data.features.length > 0) {
          const speeds = data.features
            .map(({ properties: { frequency, extrapolation } }) =>
              currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated'
                ? extrapolation
                : frequency,
            )
            .filter((frequency) => frequency && frequency >= min && frequency <= max)
            .sort((a, b) => (a && b ? a - b : 1));

          _quartiles = [
            ...new Set([
              min,
              ..._colors
                .slice(0, Math.min(_colors.length - 1, speeds.length - 1))
                .map((_, index) =>
                  Math.round(
                    speeds[Math.round(speeds.length * ((index + 1) / _colors.length))] || 0,
                  ),
                ),
              max,
            ]),
          ];
        } else if (comparisonEnabled) {
          if (min < 0 && max > 0) {
            const nbNegativeParts = Math.ceil(colorsComparison.length / 2),
              nbPositiveParts = nbNegativeParts;
            const negativeGap = -min / nbNegativeParts;
            const positiveGap = max / nbPositiveParts;

            _quartiles = [
              min,
              ...new Array(nbNegativeParts - 1)
                .fill(null)
                .map((_, index) => Math.round((min + (index + 1) * negativeGap) * 100) / 100),
              ...new Array(nbPositiveParts - 1)
                .fill(null)
                .map((_, index) => Math.round((index + 1) * positiveGap * 100) / 100),
              max,
            ];
          }
        }

        if (_quartiles) setQuartiles?.(_quartiles);

        const colors = comparisonEnabled
          ? min < 0 && max > 0
            ? colorsComparison
            : min >= 0
              ? colorsComparisonAllPositives
              : colorsComparisonAllPositives
          : baseLayer === 'dark'
            ? _colors
            : [..._colors].reverse();
        if (currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated')
          updateExtrapolation(data, {
            colors: _quartiles
              ? colors.slice(0, _quartiles.length - 1).map(({ value }, index) => ({
                  value,
                  min: _quartiles?.[index],
                  max: _quartiles?.[index + 1],
                }))
              : colors,
            comparisonEnabled,
            currentRange,
            primaryBounds: bounds,
            secondaryBounds: bounds,
          });
        else
          updateLayers(data, {
            colors: _quartiles
              ? colors.slice(0, _quartiles.length - 1).map(({ value }, index) => ({
                  value,
                  min: _quartiles?.[index],
                  max: _quartiles?.[index + 1],
                }))
              : colors,
            comparisonEnabled,
            currentRange,
            primaryBounds: bounds,
            secondaryBounds: bounds,
          });
      }, 300);
    } else {
      if (currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated')
        updateExtrapolation(
          { type: 'FeatureCollection', features: [] },
          {
            comparisonEnabled,
            primaryBounds: { min: 0, max: 1 },
            secondaryBounds: bounds,
          },
        );
      else
        updateLayers(
          { type: 'FeatureCollection', features: [] },
          {
            comparisonEnabled,
            currentRange: [0, 1],
            primaryBounds: { min: 0, max: 1 },
            secondaryBounds: { min: 0, max: 1 },
          },
        );
    }
  }, [baseLayer, layersInitialized, extrapolationInitialized, data, currentRange]);

  useEffect(() => {
    if (sections && stats) setAlreadyLaunched(true);
  }, [sections, stats]);

  useEffect(() => {
    if (data)
      setActions(
        <>
          <Tooltip title={t('commons.actions.download')}>
            <IconButton color="primary" onClick={() => handleDownload()} size="small">
              <FileDownloadOutlined />
            </IconButton>
          </Tooltip>
        </>,
      );

    return () => {
      setActions(undefined);
    };
  }, [data]);

  async function getDistancesTravelled() {
    cancelPromises();
    setDistancesByFacilities?.(undefined);
    setLastMonthDistance(undefined);
    setLastYearDistance(undefined);

    if (!currentPartner) return;

    setLoadingDistances(true);

    try {
      if (period.comparisonEnabled) {
        const [currentDistances, prevDistances] = await cancellablePromise(
          Promise.all([
            StatsService.getDistancesTravelled(
              currentPartner.id,
              period.values.current.toIPeriod(),
            ),
            StatsService.getDistancesTravelled(currentPartner.id, period.values.prev.toIPeriod()),
          ]),
        );
        setDistancesByFacilities?.(currentDistances);
        setPrevDistancesByFacilities?.(prevDistances);
      } else {
        const [currentDistances, lastMonthDistances, lastYearDistances] = await cancellablePromise(
          Promise.all([
            StatsService.getDistancesTravelled(
              currentPartner.id,
              period.values.current.toIPeriod(),
            ),
            StatsService.getDistancesTravelled(currentPartner.id, lastMonth.current.toIPeriod()),
            StatsService.getDistancesTravelled(currentPartner.id, lastYear.current.toIPeriod()),
          ]),
        );
        setDistancesByFacilities?.(currentDistances);
        setLastMonthDistance({
          extrapolated: lastMonthDistances.allExtrapolated,
          normal: lastMonthDistances.all,
        });
        setLastYearDistance({
          extrapolated: lastYearDistances.allExtrapolated,
          normal: lastYearDistances.all,
        });
      }
    } catch {
      enqueueSnackbar(t('cycling-insights.usage.roads_use.server_error'), {
        variant: 'error',
      });
    }

    setLoadingDistances(false);
  }

  function handleDownload() {
    if (!data || !currentRange) return;

    _trackEvent('File Downloaded', { file: 'Roads Use analysis' });

    const {
      values: { current: currentPeriod, prev: prevPeriod },
      comparisonEnabled,
    } = period;
    const [min, max] = currentRange;

    downloadBlob(
      `stats-${t('cycling-insights.usage.navigation.road_use').replace(/ /g, '_').toLowerCase()}-${
        comparisonEnabled
          ? `${prevPeriod.from.format('YYYY-MM-DD')}_${prevPeriod.to.format('YYYY-MM-DD')}-`
          : ''
      }${currentPeriod.from.format('YYYY-MM-DD')}_${currentPeriod.to.format('YYYY-MM-DD')}.geojson`,
      new Blob(
        [
          JSON.stringify(
            {
              ...data,
              features: data.features.filter(
                ({ properties: { frequency } }) =>
                  frequency && frequency >= min && frequency <= max,
              ),
            },
            null,
            2,
          ),
        ],
        { type: 'application/json' },
      ),
    );
  }

  const loading = _loading || loadingDistances;

  return (
    <Box display="flex" flexDirection="column" gap={3} minHeight="100%">
      <Box display="flex" flexDirection="column" flexGrow={1} gap={3}>
        <TabIntroduction title="cycling-insights.bicycle_observatory.introduction.roads_use" />
        <Paper
          disablePadding
          header={
            <Box display="flex" flexDirection="column" gap={3}>
              <PeriodForm
                disablePadding
                enableCustomComparison
                enableDayPeriods
                enableTimePeriods
                maxPeriod={maxPeriod}
                minPeriod={minPeriod}
                {...period}
              />
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography fontWeight={600} variant="subtitle2">
                  {t('commons.facilities_types')}
                </Typography>
                <FacilitiesForm
                  selectedFacilities={selectedFacilities}
                  selectFacilities={selectFacilities}
                />
              </Box>
            </Box>
          }
        >
          {alreadyLaunched && (
            <>
              <Box padding={3}>
                <FrequenciesChart
                  lastMonthDistance={lastMonthDistance}
                  lastYearDistance={lastYearDistance}
                  {...context}
                />
              </Box>
              <Ranking
                data={
                  !!data && data.features.length > 0
                    ? data.features
                        .sort((a, b) =>
                          a.properties.sectionDistanceTraveled !== undefined &&
                          b.properties.sectionDistanceTraveled !== undefined
                            ? (b.properties.sectionDistanceTraveled || 0) -
                              (a.properties.sectionDistanceTraveled || 0)
                            : (b.properties.distanceTraveled || 0) -
                              (a.properties.distanceTraveled || 0),
                        )
                        .slice(
                          0,
                          data.features[0].properties.sectionDistanceTraveled !== undefined
                            ? 10
                            : 5,
                        )
                        .reduce<{ id?: number; title: string; subtitle?: string; value: number }[]>(
                          (
                            res,
                            {
                              properties: {
                                id,
                                wayName,
                                distanceTraveled,
                                roadType,
                                sectionDistanceTraveled,
                              },
                            },
                            index,
                          ) => {
                            if (data.features[0].properties.sectionDistanceTraveled !== undefined) {
                              if (index % 2 === 0)
                                res.push({
                                  id,
                                  title:
                                    wayName ||
                                    t(roadTypesLabels[roadType || 'unclassified']) +
                                      ' - ' +
                                      id.toString(),
                                  value: sectionDistanceTraveled || 0,
                                });
                              return res;
                            } else {
                              res.push({
                                id,
                                title:
                                  wayName ||
                                  t(roadTypesLabels[roadType || 'unclassified']) +
                                    ' - ' +
                                    id.toString(),
                                value: distanceTraveled || 0,
                              });
                              return res;
                            }
                          },
                          [],
                        )
                    : undefined
                }
                formatProgress={(value) =>
                  currentPartner?.dashboardTabsPermissions.usageRoadsUse === 'extrapolated'
                    ? distancesByFacilities?.allExtrapolated
                      ? `${((value / distancesByFacilities?.allExtrapolated) * 100).toFixed(2)} %`
                      : ''
                    : distancesByFacilities?.all
                      ? `${((value / distancesByFacilities?.all) * 100).toFixed(2)} %`
                      : ''
                }
                onClick={(id) => {
                  const coordinates = data?.features.find(({ properties }) => properties.id === id)
                    ?.geometry.coordinates;

                  if (coordinates) {
                    const [lng, lat] = coordinates[Math.round(coordinates.length / 2)];
                    currentMap?.flyTo({
                      center: { lat, lng },
                      zoom: 18,
                      animate: false,
                    });
                  }
                }}
                startIcon={<Place sx={{ color: '#E76685' }} />}
                subtitle={
                  <Typography marginBottom={2} marginTop={1} variant="body2">
                    {t('cycling-insights.usage.roads_use.ranking.subtitle')}
                  </Typography>
                }
                title="cycling-insights.usage.roads_use.ranking.title"
              />
            </>
          )}
        </Paper>
      </Box>
      {!alreadyLaunched && (
        <Box
          bgcolor="#fff"
          borderTop="1px solid rgba(0, 0, 0, 0.12)"
          bottom={0}
          display="flex"
          gap={2}
          justifyContent="flex-end"
          left={0}
          marginBottom={-3}
          marginX={-3}
          paddingX={3}
          paddingY={2}
          position="sticky"
          right={0}
          zIndex={1000}
        >
          <Tooltip title={t('cycling-insights.bicycle_observatory.long_analysis')}>
            <Button
              disableElevation
              color="primary"
              disabled={loading}
              onClick={() => {
                getStats();
                getDistancesTravelled();
              }}
              size="large"
              startIcon={loading && <CircularProgress color="inherit" size={16} thickness={4} />}
              variant="contained"
            >
              {t('commons.actions.analyze')}
            </Button>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}

export default RoadsUseAnalysisForm;
