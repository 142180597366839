import { cyclabilities } from '@geovelo-frontends/commons';
import { Box, Skeleton, Typography } from '@mui/material';
import { Chart } from 'chart.js';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { cyclabilitiesMap } from '../../../home/components/cyclability';
import { TCartographicDataPageContext } from '../../context';

const roadsTypesChartId = 'cyclability-chart';

function CyclabilityChart({
  cyclability: { roadsCountByCyclability },
}: TCartographicDataPageContext): JSX.Element {
  const { t } = useTranslation();
  const roadsTypesChartRef = useRef<Chart<'doughnut'>>();

  useEffect(() => {
    const roadsTypesChartCtx = document.getElementById(roadsTypesChartId);
    if (roadsTypesChartCtx && roadsTypesChartCtx instanceof HTMLCanvasElement) {
      roadsTypesChartRef.current?.destroy();

      roadsTypesChartRef.current = new Chart(roadsTypesChartCtx, {
        type: 'doughnut',
        data: {
          labels: cyclabilities.map((key) => t(cyclabilitiesMap[key].labelKey)),
          datasets: roadsCountByCyclability
            ? [
                {
                  data: roadsCountByCyclability.slice(),
                  backgroundColor: cyclabilities.map((key) => cyclabilitiesMap[key].color),
                },
              ]
            : [
                {
                  data: [70, 30],
                  backgroundColor: ['#E3E7EE', '#F6F7FB'],
                },
              ],
        },
        options: {
          cutout: '80%',
          events: roadsCountByCyclability
            ? ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove']
            : [],
          responsive: true,
          maintainAspectRatio: false,
          plugins: roadsCountByCyclability
            ? {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: ({ parsed }) =>
                      ` ${
                        Math.round(
                          (parsed /
                            roadsCountByCyclability.reduce((value, res) => (res += value), 0)) *
                            1000,
                        ) / 10
                      } %`,
                  },
                },
                title: {
                  display: false,
                },
              }
            : {
                legend: {
                  display: false,
                },
                title: {
                  display: false,
                },
              },
        },
      });
    }
  }, [roadsCountByCyclability]);

  const totalRoadsCount = roadsCountByCyclability?.reduce((value, res) => (res += value), 0);

  return (
    <Box display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" gap={1} marginBottom={3}>
        <Typography fontSize="1.125rem" fontWeight={700}>
          {t('cycling-insights.facilities.cyclability.chart.title')}
        </Typography>
      </Box>
      {roadsCountByCyclability &&
      roadsCountByCyclability.reduce((value, res) => (res += value), 0) === 0 ? (
        <Typography overflow="hidden" textOverflow="ellipsis" variant="body2" whiteSpace="nowrap">
          {t('commons.no_filtered_data')}
        </Typography>
      ) : (
        <Box
          display="flex"
          flexDirection="row"
          gap={4}
          justifyContent="space-between"
          paddingRight={2}
        >
          <Box
            alignSelf="center"
            height={200}
            maxWidth="100%"
            minWidth={200}
            overflow="hidden"
            position="relative"
            sx={{ overflow: 'hidden' }}
            width={200}
          >
            <canvas id={roadsTypesChartId} style={{ position: 'relative', zIndex: 2 }} />
          </Box>
          <Box display="flex" flexDirection="column" gap={2} justifyContent="center">
            {cyclabilities.map((key, index) => {
              const { color, labelKey } = cyclabilitiesMap[key];

              return (
                <Box display="flex" flexDirection="column" key={key}>
                  <Box alignItems="center" display="flex" gap={1}>
                    <Box
                      bgcolor={color}
                      borderRadius="5px"
                      height="10px"
                      marginBottom="2px"
                      minWidth="10px"
                      width="10px"
                    />
                    <Typography variant="caption">{t(labelKey)}</Typography>
                  </Box>
                  <Typography variant="caption">
                    {roadsCountByCyclability ? (
                      `${
                        totalRoadsCount
                          ? Math.round((roadsCountByCyclability[index] / totalRoadsCount) * 1000) /
                            10
                          : 0
                      } %`
                    ) : (
                      <Skeleton variant="text" width={100} />
                    )}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default CyclabilityChart;
