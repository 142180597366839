import {
  Event,
  EventService,
  Geogroup,
  GeogroupService,
  Loading,
  Period,
  UserService,
  i18nCommons,
  setLocales,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import moment from 'moment';
import { ReactNode, useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { environment } from '../../environment';
import { AppContext } from '../context';
import { languages } from '../i18n';

import routes, { defaultGuestPath, defaultPrivatePath } from './routes';

const { highlightedEventId } = environment;

function Router(): JSX.Element {
  const {
    app: { initializing, highlightedEvent },
    partner: { currentGeogroup },
    user: { current: currentUser },
    actions: { setHighlightedEvent, setHighlightedEventChallenge, setCurrentUser },
  } = useContext(AppContext);
  const { i18n } = useTranslation();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();

  useEffect(() => {
    if (!initializing.current) {
      initializing.current = true;
      initUser();
      if (highlightedEventId) getHighlightedEvent(highlightedEventId);
    }
  }, []);

  useEffect(() => {
    if (currentGeogroup && highlightedEvent)
      getHighlightedEventChallenge(currentGeogroup, highlightedEvent);

    return () => {
      cancelPromises();
      setHighlightedEventChallenge(undefined);
    };
  }, [currentGeogroup]);

  useEffect(() => {
    if (currentUser) {
      const {
        parameters: { language: userLanguage },
      } = currentUser;
      if (i18n.language !== userLanguage && languages[userLanguage]) {
        i18nCommons.changeLanguage(userLanguage);
        i18n.changeLanguage(userLanguage);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    moment.locale(i18n.language);
    setLocales(i18n.language);
  }, [i18n.language]);

  async function initUser() {
    try {
      const user = await UserService.getCurrentUser();

      setCurrentUser(user);
    } catch (err) {
      console.error(err);
    }
  }

  async function getHighlightedEvent(id: number) {
    try {
      const event = await EventService.getEvent(id);

      setHighlightedEvent(event);
      if (currentGeogroup) getHighlightedEventChallenge(currentGeogroup, event);
    } catch (err) {
      console.error(err);
    }
  }

  async function getHighlightedEventChallenge(geogroup: Geogroup, highlightedEvent: Event) {
    try {
      const { challenges } = await cancellablePromise(
        GeogroupService.getChallenges(geogroup.id, {
          page: 1,
          pageSize: 100,
          period: new Period('custom', highlightedEvent.startDate, highlightedEvent.endDate),
        }),
      );

      setHighlightedEventChallenge(
        challenges.find(({ eventId }) => eventId === highlightedEvent.id) || null,
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ isPrivate, isGuest, element, ...otherProps }) => {
          if (isPrivate)
            return (
              <Route
                element={<PrivateRoute>{element}</PrivateRoute>}
                key={otherProps.path || 'index'}
                {...otherProps}
              />
            );
          if (isGuest)
            return (
              <Route
                element={<GuestRoute>{element}</GuestRoute>}
                key={otherProps.path || 'index'}
                {...otherProps}
              />
            );

          return <Route element={element} key={otherProps.path || 'index'} {...otherProps} />;
        })}
      </Routes>
    </BrowserRouter>
  );
}

function GuestRoute({ children }: { children: ReactNode }): JSX.Element {
  const { state } = useLocation();
  const {
    user: { current: currentUser },
  } = useContext(AppContext);
  if (currentUser) return <Navigate to={state?.requestedPath || defaultPrivatePath} />;

  return <>{children}</>;
}

function PrivateRoute({ children }: { children: ReactNode }): JSX.Element {
  const { pathname, search } = useLocation();
  const {
    user: { current: currentUser },
  } = useContext(AppContext);

  if (currentUser === undefined) return <Loading text={<Trans i18nKey="commons.user.loading" />} />;
  if (currentUser === null)
    return <Navigate state={{ requestedPath: `${pathname}${search}` }} to={defaultGuestPath} />;

  return <>{children}</>;
}

export default Router;
