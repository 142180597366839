import {
  BikeRoute,
  BikeRouteService,
  WidgetConfig,
  WidgetConfigService,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { Box, useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AppContext } from '../../../../app/context';
import ConfirmDialog from '../../../../components/confirm-dialog';
import useAmplitudeTracker from '../../../../hooks/tracker';
import { IPromotionPageContext } from '../../context';

import WidgetFormDialog from './form-dialog';
import WidgetsList from './list';

function WidgetsForm({
  widgets: { list, selectedIndex, setList, selectIndex },
}: IPromotionPageContext): JSX.Element {
  const [bikeRoutes, setBikeRoutes] = useState<BikeRoute[]>();
  const [updatedWidget, setUpdatedWidget] = useState<WidgetConfig | null>(null);
  const [removeConfirmOpen, openRemoveConfirm] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [formDialogOpen, openFormDialog] = useState(false);
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { t } = useTranslation();
  const { transitions } = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    getWidgets();
    getBikeRoutes();

    return () => {
      cancelPromises();
      setList(undefined);
    };
  }, []);

  async function getWidgets() {
    if (!currentPartner) return;

    try {
      const widgets = await cancellablePromise(
        WidgetConfigService.getWidgetConfigs({ partnerId: currentPartner.id }),
      );

      widgets.sort((a, b) => b.id - a.id);

      setList(widgets);
      selectIndex(0);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.widgets.list.server_error'), { variant: 'error' });
      }
    }
  }

  async function getBikeRoutes() {
    try {
      const _bikeRoutes = await cancellablePromise(
        BikeRouteService.getBikeRoutes({ condensed: true }),
      );

      setBikeRoutes(_bikeRoutes);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('cycling-insights.widgets.list.server_error_bike_routes'), {
          variant: 'error',
        });
        setBikeRoutes([]);
      }
    }
  }

  async function handleRemove() {
    if (!currentPartner || !list || selectedIndex === null) return;

    const widget = list[selectedIndex];
    if (!widget) return;

    setRemoving(true);

    try {
      await WidgetConfigService.removeWidgetConfig(widget.id, {
        partnerId: currentPartner.id,
      });

      const index = list.findIndex(({ id }) => widget.id === id);
      const widgets = [...list];
      widgets.splice(index, 1);

      setList(widgets);
      selectIndex(0);
      openRemoveConfirm(false);
      enqueueSnackbar(t('cycling-insights.widgets.remove_dialog.success'));
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.widgets.remove_dialog.server_error'), {
        variant: 'error',
      });
    }

    setRemoving(false);
  }

  function handleFormDialogClose(newOrUpdatedConfig?: WidgetConfig) {
    if (list) {
      if (newOrUpdatedConfig && updatedWidget) {
        const index = list.findIndex(({ id }) => newOrUpdatedConfig.id === id);
        const widgets = [...list];
        widgets.splice(index, 1, newOrUpdatedConfig);

        setList(widgets);
      } else if (newOrUpdatedConfig) {
        setList([newOrUpdatedConfig, ...list]);
        selectIndex(0);
      }
    }

    openFormDialog(false);
    setTimeout(() => setUpdatedWidget(null), transitions.duration.leavingScreen);
  }

  return (
    <>
      <Box padding={2}>
        <WidgetsList
          onAdd={() => {
            openFormDialog(true);
            trackEvent('Button Clicked', { cta: 'Add Widget Button' });
          }}
          onEdit={() => {
            if (selectedIndex === null) return;

            const widget = list?.slice()[selectedIndex];
            if (widget) {
              setUpdatedWidget(widget);
              openFormDialog(true);
              trackEvent('Button Clicked', { cta: 'Edit Widget Button' });
            }
          }}
          onRemove={() => {
            openRemoveConfirm(true);
            trackEvent('Button Clicked', { cta: 'Delete Widget Button' });
          }}
          onSelect={selectIndex}
          selectedIndex={selectedIndex}
          widgets={list}
        />
      </Box>
      <ConfirmDialog
        dialogTitle="widget-config-remove-confirm"
        loading={removing}
        onCancel={() => openRemoveConfirm(false)}
        onConfirm={handleRemove}
        open={removeConfirmOpen}
        title={<Trans i18nKey="cycling-insights.widgets.remove_dialog.title" />}
      />
      <WidgetFormDialog
        bikeRoutes={bikeRoutes}
        config={updatedWidget}
        onClose={handleFormDialogClose}
        open={Boolean(formDialogOpen)}
      />
    </>
  );
}

export default WidgetsForm;
