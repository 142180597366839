import { FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import useAmplitudeTracker from '../../../../hooks/tracker';
import { TOutletContext } from '../../../../layouts/page/container';
import PageContentLayout from '../../../../layouts/page/content';
import { IBicycleObservatoryPageContext } from '../../context';

import OriginDestinationForm from './form';
import PreviewDialog from './preview-dialog';

function OldOriginDestinationTab(): JSX.Element {
  const [previewDialogOpen, openPreviewDialog] = useState(false);
  const context = useOutletContext<IBicycleObservatoryPageContext & TOutletContext>();
  const {
    header: { setActions },
    oldOriginDestination: { flows },
  } = context;
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    setActions(
      <Tooltip title={<Trans i18nKey="commons.actions.export" />}>
        <span>
          <IconButton
            color="primary"
            disabled={!flows || flows.length === 0}
            onClick={() => {
              openPreviewDialog(true);
              trackEvent('File Downloaded', { file: 'Origin Destination' });
            }}
            size="small"
          >
            <FileDownloadOutlined />
          </IconButton>
        </span>
      </Tooltip>,
    );

    return () => {
      setActions(undefined);
    };
  }, [flows]);

  return (
    <>
      <PageContentLayout
        leftPanel={<OriginDestinationForm {...context} />}
        main={
          <StyledCanvas
            id="old-origin-destination-deck-canvas"
            ref={context.oldOriginDestination.canvasRef}
          />
        }
      />
      <PreviewDialog onClose={() => openPreviewDialog(false)} open={previewDialogOpen} />
    </>
  );
}

const StyledCanvas = styled.canvas`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export default OldOriginDestinationTab;
