import { SvgIcon, SvgIconProps } from '@mui/material';

function JourneyIcon(props: SvgIconProps): JSX.Element {
  return (
    <SvgIcon viewBox="0 0 43 43" {...props}>
      <path d="M20.1586 24.9141C20.1586 20.5616 16.6176 17.0206 12.265 17.0206C7.91248 17.0206 4.37158 20.5616 4.37158 24.9141C4.37158 26.1896 4.67591 27.3952 5.21549 28.4624L12.265 43L19.3157 28.4602C19.8545 27.3934 20.1586 26.1886 20.1586 24.9141ZM12.265 29.2824C9.85639 29.2824 7.89673 27.3227 7.89673 24.9141C7.89673 22.5055 9.85639 20.5459 12.265 20.5459C14.6737 20.5459 16.6332 22.5055 16.6332 24.9141C16.6332 27.3227 14.6737 29.2824 12.265 29.2824Z" />
      <path d="M34.6318 0C32.4279 0 30.6348 1.79304 30.6348 3.99699C30.6348 4.64287 30.7889 5.25332 31.0621 5.79388L34.6318 13.1553L38.2021 5.79271C38.4749 5.25251 38.6289 4.64242 38.6289 3.99708C38.6289 1.79304 36.8357 0 34.6318 0ZM34.6318 6.20898C33.4121 6.20898 32.4198 5.21672 32.4198 3.99699C32.4198 2.77735 33.4121 1.78499 34.6318 1.78499C35.8514 1.78499 36.8438 2.77735 36.8438 3.99699C36.8438 5.21672 35.8514 6.20898 34.6318 6.20898Z" />
      <path d="M31.1503 25.9308C30.9882 25.1682 30.7775 24.4208 30.5122 23.6871C30.1566 22.7039 29.7302 21.7432 29.4035 20.7488C28.7929 18.8902 29.8462 17.3391 30.0441 17.0572C30.391 16.5635 30.7965 16.1104 31.2244 15.6861C32.0483 14.8693 32.9569 14.1391 33.9074 13.4667C33.7897 13.2842 33.6756 13.0998 33.567 12.912C32.1998 13.448 25.1687 15.9053 25.3402 21.3341C25.3745 22.4224 25.795 23.4582 26.0541 24.5131C26.179 25.0214 26.2872 25.5342 26.3535 26.0538C26.382 26.2877 26.3995 26.5229 26.4187 26.758C26.4192 26.7562 26.419 26.755 26.418 26.754C26.4186 26.7608 26.436 27.499 26.4033 27.8188C26.3007 28.82 26.1528 29.8316 25.7307 30.7501C25.3382 31.604 24.7459 32.3466 24.0133 32.9336C22.3386 34.2753 20.1196 34.8117 18.0258 35.0273C17.9261 35.0378 17.8264 35.0467 17.7268 35.0554L13.9883 42.7645C15.8329 42.6498 17.6727 42.3974 19.4677 41.9556C21.5801 41.4357 23.6387 40.6503 25.4833 39.4861C27.3263 38.3232 28.9112 36.7499 29.9663 34.8345C31.0701 32.8309 31.5123 30.5359 31.4181 28.2618C31.3862 27.485 31.3125 26.6932 31.1503 25.9308Z" />
    </SvgIcon>
  );
}

export default JourneyIcon;
