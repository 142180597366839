import { Add, FileDownloadOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import useAmplitudeTracker from '../../../../hooks/tracker';
import PageContentLayout from '../../../../layouts/page/content';
import SendFileDialog from '../../../cartographic-data/components/facilities/send-file-dialog';
import { TParkingsPageContext } from '../../context';

import DownloadDialog from './download-dialog';
import ParkingsForm from './form';

function ParkingsTab(): JSX.Element {
  const context = useOutletContext<TParkingsPageContext>();
  const {
    header: { setPrevButtonClick, setTitle, setActions },
    period: {
      values: { current: currentPeriod },
    },
    parkings: { cyclabilityZones, selectedZone, stats, selectZone },
  } = context;
  const {
    partner: { current: currentPartner },
  } = useContext(AppContext);
  const { trackEvent } = useAmplitudeTracker();
  const [sendFileDialogOpen, openSendFileDialog] = useState(false);
  const [downloadDialogOpen, openDownloadDialog] = useState(false);

  useEffect(() => {
    return () => {
      setActions(undefined);
    };
  }, []);

  useEffect(() => {
    if (selectedZone) {
      setPrevButtonClick(() => () => selectZone(null));
      setTitle(selectedZone.name);
    }

    return () => {
      setPrevButtonClick(undefined);
      setTitle(undefined);
    };
  }, [selectedZone]);

  useEffect(() => {
    setActions(
      <>
        {currentPartner?.dashboardTabsPermissions.facilitiesGISFiles !== 'none' && (
          <Tooltip title={<Trans i18nKey="cycling-insights.facilities.navigation.send_gis_file" />}>
            <IconButton
              color="primary"
              onClick={() => {
                openSendFileDialog(true);
                trackEvent('Button Clicked', { cta: 'Facilities File Upload Button' });
              }}
              size="small"
            >
              <Add />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title={
            <Trans i18nKey="cycling-insights.cartographic_data.parkings.download_dialog.title" />
          }
        >
          <span>
            <IconButton
              color="primary"
              disabled={!stats}
              onClick={() => {
                openDownloadDialog(true);
                trackEvent('Button Clicked', { cta: 'Parkings File Download Button' });
              }}
              size="small"
            >
              <FileDownloadOutlined />
            </IconButton>
          </span>
        </Tooltip>
      </>,
    );
  }, [stats]);

  return (
    <>
      <PageContentLayout leftPanel={<ParkingsForm {...context} />} {...context} />
      <SendFileDialog
        onAdd={() => openSendFileDialog(false)}
        onCancel={() => openSendFileDialog(false)}
        open={sendFileDialogOpen}
      />
      <DownloadDialog
        cyclabilityZones={cyclabilityZones}
        onClose={() => openDownloadDialog(false)}
        open={downloadDialogOpen}
        period={currentPeriod}
        selectedZone={selectedZone}
      />
    </>
  );
}

export default ParkingsTab;
