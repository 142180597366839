import {
  Challenge,
  ChallengeCard,
  ChallengeLeaderboard,
  GeogroupService,
  RemoveIcon,
  TChallengeLeaderboard,
  TSubGroupType,
  useCancellablePromise,
  useFileSaver,
} from '@geovelo-frontends/commons';
import { ArrowBackIosNew, EditOutlined } from '@mui/icons-material';
import { Box, ButtonBase, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { AppContext } from '../../../../app/context';
import Button from '../../../../components/button';

import RemoveDialog from './remove-dialog';

function ChallengeDetails(): JSX.Element {
  const {
    partner: { current: currentPartner, currentGeogroup },
    user: { current: currentUser },
  } = useContext(AppContext);
  const [challenge, setChallenge] = useState<Challenge>();
  const [leaderboard, setLeaderboard] = useState<TChallengeLeaderboard | undefined>();
  const [displayType, setDisplayType] = useState<TSubGroupType | 'individual'>('individual');
  const [downloading, setDownloading] = useState(false);
  const [removeDialogOpen, openRemoveDialog] = useState(false);
  const { t } = useTranslation();
  const { id: _id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const { downloadCSV } = useFileSaver();

  useEffect(() => {
    if (_id) getChallenge(parseInt(_id));

    return () => cancelPromises();
  }, [currentPartner, _id]);

  useEffect(() => {
    return () => {
      cancelPromises();
      setLeaderboard(undefined);
    };
  }, [challenge]);

  async function getChallenge(id: number) {
    if (!currentGeogroup) return;

    try {
      const result = await cancellablePromise(GeogroupService.getChallenge(currentGeogroup.id, id));

      setChallenge(result);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        enqueueSnackbar(t('commons.challenge_details.server_error'), { variant: 'error' });
        navigate(`/${currentPartner?.code}/promotion/animation`);
      }
    }
  }

  function handleCSVDownload() {
    if (!challenge || !leaderboard) return;

    setDownloading(true);

    downloadCSV(
      `Challenge_${challenge.title
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/\s+/g, '_')}_${moment().format('YYYY-MM-DD')}.csv`,
      ['Classement', 'Pseudo', 'Nom', 'Prénom', 'Adresse email', 'Score (km ou jours)'],
      leaderboard.map(
        ({ rank, username, firstName, lastName, professionalEmail, progressValue }) => [
          rank,
          username,
          lastName || '-',
          firstName || '-',
          professionalEmail || '-',
          challenge.type === 'traveledDistance' ? (progressValue / 1000).toFixed(1) : progressValue,
        ],
      ),
    );

    setDownloading(false);
  }

  return (
    <>
      <Helmet>
        <title>
          {t('cycling-insights.app_title')}&nbsp;|&nbsp;
          {t('cycling-insights.community.navigation.challenges')}
        </title>
        <meta content={t('commons.navigation.community_description') || ''} name="description" />
      </Helmet>
      <Box
        bgcolor="#F6F7FB"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={5}
        paddingX={14}
        paddingY={8}
        sx={{ overflowY: 'auto' }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box alignItems="center" display="flex" gap={2}>
            <ButtonBase component={Link} relative="path" to="..">
              <ArrowBackIosNew color="primary" fontSize="large" />
            </ButtonBase>
            <Typography
              color="primary"
              component="h2"
              flexDirection="column"
              fontSize="2rem"
              fontWeight="700"
            >
              {challenge ? (
                challenge.title
              ) : (
                <Skeleton height={48} variant="rectangular" width={256} />
              )}
            </Typography>
          </Box>
          <Box alignItems="center" display="flex" gap={2}>
            {challenge && challenge.startDate > moment() && (
              <Button
                color="error"
                onClick={() => openRemoveDialog(true)}
                size="medium"
                startIcon={<RemoveIcon />}
              >
                <Trans i18nKey="commons.challenge_details.remove_dialog.actions.remove" />
              </Button>
            )}
            {challenge && challenge.period !== 'past' && (
              <Button
                disableElevation
                component={Link}
                disabled={!challenge}
                size="large"
                startIcon={<EditOutlined />}
                to={challenge ? `./update` : ''}
                variant="contained"
              >
                <Trans i18nKey="commons.actions.update" />
              </Button>
            )}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flexShrink={0} gap={3}>
          <ChallengeCard
            challenge={challenge}
            containerProps={{ alignSelf: 'stretch', flexGrow: 0 }}
            imagePosition="left"
            redirectLink={
              currentPartner && challenge
                ? `/${currentPartner.code}/promotion/challenges/${challenge.id}`
                : undefined
            }
          />
          <ChallengeLeaderboard
            Button={Button}
            challenge={challenge}
            currentPartner={currentPartner}
            currentUser={currentUser}
            displayType={displayType}
            downloading={downloading}
            geogroup={currentGeogroup}
            handleExport={() => handleCSVDownload()}
            leaderboard={leaderboard}
            setDisplayType={setDisplayType}
            setLeaderboard={setLeaderboard}
            userType="admin"
          />
        </Box>
      </Box>
      <RemoveDialog
        challenge={challenge}
        onClose={(removed?: boolean) => {
          openRemoveDialog(false);
          if (removed) navigate('..', { relative: 'path' });
        }}
        open={removeDialogOpen}
      />
    </>
  );
}

export default ChallengeDetails;
