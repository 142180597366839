import { useUnits } from '@geovelo-frontends/commons';
import { Box, Divider } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Slider, { ISliderBounds, TSliderRange } from './form/slider';

export type TColorCollection = Array<{
  value: string;
  labelKey?: string;
  min?: number;
  max?: number;
}>;

type TRangeScale<T> = Array<{
  value: T;
  min: number;
  max: number;
}>;

export type TIntervalCollection<T> = Array<{ value: T; min: number; max: number } | { value: T }>;

export type TThicknessCollection = TIntervalCollection<number>;

interface IProps {
  caption?: ReactNode;
  colors: TColorCollection;
  currentRange?: TSliderRange;
  children?: ReactNode;
  bounds?: ISliderBounds;
  format?: (value: number) => string;
  setCurrentRange?: (range?: TSliderRange) => void;
  sliderTitle?: ReactNode;
  split?: boolean;
  title?: ReactNode;
  uniform?: boolean;
}

function ColorLegendSlider({
  caption,
  currentRange,
  bounds,
  sliderTitle,
  title,
  children,
  setCurrentRange,
  ...otherProps
}: IProps): JSX.Element {
  return (
    <Box
      bgcolor="white"
      borderRadius={2}
      display="flex"
      flexShrink={0}
      maxWidth="100%"
      minHeight={74}
      padding={2}
      width={setCurrentRange || children ? 662 : 270}
    >
      <Box display="flex" flexDirection="column" gap={2} width={306}>
        {title}
        <Box display="flex" flexDirection="column" gap={0.5}>
          <ColorLegend bounds={bounds} {...otherProps} />
          <CaptionWrapper>{caption}</CaptionWrapper>
        </Box>
      </Box>
      {setCurrentRange && (
        <>
          <Divider orientation="vertical" sx={{ marginX: 3 }} />
          <Box display="flex" flexGrow={1}>
            <Slider
              bounds={bounds}
              currentRange={currentRange}
              eventValue="Frequency"
              label={sliderTitle}
              setCurrentRange={setCurrentRange}
              step={1}
            />
          </Box>
        </>
      )}
      {children && (
        <>
          <Divider orientation="vertical" sx={{ marginX: 3 }} />
          {children}
        </>
      )}
    </Box>
  );
}

export function ColorLegend({
  bounds,
  colors: _colors,
  split,
  uniform,
  format: _format,
}: {
  colors: TColorCollection;
  bounds?: ISliderBounds;
  format?: (value: number) => string;
  split?: boolean;
  uniform?: boolean;
}): JSX.Element {
  const [colors, setColors] = useState(_colors);
  const { formatNumber } = useUnits();
  const { t } = useTranslation();

  const format = _format || formatNumber;

  useEffect(() => {
    setColors(getColors({ colors: _colors, bounds, split }));
  }, [_colors, bounds, split]);

  const uniformWidth = 100 / colors.length;

  return (
    <StyledBar>
      {colors.map(({ value: backgroundColor, ...otherProps }, index) => {
        let title;

        if ('labelKey' in otherProps && otherProps.labelKey) {
          title = t(otherProps.labelKey);
        } else if (
          'min' in otherProps &&
          typeof otherProps.min === 'number' &&
          'max' in otherProps &&
          typeof otherProps.max === 'number'
        ) {
          if (otherProps.max === Infinity) {
            title = `${format(otherProps.min)} ${t('commons.and_more')}`;
          } else {
            title = `${format(otherProps.min)} -> ${format(otherProps.max)}`;
          }
        } else if (bounds && uniform) {
          const { min, max } = bounds;
          const gap = (max - min) / colors.length;

          title = `${format(Math.round((min + gap * index) * 100) / 100)} -> ${format(
            Math.round((min + gap * (index + 1)) * 100) / 100,
          )}`;
        } else {
          title = undefined;
        }

        return (
          <div
            key={backgroundColor}
            style={{
              backgroundColor,
              flexGrow: !bounds || !('min' in otherProps) ? 1 : undefined,
              width: uniform
                ? `${uniformWidth}%`
                : bounds &&
                    'min' in otherProps &&
                    typeof otherProps.min === 'number' &&
                    typeof otherProps.max === 'number'
                  ? `${
                      ((otherProps.max - otherProps.min) / (bounds.max - (bounds.min || 0))) * 100
                    }%`
                  : undefined,
            }}
            title={title}
          />
        );
      })}
    </StyledBar>
  );
}

export function getColors({
  colors: _colors,
  bounds,
  split,
}: {
  bounds?: ISliderBounds;
  colors: TColorCollection;
  split?: boolean;
}): TColorCollection {
  if (bounds && split) {
    const { min, max } = bounds;
    const middleIndex = Math.floor(_colors.length / 2);

    if (min >= 0) {
      const colors = [..._colors].slice(middleIndex, Math.floor(max));

      return colors.map<{ value: string; min: number; max: number }>(({ value }, index) => ({
        value,
        min: Math.round((max / colors.length) * index * 10) / 10,
        max: Math.round((max / colors.length) * (index + 1) * 10) / 10,
      }));
    }

    const decreaseColors = [..._colors].slice(
      middleIndex - Math.min(-Math.floor(min) - 1, middleIndex),
      middleIndex,
    );
    const increaseColors = [..._colors].slice(middleIndex + 1, Math.ceil(max));

    return [
      ...decreaseColors.map<{ value: string; min: number; max: number }>(({ value }, index) => ({
        value,
        min: Math.round((min / (decreaseColors.length + 1)) * (middleIndex - index + 1) * 10) / 10,
        max: Math.round((min / (decreaseColors.length + 1)) * (middleIndex - index) * 10) / 10,
      })),
      {
        value: _colors[middleIndex].value,
        min: Math.round((min / (decreaseColors.length + 1)) * 10) / 10,
        max: Math.round((max / (increaseColors.length + 1)) * 10) / 10,
      },
      ...increaseColors.map<{ value: string; min: number; max: number }>(({ value }, index) => ({
        value,
        min: Math.round((max / (increaseColors.length + 1)) * (index + 1) * 10) / 10,
        max: Math.round((max / (increaseColors.length + 1)) * (index + 2) * 10) / 10,
      })),
    ];
  }
  return [..._colors];
}

function isRangeScale<T>(intervals: TIntervalCollection<T>): intervals is TRangeScale<T> {
  return (
    intervals.find((interval) => {
      return ('value' in interval && 'min' in interval && 'max' in interval) || false;
    }) !== undefined
  );
}

export function findIndexInIntervals<T>(
  data: number,
  bounds: ISliderBounds,
  intervals: TIntervalCollection<T>,
  comparisonEnabled?: boolean,
): number | null {
  if (intervals.length === 0) return null;

  if (isRangeScale(intervals)) {
    const index = intervals.findIndex((color, index) =>
      index < intervals.length - 1
        ? data >= color.min && data < color.max
        : data >= color.min && data <= color.max,
    );

    return index > -1 ? index : null;
  }

  const { min, max } = bounds;

  if (min === max) return Math.round(intervals.length / 2) - 1;

  let percent: number;
  if (comparisonEnabled) percent = Math.abs(data) / Math.max(-min, max);
  else percent = (data - min) / (max - min);

  return Math.round(percent * (intervals.length - 1));
}

const StyledBar = styled.div`
  display: flex;
  height: 14px;

  > div {
    &:first-child {
      border-radius: 12px 0 0 12px;
    }

    &:last-child {
      border-radius: 0 12px 12px 0;
    }
  }
`;

const CaptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > span {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
    align-items: center;

    &:first-child {
      align-items: flex-start;
    }

    &:last-child {
      align-items: flex-end;
    }
  }
`;

export default ColorLegendSlider;
