import { FileInput, GISFile, GISService, TFile } from '@geovelo-frontends/commons';
import { Box, DialogProps, TextField, Typography } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import Dialog from '../../../../components/dialog';
import useAmplitudeTracker from '../../../../hooks/tracker';

interface IValues {
  title: string;
  description: string;
}

type TProps = Omit<DialogProps, 'onClose' | 'title'> & {
  onCancel: () => void;
  onAdd: (file: GISFile) => void;
  open: boolean;
};

function NewFileDialog({ open, onAdd, ...props }: TProps): JSX.Element {
  const [file, setFile] = useState<TFile>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isSubmitting, values, touched, errors, handleChange, handleSubmit, setValues } =
    useFormik<IValues>({
      initialValues: {
        title: '',
        description: '',
      },
      validationSchema: Yup.object().shape({
        title: Yup.string().required(),
      }),
      onSubmit,
      enableReinitialize: true,
    });
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    if (open) {
      setValues({
        title: '',
        description: '',
      });
      setFile(null);
    }
  }, [open]);

  async function onSubmit(
    { title, description }: IValues,
    { setSubmitting }: FormikHelpers<IValues>,
  ) {
    if (!(file instanceof File)) return;

    setSubmitting(true);

    trackEvent('File Uploaded', { file: 'SIG Data', file_type: file.type });

    try {
      const newFile = await GISService.sendFile({ title, comment: description, document: file });

      onAdd(newFile);
      enqueueSnackbar(t('cycling-insights.facilities.gis_files.form.sent'), { variant: 'success' });
    } catch (err) {
      enqueueSnackbar(t('cycling-insights.facilities.gis_files.form.not_sent'), {
        variant: 'error',
      });
    }

    setSubmitting(false);
  }

  function handleFileChange(newFile?: TFile) {
    if (newFile === undefined || newFile instanceof File) setFile(newFile);
  }

  return (
    <Dialog
      isForm
      cancelTitle={<Trans i18nKey="commons.actions.cancel" />}
      confirmTitle={<Trans i18nKey="cycling-insights.facilities.gis_files.actions.send" />}
      dialogTitle="new-file-dialog"
      loading={isSubmitting}
      maxWidth="sm"
      onConfirm={handleSubmit}
      open={open}
      scroll="paper"
      title={<Trans i18nKey="cycling-insights.facilities.gis_files.form.title" />}
      {...props}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Typography component="p" variant="body2">
          <Trans i18nKey="cycling-insights.facilities.gis_files.form.description" />
        </Typography>
        <TextField
          fullWidth
          disabled={isSubmitting}
          error={touched.title && Boolean(errors.title)}
          label={t('commons.title')}
          margin="none"
          name="title"
          onChange={handleChange}
          size="small"
          value={values.title}
          variant="outlined"
        />
        <TextField
          fullWidth
          multiline
          disabled={isSubmitting}
          label={t('cycling-insights.facilities.gis_files.form.file_description')}
          margin="none"
          name="description"
          onChange={handleChange}
          rows={3}
          value={values.description}
          variant="outlined"
        />
        <FileInput
          disabled={isSubmitting}
          file={file}
          onChange={handleFileChange}
          size="small"
          type="*"
        />
      </Box>
    </Dialog>
  );
}

export default NewFileDialog;
